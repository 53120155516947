import React from 'react'

export default function ProdigiReflection() {
  return (
    <>
          <div className='grid grid-cols-12 py-10 md:py-20 w-screen h-auto bg-white'>
    <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
    <div className='w-full h-auto space-y-6'>
            <div className='text-left text-4xl md:text-[52px] w-full h-auto font-monda tracking-tighter font-bold text-zinc-800'>Reflection</div>
            
            <div className='text-left text-blue-600 w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>Gained the following skills</div>
            
            {/* Responsive */}

            <div className="w-full hidden md:grid grid-cols-3 gap-12">
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-blue-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Collaborated within a remote setting.</p>
                    </div>
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-blue-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Observing the UX Design process from a project manager's perspective.</p>
                    </div>
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-blue-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Rapidly working with mentors to make iterations.</p>
                    </div>
            </div>

            {/* Unresponsive */}

            <div className="w-full grid md:hidden space-y-6">
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-blue-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Collaborated within a remote setting.</p>
                    </div>
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-blue-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Observing the UX Design process from a project manager's perspective.</p>
                    </div>
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-blue-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Rapidly working with mentors to make iterations.</p>
                    </div>
            </div>

            <div className='text-left text-orange-800 w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>Learned the following lessons</div>

            {/* Responsive */}

            <div className="w-full hidden md:grid grid-cols-2 gap-12">
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>In group projects it's typical for your contributions not always to make it into the final project. In these momements it is important not to take this personally.</p>
                    </div>
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>There is no universal method for user research; in this field we are all trained differently, and our approaches each provide unique insights for projects.</p>
                    </div>
            </div>

            {/* Unresponsive */}

                <div className="w-full grid md:hidden space-y-6">
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>In group projects it's typical for your contributions not always to make it into the final project. In these momements it is important not to take this personally.</p>
                    </div>
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>There is no universal method for user research; in this field we are all trained differently, and our approaches each provide unique insights for projects.</p>
                    </div>
            </div>
        </div>
      </div>
      </div>
    </>
  )
}
