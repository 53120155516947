import { Link } from "react-router-dom";
import {useEffect} from "react";
import MyResume from '../pages/home-elements/home-images/ayisha_taye_zraika_resume.pdf';


function Mailto({ email, subject, body, ...props }) {
  return (
    <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
      {props.children}
    </a>
  );
};


export default function Footer(){
  
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  
    return(
        <>
      {/* Footer  normal*/}

      <div className='w-screen h-auto py-20 bg-zinc-800 hidden md:grid grid-cols-12'>
        <div className='col-start-3 col-end-11 grid grid-cols-2 gap-4'>
          <div className='col-span-1 w-full text-align-left space-y-2'>
            
            <div className='space-y-1'>
              <h2 className='w-fit text-align-left text-left text-5xl font-bold font-monda tracking-tighter bg-gradient-to-r from-yellow-500 to-purple-600 h-fit text-transparent bg-clip-text'>Find me here</h2>
              <p className='w-fit text-align-left text-left text-xl font-medium tracking-tight text-zinc-100'>Drop a follow on my socials or through my email.</p>
            </div>

          <div className='space-y-4 py-4'>
            <div className='grid grid-cols-3 w-fit gap-2'>
              <a href="https://www.behance.net/ayishazraika" className='col-span-1 rounded-md py-1 px-2 bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'><button>Behance</button></a>
              <Mailto email="a.t.zraika147@gmail.com" subject="Let's get in touch!" body="Hello Ayisha. I hope you've been well." className="h-auto w-auto"><div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Email</div></Mailto>
              <Link to="/work-in-progress"><div className='col-span-1 rounded-md w-fit px-2 py-1 bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Github</div></Link>
            </div>

            <div className='grid grid-cols-3 w-fit gap-2'>
              <a href="https://www.instagram.com/azp.en/" className="col-span-1 rounded-md py-1 px-2 bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300"><button>Instagram</button></a>
              <a href="https://www.linkedin.com/in/ayisha-zraika/" className='col-span-1 rounded-md py-1 bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'><button>LinkedIn</button></a>
              <a href="https://open.spotify.com/playlist/3Jd5eONGrsLgKdnhBkBX0o?si=f3d147ce087949bb"  className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'><button>Spotify</button></a>
            </div>
          </div>

          </div>
          <div className='col-span-1 grid place-items-center'>
            <div className="grid grid-cols-4 gap-4">
                <Link to="/"><div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Home</div></Link>
                <Link to="/about" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}>
                  <div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>About</div>
                </Link>
                <Link to="/work" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}>
                  <div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Work</div>
                </Link>
                <Mailto email="a.t.zraika147@gmail.com" subject="Let's get in touch!" body="Hello Ayisha. I hope you've been well." className="h-auto w-auto"><div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Contact</div></Mailto>
                <a href={require('../pages/home-elements/home-images/ayisha_taye_zraika_resume.pdf')} target="_blank"><div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Resume</div></a>
            </div>
          </div>
        </div>
      </div>

      {/* Footer  Responsive*/}

      <div className='w-screen h-auto pt-20 pb-6 bg-zinc-800 md:hidden grid grid-cols-12'>
        <div className='col-start-3 col-end-11 grid grid-rows-2 gap-0'>
          <div className='row-span-1 w-full text-align-left space-y-2'>
            
            <div className='space-y-1'>
              <h2 className='w-fit text-align-left text-left text-5xl font-bold font-monda tracking-tighter bg-gradient-to-r from-yellow-500 to-purple-600 h-fit text-transparent bg-clip-text'>Find me here</h2>
              <p className='w-fit text-align-left text-left text-md font-bold tracking-tight text-white'>Drop a follow over social media or contact me through email.</p>
            </div>

          <div className='space-y-4 py-4'>
            <div className='grid grid-cols-3 w-fit gap-2 h-auto'>
              <a href="https://www.behance.net/ayishazraika"><div className='col-span-1 rounded-md py-1 px-2 bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Behance</div></a>
              <Mailto email="a.t.zraika147@gmail.com" subject="Let's get in touch!" body="Hello Ayisha. I hope you've been well." className="h-auto w-auto"><div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Email</div></Mailto>
              <Link to="/work-in-progress"><div className='col-span-1 rounded-md py-1 px-2 bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Github</div></Link>
            </div>

            <div className='grid grid-cols-3 w-fit gap-2'>
              <a href="https://www.instagram.com/azp.en/"><div className='col-span-1 rounded-md py-1 px-2 bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300 w-fit'>Instagram</div></a>
              <a href="https://www.linkedin.com/in/ayisha-zraika/"><div className='col-span-1 rounded-md py-1 bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>LinkedIn</div></a>
              <a href="https://open.spotify.com/playlist/3Jd5eONGrsLgKdnhBkBX0o?si=f3d147ce087949bb"><div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Spotify</div></a>
            </div>
          </div>

          </div>
          <div className='row-span-1 grid place-items-center'>
            <div className="grid grid-cols-2 gap-4">
                <Link to="/"><div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Home</div></Link>
                <Link to="/about" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}><div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>About</div></Link>
                <Link to="/work" onClick={() => {window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}}><div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Work</div></Link>
                <Mailto email="a.t.zraika147@gmail.com" subject="Let's get in touch!" body="Hello Ayisha. I hope you've been well." className="h-auto w-auto"><div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Contact</div></Mailto>
                <a href={require('../pages/home-elements/home-images/ayisha_taye_zraika_resume.pdf')} target="_blank"><div className='col-span-1 rounded-md py-1 px-2 w-fit bg-zinc-700 text-white transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-zinc-600 duration-300'>Resume</div></a>
            </div>
          </div>
        </div>
      </div>
        </>
    );
};