import React from 'react';
import FigmaProtoPlanco from './planco-images/planco-figma.png';
import PlancoPreso from './planco-images/planco-preso.png';

export default function PlancoEnding() {
  return (
    <>
              {/* Endings Unresponsive*/}
        <div className='hidden md:grid grid-cols-12 w-screen h-auto'>
          <div className='col-start-3 col-end-11'>
              <div className='w-full h-auto grid grid-cols-2 gap-12 py-6 md:py-10'>
            <div className="col-span-1 space-y-4 grid place-items-center">
            <div className="text-[30px] font-monda font-bold tracking-tighter">Figma prototype</div>
                <div className='w-3/4 md:h-48 rounded-md overflow-hidden drop-shadow-sm'>
                  <img src={FigmaProtoPlanco} className='scale-[1.6]'/>
                </div>
                <a href="https://www.figma.com/file/e1szcRIiQx3tKk6WImdSlr/Planco?type=design&node-id=0%3A1&mode=design&t=Gkgfl0eBLe22vESd-1n"><button className='font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-teal-700 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>Figma file</button></a>
            </div>
            <div className="col-span-1 space-y-4 grid place-items-center">
                <div className="text-[30px] font-monda font-bold tracking-tighter">Full presentation</div>
                <div className='w-3/4 md:h-48 rounded-md overflow-hidden drop-shadow-sm grid place-items-center'>
                  <img src={PlancoPreso} className='scale-[1.2]'/>
                </div>
                <a href="https://youtu.be/Sf37nYPTSqg"><button className='font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-teal-700 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>YouTube video</button></a>
            </div>
        </div>
        </div>
        </div>
        {/* Responsive */}
        <div className='md:hidden grid grid-cols-12 w-screen h-auto'>
          <div className='col-start-3 col-end-11'>
              <div className='w-full h-auto grid grid-rows-2 gap-12 py-6 md:py-10'>
            <div className="row-span-1 space-y-4 grid place-items-center">
                <div className='w-full h-auto text-[24px] font-monda font-bold tracking-tighter'>Figma prototype</div>
                <div className='w-72 h-auto rounded-md'>
                  <img src={FigmaProtoPlanco}/>
                </div>
                <a href="https://www.figma.com/file/e1szcRIiQx3tKk6WImdSlr/Planco?type=design&node-id=0%3A1&mode=design&t=Gkgfl0eBLe22vESd-1"><button className='font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-teal-700 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>Figma file</button></a>
            </div>
            <div className="row-span-1 space-y-4 grid place-items-center">
                <div className='w-full h-auto text-[24px] font-monda font-bold tracking-tighter'>Full presentation</div>
                <div className='w-72 h-auto rounded-md overflow-hidden'>
                  <img src={PlancoPreso}/>
                </div>
                <a href="https://youtu.be/Sf37nYPTSqg"><button className='font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-teal-700 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>YouTube video</button></a>
            </div>
        </div>
        </div>
        </div>
    </>
  )
}
