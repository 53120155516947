import React from 'react'
import AmbassadorIntro from './ambassador-elements/AmbassadorIntro'
import AmbassadorImagesOne from './ambassador-elements/AmbassadorImagesOne'
import AmbassadorVideos from './ambassador-elements/AmbassadorVideos'

export default function AmbassadorPage() {
  return (
    <>
    <AmbassadorIntro/>
    <AmbassadorImagesOne/>
    <AmbassadorVideos/>
    </>
  )
}
