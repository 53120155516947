import React from 'react';
import ResoIntro from './reso-elements/ResoIntro'
import ResoReflection from './reso-elements/ResoReflection'
import ResoSummary from './reso-elements/ResoSummary'
import ResoProcess from './reso-elements/ResoProcess'

export default function ResolutionDigitalPage() {
  return (
    <>
      <ResoIntro/>
      <ResoProcess/>
      <ResoReflection/>
      <ResoSummary/>
    </>
  )
}
