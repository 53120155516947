import React from 'react';
import KurtImage from './chromacity-images/kurt.png';
import MohammedImage from './chromacity-images/mohammed.png';
import MaxineImage from './chromacity-images/maxine.png';

export default function UserPersonasChrom() {
  return (
    <>
        <div className='hidden lg:grid grid-cols-12 w-screen h-auto pb-10'>
            <div className='col-start-3 col-end-11'>
                <h2 className='text-cyan-800 text-[32px] md:text-[40px] font-monda tracking-tighter py-4 font-bold pb-10'>User personas</h2>
            </div>
            <div className='col-start-3 col-end-11  grid grid-cols-3 gap-8'>
                <div className='col-span-1  grid place-items-center gap-4 bg-zinc-200 p-8 rounded-md'>
                    {/* Personas 1 */}
                    <p className='text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Maxine</p>
                    <div className='w-40 h-40 rounded-full overflow-hidden'>
                        <img src={MaxineImage}/>
                    </div>
                    <div className="bg-white font-plus font-medium text-md md:text-lg w-fit h-auto rounded-md px-2 py-4">"I want to have an easier time connecting with my emotions."</div>
                    <div className='text-left w-full h-auto space-y-2 py-2'>
                        <p className='text-xl font-monda tracking-tighter font-bold'>Background</p>
                        <p className='md:text-lg text-md font-medium font-plus'>Maxine is a PR marketer currently working on promoting a sensory product. Maxine often supports her design team to ensure their products are accessible by participating in user testing. She believes overseeing part of their user research is imperative for her to ensure the company's success with this product.</p>
                    </div>
                    <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Goals</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Conceptualising principles of colour psychology.</li>
                        <li>Creating personal colour psychology associations.</li>
                    </ul>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Frustrations</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Their autisim makes it hard for them to concentrate with generic learning materials and empathise with other people.</li>
                        <li>There are too many resources available pertaining to colour psychology, but Maxine doesn't have enough time.</li>
                    </ul>                
                </div>
                </div>
                <div className='col-span-1  grid place-items-center gap-4 bg-zinc-200 p-8 rounded-md'>
                    {/* Personas 2 */}
                    <p className='text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Mohammed</p>
                    <div className='w-40 h-40rounded-rounded overflow-hidden'>
                        <img src={MohammedImage}/>
                    </div>
                    <div className="bg-white font-plus font-medium text-md md:text-lg w-fit h-auto rounded-md px-2 py-4">"I'll do it eventually, but it'll always get done!" </div>
                    <div className='text-left w-full h-auto space-y-2 py-2'>
                        <p className='text-xl font-monda tracking-tighter font-bold'>Background</p>
                        <p className='md:text-lg text-md font-medium font-plus'>Mohammed is a 29-year-old educational game designer based in Sydney, NSW. Mohammed's workplace is currently developing a game with an innovative control system; the game will be void of written instructions and operable on intuition alone. </p>
                    </div>
                    <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Goals</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Refining his understanding of colour psychology for work.</li>
                        <li>To create products which can be used in his home country. </li>
                    </ul>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Frustrations</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Lack of access to technology in his home country.</li>
                        <li>Didn't pay enough attention to design fundamentals in university.</li>
                        <li>Constantly overstimulated because of work.</li>
                    </ul>                
                </div>
                </div>
                <div className='col-span-1  grid place-items-center gap-4 bg-zinc-200 p-8 rounded-md'>
                    {/* Personas 3 */}
                    <p className='text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Kurt</p>
                    <div className='w-40 h-40'>
                        <img src={KurtImage}/>
                    </div>
                    <div className="bg-white font-plus font-medium text-md md:text-lg w-fit h-auto rounded-md px-2 py-4">"Knowledge is power, but it can sometimes make you see things you wish you didn't."</div>
                    <div className='text-left w-full h-auto space-y-2 py-2'>
                        <p className='text-xl font-monda tracking-tighter font-bold'>Background</p>
                        <p className='md:text-lg text-md font-medium font-plus'>Kurt is a 31-year-old psychology researcher for WSU who loves learning new things. Kurt tries to live a balanced life outside of work which consists of his social life, spending time with family, and his spiritual well-being.</p>
                    </div>
                    <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Goals</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>To find sensory activities to help him relax during his down time.</li>
                        <li>To find a new field to learn about.</li>
                    </ul>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Frustrations</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Constantly overstimulated.</li>
                        <li>Deals with people's energy all day.</li>
                        <li>Often has to work overtime, meaning he doesn't get much time with his family.</li>
                    </ul>                
                </div>
                </div>
            </div>
        </div>

        {/* Responsive */}
        <div className='lg:hidden grid grid-cols-12 w-screen h-auto py-10 md:py-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
        <h2 className='text-[32px] md:text-[40px] font-bold font-monda tracking-tighter pb-4 text-cyan-800'>User personas</h2>
        {/* Persona 1 */}
        <div className="grid space-y-4 bg-zinc-200 rounded-md p-4">
        <div className="row-span-1 font-plus font-medium text-md md:text-lg w-full h-auto grid place-items-center py-4 md:py-6 md:space-y-6 space-y-4">
            <p className='text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Maxine</p>
            <div className='md:w-60 w-36 md:h-60 h-36 overflow-hidden'>
                <img src={MaxineImage}/> 
            </div>
            <div className="font-plus font-medium text-md md:text-lg w-fit h-auto bg-white rounded-md p-4">"I want to have an easier time connecting with my emotions.”</div>
        </div>
                <div className='space-y-8'>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Background</p>
                    <p className='md:text-lg text-md font-medium font-plus'>Maxine is a PR marketer currently working on promoting a sensory product. Maxine often supports her design team to ensure their products are accessible by participating in user testing. She believes overseeing part of their user research is imperative for her to ensure the company's success with this product.</p>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Goals</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Conceptualising principles of colour psychology.</li>
                        <li>Creating personal colour psychology associations.</li>
                    </ul>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Frustrations</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Their autisim makes it hard for them to concentrate with generic learning materials and empathise with other people.</li>
                        <li>There are too many resources available pertaining to colour psychology, but Maxine doesn't have enough time.</li>
                    </ul>                
                </div>
                </div>
        </div>
                {/* Persona 2 */}
                <div className="grid space-y-4 py-10 bg-zinc-200 p-4 my-10">
        <div className=" row-span-1 font-plus font-medium text-md md:text-lg w-full h-auto grid place-items-center rounded-md py-4 md:py-6 md:space-y-6 space-y-4">
            <p className='text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Mohammed</p>
            <div className='md:w-60 w-36 md:h-60 h-36 overflow-hidden'>
                <img src={MohammedImage}/>
            </div>
            <div className="font-plus font-medium text-md md:text-lg w-fit h-auto bg-white rounded-md p-4">"I want to have an easier time connecting with my emotions.”</div>
        </div>
                <div className='space-y-8'>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Background</p>
                    <p className='md:text-lg text-md font-medium font-plus'>Mohammed is a 29-year-old educational game designer based in Sydney, NSW. Mohammed's workplace is currently developing a game with an innovative control system; the game will be void of written instructions and operable on intuition alone.</p>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Goals</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Refining his understanding of colour psychology for work.</li>
                        <li>To create products which can be used in his home country. </li>
                    </ul>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Frustrations</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Lack of access to technology in his home country.</li>
                        <li>Didn't pay enough attention to design fundamentals in university.</li>
                        <li>Constantly overstimulated because of work.</li>
                    </ul>                
                </div>
                </div>
        </div>
                       {/* Persona 3 */}
                       <div className="grid space-y-4 bg-zinc-200 p-4 rounded-md">
        <div className="row-span-1 font-plus font-medium text-md md:text-lg w-full h-auto grid place-items-center rounded-md py-4 md:py-6 md:space-y-6 space-y-4">
            <p className='text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Kurt</p>
            <div className='md:w-60 w-36 md:h-60 h-36 overflow-hidden'>
                <img src={KurtImage}/>
            </div>
            <div className="font-plus font-medium text-md md:text-lg w-fit h-auto bg-white rounded-md p-4">"I want to have an easier time connecting with my emotions.”</div>
        </div>
                <div className='space-y-8'>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Background</p>
                    <p className='md:text-lg text-md font-medium font-plus'>Kurt is a 31-year-old psychology researcher for WSU who loves learning new things. Kurt tries to live a balanced life outside of work which consists of his social life, spending time with family, and his spiritual well-being.</p>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Goals</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>To find sensory activities to help him relax during his down time.</li>
                        <li>To find a new field to learn about.</li>
                    </ul>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Frustrations</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Constantly overstimulated.</li>
                        <li>Deals with people's energy all day.</li>
                        <li>Often has to work overtime, meaning he doesn't get much time with his family.</li>
                    </ul>                
                </div>
                </div>
        </div>
        </div>
        </div>
    </>
  )
}
