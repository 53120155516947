import React from 'react';
import FeatureOne from './chromacity-images/onboarding.png';
import FeatureTwo from './chromacity-images/learn.png';
import FeatureThree from './chromacity-images/journal.png';
import FeatureFour from './chromacity-images/account-and-settings.png';
import DesignSystem from './chromacity-images/style-guide.jpg';


export default function ChromFeatureBreakdown() {
  return (
    <>
    {/* Feature Unresponsive */}
    <div className='hidden lg:grid grid-cols-12 w-screen h-auto py-10 md:py-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
        <div className='w-full h-auto md:space-y-6 space-y-4'>
            <h3 className='text-left text-4xl md:text-[52px] font-bold text-cyan-800 font-monda tracking-tighter'>Feature breakdown</h3>
            <div className='w-full h-auto grid grid-cols-2 gap-12 py-4 place-items-center'>
                <div className='col-span-1 h-auto'>
                    <img src={FeatureOne}/>
                </div>
                <div className='col-span-1 h-auto space-y-2'>
                    {/* Elements of features */}
                    <p className='text-cyan-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Feature one: Onboarding</p>
                    <p className='text-cyan-950 text-left w-full md:text-xl text-lg font-bold'>Seamless platform customisation with a no-nonsense tutorial.</p>
                    <ul className='text-left space-y-1 list-disc pl-6 text-md md:text-lg font-medium'>
                        <li>Provides short quizzes for platform customisation.</li>
                        <li>Teaches users how to use the basic features of Chromacity.</li>
                        <li>Creates instant autonomy for the user by letting them have access to their data.</li>
                    </ul>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-2 gap-12 py-4 place-items-center'>
                <div className='col-span-1 h-auto space-y-2'>                    
                    <p className='text-cyan-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Feature Two: Learn</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Complete data-base of colour accessibility, psychology and accessibility courses.</p>
                    <ul className='text-left space-y-1 list-disc pl-6 text-md md:text-lg font-medium'>
                        <li>Comprehensive course overviews, including viewing preferences - audio or text - and estimated completion time.</li>
                        <li>Multiple, practical, short-form summaries; includes 1-minute, 3-minute, and whole module summaries.</li>
                        <li>Real-world colour palette applications.</li>
                    </ul>
                </div>
                <div className='col-span-1 h-96'>
                    <img src={FeatureTwo} className='h-full'/>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-2 gap-12 py-4 place-items-center'>
                <div className='col-span-1 h-96 '>
                    <img src={FeatureThree} className='h-full'/>
                </div>
                <div className='col-span-1 h-auto space-y-2'>
                <p className='text-cyan-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Feature Three: Journal</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Redefining visual journaling.</p>
                    <ul className='text-left space-y-1 list-disc pl-6 text-md md:text-lg font-medium'>
                        <li>Apply your learnings from each course with Journal’s “Sensory Entries” - creating an intuitive visual journal - and “Story Entries” - completing a story with colours and see how each colour choice effects the narrative.</li>
                        <li>Each entry contains a journal activity, along with an option to review your entry before publishing.</li>
                        <li>Users can revisit an entry after completing it and will have options to view, edit, share or delete it.</li>
                    </ul>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-2 gap-12 py-4 place-items-center'>
                <div className='col-span-1 h-auto space-y-2 grid'>
                <p className='text-cyan-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Feature Four - Account Settings</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Reflect on your settings and customisations.</p>
                    <ul className='text-left space-y-1 list-disc pl-6 text-md md:text-lg font-medium'>
                        <li>Enable users with methods to personalise the application, revisit tutorials, access their privacy settings, and exit Chromacity once they've finished.</li>
                    </ul>
                </div>
                <div className='col-span-1 h-96'>
                    <img src={FeatureFour} className='h-full'/>
                </div>
            </div>
            
            {/* Design System */}
            <div className='w-full h-auto space-y-2'>
                <h4 className='text-left text-[32px] md:text-[40px] font-bold text-blue font-monda tracking-tighter text-cyan-800'>Design system</h4>
                <p className='text-left w-full h-auto text-md font-medium md:text-lg font-plus'>I created my design system closely following the <span className='font-bold text-cyan-800'>WGAC 2.0, IOS and Google Material Design guidelines.</span> Along with this I used the Figma plug-ins <span className='font-bold text-cyan-800'>Blush, Iconfiy, Blobs and Unsplash.</span></p>
            </div>
            <div className='w-full h-auto space-y-1'>
                <div className='h-full bg-red-400 rounded-md overflow-hidden'>
                    <img src={DesignSystem}/>
                </div>
            </div>

        </div>
        </div>
        </div>

            {/* Feature Responsive */}
    <div className='lg:hidden grid grid-cols-12 w-screen h-auto py-10 md:py-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
        <div className='w-full h-auto space-y-8'>
            <h3 className='text-left text-4xl md:text-[52px] font-bold text-cyan-800 font-monda tracking-tighter'>Feature breakdown</h3>
            <div className='w-full h-auto space-y-6'>
                <div className='h-auto rounded-md bg-green-500'><img src={FeatureOne}/></div>
                <div className='h-auto space-y-2'>
                    <p className='text-cyan-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Feature one: Onboarding</p>
                    <p className='text-cyan-950 text-left w-full md:text-xl text-lg font-bold'>Seamless platform customisation with a no-nonsense tutorial.</p>
                    <ul className='text-left space-y-2 list-disc pl-6 text-md md:text-lg font-medium'>
                        <li>Provides short quizzes for platform customisation.</li>
                        <li>Teaches users how to use the basic features of Chromacity.</li>
                        <li>Creates instant autonomy for the user by letting them have access to their data.</li>
                    </ul>
                </div>
            </div>
            <div className='w-full h-auto space-y-6 '>
                <div className='h-auto'>
                <img src={FeatureTwo}/>
                </div>
                <div className='h-auto space-y-2'>                    
                    <p className='text-cyan-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Feature Two: Learn</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Complete data-base of colour accessibility, psychology and accessibility courses.</p>
                    <ul className='text-left space-y-2 list-disc pl-6 text-md md:text-lg font-medium'>
                        <li>Comprehensive course overviews, including viewing preferences - audio or text - and estimated completion time.</li>
                        <li>Multiple, practical, short-form summaries; includes 1-minute, 3-minute, and whole module summaries.</li>
                        <li>Real-world colour palette applications.</li>
                    </ul>
                </div>
            </div>
            <div className='w-full h-auto space-y-6'>
                <div className='h-auto'>
                    <img src={FeatureThree}/>
                </div>
                <div className='h-auto space-y-2'>
                <p className='text-cyan-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Feature Three: Journal</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Redefining visual journaling.</p>
                    <ul className='text-left space-y-2 list-disc pl-6 text-md md:text-lg font-medium'>
                        <li>Apply your learnings from each course with Journal’s “Sensory Entries” - creating an intuitive visual journal - and “Story Entries” - completing a story with colours and see how each colour choice effects the narrative.</li>
                        <li>Each entry contains a journal activity, along with an option to review your entry before publishing.</li>
                        <li>Users can revisit an entry after completing it and will have options to view, edit, share or delete it.</li>
                    </ul>
                </div>
            </div>
            <div className='w-full h-auto space-y-6'>
                <div className='h-auto bg-green-400'>
                    <img src={FeatureFour}/>
                </div>
                <div className='h-auto space-y-2'>
                <p className='text-cyan-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Feature Four - Account Settings</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Reflect on your settings and customisations.</p>
                    <ul className='text-left space-y-2 list-disc pl-6 text-md md:text-lg font-medium'>
                        <li>Enable users with methods to personalise the application, revisit tutorials, access their privacy settings, and exit Chromacity once they've finished.</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className='w-full h-auto space-y-2 pt-8 pb-4'>
                <h4 className='text-left text-[32px] md:text-[40px] font-bold text-blue font-monda tracking-tighter text-cyan-800'>Design system</h4>
                <p className='text-left w-full h-auto text-md font-medium md:text-lg font-plus'>I created my design system closely following the <span className='font-bold text-cyan-800'>WGAC 2.0, IOS and Google Material Design guidelines.</span> Along with this I used the Figma plug-ins <span className='font-bold text-cyan-800'>Blush, Iconfiy, Blobs and Unsplash.</span></p>
            </div>
            <div className='w-full h-auto space-y-1'>
                <div className='h-full bg-red-400 rounded-md overflow-hidden'>
                    <img src={DesignSystem}/>
                </div>
            </div>
        </div>
        </div>
    </>
  )
}
