import React from 'react';
// import CarouselOne from './CarouselOne';
import FormResponsesPlanco from './planco-images/planco-primary-research.png';
// import ResearchCarouselWrapper from './ResearchCarouselWrapper';
import SwotImage from './planco-images/planco-swot.jpg';

export default function ResearchPlanco() {
  return (
    <>
        {/* Primary and Secondary Research */}
        <div className='grid grid-cols-12 w-screen h-auto'>
        <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
        <div className='space-y-6'>
            <h3 className='text-left text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter'>Primary Research</h3>
            <p className='w-full text-left text-md md:text-lg font-plus tracking-tight font-medium'>
              After defining the problem I made a survey to explore the risky assumptions, understand views on sustainability, and see if there were shared factors affecting accessibility. It went out to people from various suburbs in Western and Northern Sydney.
            </p>
            <div className='w-full h-auto rounded-md space-y-2'>
                <div className='w-full h-40 md:h-60 rounded-md overflow-hidden shadow-md'>
                  <img src={FormResponsesPlanco}/>
                </div>
                <p className='text-sm md:text-md text-left text-zinc-800 font-plus font-medium'>Screenshot of responses</p>
                <h3 className='text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter pt-5 md:pt-10'>Secondary Research</h3>
            </div>
            {/* Unresponsive - Desktop Research*/}
            <section className='hidden md:grid space-y-8'>
            <div className='w-full grid place-items-center h-auto space-y-2'>
              <img src={SwotImage} className='w-60 md:w-96 rounded-md shadow-md'/>
              <p className='text-sm md:text-md text-left text-zinc-800 font-plus font-medium'>Company logos from SWOT analysis</p>
            </div>
            <div className='grid grid-cols-2 gap-12'>
              <div className='col-span-1 space-y-4 md:space-y-6 p-8 bg-zinc-100 rounded-md grid place-items-center'>
                <p className='font-monda tracking-tighter font-bold text-2xl'>SWOT analysis</p>
                <p className='text-md md:text-lg font-medium font-plus'>I researched companies that are currently creating sustainability services, as well as discipline-based services. I conducted a SWOT analysis on the following companies - <span className='font-bold text-teal-800'>Eather Group, Fabulous, Forest App, Green Impact, Halo, One Small Step and Planet Ark</span> - to understand their approaches to solving their problems and their execution.</p>
              </div>
              <div className='col-span-1 space-y-4 md:space-y-6 p-8 bg-zinc-100 rounded-md grid place-items-center'>
                <p className='font-monda tracking-tighter font-bold text-2xl'>Desktop research</p>
                <p className='text-md md:text-lg font-medium font-plus'> I wanted to learn more about the sustainability market within Australia to see if there is potential for growth for my solution.</p>
                <div className='p space-y-2 font-medium font-plus text-md md:text-lg'>
                  <p className='py-2 space-x-2 font-bold'><span>Market Size</span><span className='p-2 bg-zinc-300 rounded-md text-teal-700'>$6.0bn</span></p>
                  <p className='py-2 space-x-2 font-bold'><span>Industry Employment</span><span className='p-2 bg-zinc-300 rounded-md text-teal-700'>31470</span></p>
                  <p className='py-2 space-x-2 font-bold'><span>Number of Businesses</span><span className='p-2 bg-zinc-300 rounded-md text-teal-700'>6331</span></p>
                </div>
              </div>
            </div>
            </section>

            {/* Responsive */}
            <section className='md:hidden grid space-y-8'>
            <div className='w-full grid place-items-center h-auto space-y-2'>
              <img src={SwotImage} className='h-40 md:h-60 rounded-md shadow-md'/>
              <p className='text-sm md:text-md text-left text-zinc-800 font-plus font-medium'>Company logos from SWOT analysis</p>
            </div>
            <div className='space-y-12'>
              <div className='space-y-4 md:space-y-6 p-8 bg-zinc-100 rounded-md grid place-items-center'>
                <p className='font-monda tracking-tighter font-bold text-2xl'>SWOT analysis</p>
                <p className='text-md md:text-lg font-medium font-plus'>I researched companies that are currently creating sustainability services, as well as discipline-based services. I conducted a SWOT analysis on the following companies - <span className='font-bold text-teal-800'>Eather Group, Fabulous, Forest App, Green Impact, Halo, One Small Step and Planet Ark</span> - to understand their approaches to solving their problems and their execution.</p>
              </div>
              <div className='space-y-4 md:space-y-6 p-8 bg-zinc-100 rounded-md grid place-items-center'>
                <p className='font-monda tracking-tighter font-bold text-2xl'>Desktop research</p>
                <p className='text-md md:text-lg font-medium font-plus'> I wanted to learn more about the sustainability market within Australia to see if there is potential for growth for my solution.</p>
                <div className='p space-y-2 font-medium font-plus text-md md:text-lg'>
                  <p className='py-2 space-x-2 font-bold'><span>Market Size</span><span className='p-2 bg-zinc-300 rounded-md text-teal-700'>$6.0bn</span></p>
                  <p className='py-2 space-x-2 font-bold'><span>Industry Employment</span><span className='p-2 bg-zinc-300 rounded-md text-teal-700'>31470</span></p>
                  <p className='py-2 space-x-2 font-bold'><span>Number of Businesses</span><span className='p-2 bg-zinc-300 rounded-md text-teal-700'>6331</span></p>
                </div>
              </div>
            </div>
            </section>
  

        </div>
        </div>
        </div>
    </>
  )
}