import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';

export default function DiscoverIntro() {
  return (
    <>
    {/* Discover */}
    <div className='grid grid-cols-12 w-screen h-auto py-10 md:py-20'>
      <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
        <div className='space-y-4 md:space-y-6 grid place-items-center'>
        <motion.h1 initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }}h1 className='text-[44px] md:text-[52px] font-monda font-bold tracking-tighter w-full text-left text-zinc-800'>Discover</motion.h1>
    

            {/* <div className='w-full h-auto rounded-xl text-zinc-700 grid place-items-center space-y-4 md:space-y-6'>
              <img src={IbisGraphOne}  className='h-fit'/>
            </div> */}
            {/* Unresponsive */}
            <div className='hidden md:grid grid-cols-2 gap-12 pb-8'>
              <div className='col-span-1 grid place-items-center p-8 rounded-md bg-cyan-50'>
                <div className='w-32 h-32 grid place-items-center'>
                  <svg class="h-28 w-28 text-cyan-800"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="9" />  <line x1="9" y1="10" x2="9.01" y2="10" />  <line x1="15" y1="10" x2="15.01" y2="10" />  <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" /></svg>
                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>As a university student, I observed a significant gap between students and design fundamentals. These basics are crucial but often rushed through or sidelined due to students juggling various commitments and focusing on other disciplines.</p> 
              </div>
              <div className='col-span-1 grid place-items-center p-8 rounded-md bg-cyan-50'>
                <div className='w-32 h-32 grid place-items-center'>
                  <svg class="h-28 w-28 text-cyan-800"  fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"/></svg>
                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>Thus, many designers constantly need to revisit basics while juggling work, which can be daunting and time-consuming. Combined with the absence of teacher or mentor guidance, locating trustworthy resources becomes bewildering and discouraging.</p> 
              </div>
            </div>

            {/* Responsive */}
            <div className='grid md:hidden grid-row-2 gap-6 pb-4'>
              <div className='row-span-1 grid place-items-center p-8 rounded-md bg-cyan-50'>
                <div className='w-24 h-24 grid place-items-center'>
                  <svg class="h-20 w-20 text-cyan-800"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="9" />  <line x1="9" y1="10" x2="9.01" y2="10" />  <line x1="15" y1="10" x2="15.01" y2="10" />  <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" /></svg>
                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>As a university student, I observed a significant gap between students and design fundamentals. These basics are crucial but often rushed through or sidelined due to students juggling various commitments and focusing on other disciplines.</p> 
              </div>
              <div className='row-span-1 grid place-items-center p-8 rounded-md bg-cyan-50'>
                <div className='w-24 h-24 grid place-items-center'>
                  <svg class="h-20 w-20 text-cyan-800"  fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"/></svg>
                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>Thus, many designers constantly need to revisit basics while juggling work, which can be daunting and time-consuming. Combined with the absence of teacher or mentor guidance, locating trustworthy resources becomes bewildering and discouraging.</p> 
              </div>
            </div>

            <div className='w-full h-auto py-6 px-6 md:py-10 md:px-10 space-y-4 md:space-y-6 rounded-xl text-white bg-cyan-700'>
                <p className='text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left'>Problem</p>
                <p className='text-lg md:text-xl font-medium font-plus text-left'>How do we develop a product which can simplify the principles of colour psychology and accessibility into a digestible resource for beginner and tenuered creatives?</p>
            </div>
        </div> 
      </div>
      </div>

    </>
  )
}
