import React from 'react'

export default function IntroVideos() {
  return (
    <>
    <div className='w-screen h-auto grid grid-cols-12 mb-10 md:mb-20'>

        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 w-full space-y-4 md:space-y-6'>
        
            <div className='h-60 md:h-96 flex justify-center'>
                            
            <iframe className='w-[80%] h-full md:w-[70%] rounded-xl drop-shadow-xl' src="https://www.youtube.com/embed/5MpBVJ7nhVo?si=STN2cqABDVHnRPZs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                          
            </div>
            
            <p className='text-md md:text-lg w-full font-medium font-plus'>Skip to <span className='underline'>40:38</span> for my showreel.</p>
       
        </div>  

    </div>
    </>
  )
}
