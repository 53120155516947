import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';


export default function HeroSection() {
  return (
    <>
    <div className='grid w-screen h-auto font-plus grid-cols-12 bg-gradient-to-r from-yellow-600 to-purple-600 tracking-tight py-10 md:py-20'>
        <motion.div initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }}  className='place-items-center col-start-3 col-end-11 grid row-auto backdrop-blur-xl bg-white/90 rounded-xl drop-shadow-md font-bold py-10 md:py-20 px-12 space-y-2'>
            <h1 className='hidden md:flex w-fit place-items-center leading-tight text-[60px] md:text-[80px] font-monda tracking-tighter text-slate-700 pb-4'><span className="pr-0 md:pr-4 animate-waving-hand font-medium text-[40px] md:text-[60px]">👋</span>Hello! I’m Ayisha<span className="pl-0 md:pl-4 animate-waving-hand font-medium text-[40px] md:text-[60px]">👋</span></h1>
            <div className='flex md:hidden animate-waving-hand font-medium text-[80px]'>👋</div>
            <h1 className='font-monda flex md:hidden w-fit place-items-center leading-tight text-[44px] sm:text-[60px] tracking-tighter text-slate-700 pb-4'>Hello! I’m Ayisha</h1>
            <p className='leading-snug text-lg md:text-2xl font-medium text-slate-700'>I am a technology enthusiast pursuing digital experiences and building my dream career within the fields of Product Design, Front-End Engineering and Accessibility. </p>
        </motion.div>
      </div>
    </>
  )
}
