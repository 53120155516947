import React, {useEffect} from 'react';
import AboutThumbnail from './home-images/about-thumbnail.png';
import {Link} from 'react-router-dom';


export default function AboutSectionHome() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
    {/* About Section */}

    <div className='w-screen h-auto py-10 grid grid-cols-12'>
        <div className='col-start-3 col-end-11 grid row-auto gap-4'>
          <div className='overflow-hidden grid place-items-center row-span-1 w-full h-60 md:h-80 rounded-xl  text-white'>
            <img src={AboutThumbnail} alt="mockup-one" className=' block w-fit lg:scale-[1.5] scale-[2.6] rounded-md' />
          </div>
          <p className='text-left tracking-tight w-fit text-align-left text-md text-zinc-500 font-medium'>Photo from Torrens University Australia “How to Uni” Campaign featuring me!</p>
          <div className='w-full h-auto flex justify-center'>
          <Link to="/about" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
              <button className='font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-purple-500 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>About me</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
