import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';


export default function ChromReflection() {
  return (
    <>
    <div className='grid grid-cols-12 py-10 md:py-20 w-screen h-auto bg-gradient-to-r from-cyan-800  to-rose-800'>
    <motion.div initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }} className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
    <div className='w-full h-auto space-y-6'>
            <div className='text-zinc-300 text-left text-4xl md:text-[52px] w-full h-auto font-monda tracking-tighter font-bold'>Reflection</div>
            <div className='text-zinc-200 text-left w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>Takeaways</div>
            {/* Unresponsive */}
            <div className='hidden md:grid grid-cols-2 gap-12 text-white'>
                <div className="col-span-1 space-y-4">
                    <div className="underline text-left text-[30px] font-monda font-bold tracking-tighter">Selective research</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>This project truly pushed me to create a fantastic body of work and reflects all of the research and effort I put into it. But, of course, not everything happened according to plan. I made the tough decision to abandon multiple parts of my project, such as my customer journey map. Everything worked out for the best.</p>
                </div>
                <div className="col-span-1 space-y-4">
                    <div className="underline text-left text-[30px] font-monda font-bold tracking-tighter">Genuine need for product</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>I enjoyed the problem I solved for this project, as I developed something that everyone in the creative field has seen a benefit in thus far.  Although it's a project, I genuinely believe this should exist as a resource for designers.</p>
                </div>
            </div>
            {/* Responsive */}
            <div className='md:hidden grid grid-rows-2 gap-12 text-white'>
                <div className="row-span-1 space-y-4">
                    <div className="underline text-left text-[24px] font-monda font-bold tracking-tighter">Selective research</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>This project truly pushed me to create a fantastic body of work and reflects all of the research and effort I put into it. But, of course, not everything happened according to plan. I made the tough decision to abandon multiple parts of my project, such as my customer journey map. Everything worked out for the best.</p>
                </div>
                <div className="row-span-1 space-y-4">
                    <div className="underline text-left text-[24px] font-monda font-bold tracking-tighter">Genuine need for product</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>I enjoyed the problem I solved for this project, as I developed something that everyone in the creative field has seen a benefit in thus far.  Although it's a project, I genuinely believe this should exist as a resource for designers.</p>
                </div>
            </div>
        </div>
      </motion.div>
      </div>
    </>
  )
}
