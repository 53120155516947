import React from 'react';
import EmpathyMap from './chromacity-images/empathy-map.png';


export default function DefineChrom() {
  return (
    <>
        <div className='grid grid-cols-12 w-screen h-auto py-10 md:py-20 bg-white'>
        <div className='md:col-start-3 col-start-2 md:col-end-11 col-end-12'>
        <div className='w-full h-auto md:space-y-6 space-y-4'>
        <h3 className='text-left text-[44px] md:text-[52px] font-bold  font-monda tracking-tighter text-zinc-900'>Define</h3>
            <p className='text-left text-[32px] md:text-[40px] font-bold font-monda tracking-tighter pb-2 text-cyan-800'>Empathy map</p>            
            <div className='w-full h-auto rounded-md grid place-items-center overflow-hidden'>
              <img src={EmpathyMap} className='w-fit'></img>
            </div>
            <p className='pt-4 text-left w-full h-auto text-md font-medium md:text-lg font-plus pb-6'>Creating an empathy map supported me in visualising all of the user problems and simplifying each of the overlapping instances presented in the collected insights. The pain points revealed are:</p>
            {/* Regular */}
            <div className='p-8 w-full h-auto hidden md:grid grid-cols-2 gap-12 bg-cyan-700 rounded-md font-medium'>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>Colour psychology is over-saturated with resources, which make learning it feel challenging.</div>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>Creative's with learning impairments may additionally find it difficult to fully conceptualize these concepts.</div>
            </div>
            {/* Responsive */}
            <div className='p-8 w-full h-auto grid md:hidden grid-rows-2 gap-8 bg-cyan-700 rounded-md text-md font-medium'>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>Colour psychology is over-saturated with resources, which make learning it feel challenging.</div>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>Creative's with learning impairments may additionally find it difficult to fully conceptualize these concepts.</div>
            </div>
        </div>
        </div>
        </div>
    </>
  )
}
