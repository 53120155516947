import React from 'react';
import StudentOneImage from './image-1-student.png';
import StudentTwoImage from './image-2-student.png';


export default function AmbassadorImagesOne() {
  return (
    <>
              {/* Mock Ups */}
              <div className='hidden md:grid grid-cols-12 w-screen h-auto'>
        <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
            <div className='pb-10 md:pb-20'>
                <div className=' gap-8 hidden md:flex justify-between items-center'>
                    <div className='lg:w-96 lg:h-96 md:h-64 md:w-64 overflow-hidden rounded-md bg-red-300'>
                        <img className="scale-[1.3]" src={StudentOneImage}/>
                    </div>
                    <div className='lg:w-96 lg:h-96 md:h-64 md:w-64 overflow-hidden rounded-md bg-red-300'>
                        <img className="scale-[1.3]" src={StudentTwoImage}/>
                    </div>
                </div>
                <div className='gap-10 md:hidden flex justify-between items-center'>
                    <div className='row-span-1 bg-green-100 w-full aspect-square rounded-md overflow-hidden'>
                    </div>
                    <div className='row-span-1 bg-green-100 w-full aspect-square rounded-md overflow-hidden'>
                    </div>
                </div>
            </div>
        </div>
        </div>

        {/* Responsive */}
        <div className='md:hidden grid w-full h-auto grid-cols-12 pb-10'>
            <div className='grid grid-rows-2 gap-8 col-start-2 col-end-12 place-items-center'>
                <div className='row-span-1 bg-green-100 w-3/4 aspect-square rounded-md overflow-hidden'>
                    <img className="scale-[1.3]" src={StudentOneImage}/>
                </div>
                <div className='row-span-1 bg-green-100 w-3/4 aspect-square rounded-md overflow-hidden'>
                    <img className="scale-[1.3]" src={StudentTwoImage}/>
                </div>
            </div>
        </div>

    </>
  )
}
