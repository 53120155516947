import React from 'react';
// import KurtImage from './chromacity-images/kurt.png';
import ChristineImage from './planco-images/christine-persona.png';
import JustinImage from './planco-images/justin-persona.png';

export default function UserPersonasPlanco() {
  return (
    <>
        <div className='hidden lg:grid grid-cols-12 w-screen h-auto pb-10'>
            <div className='col-start-3 col-end-11'>
                <div className='space-y-2 md:space-y-4 pb-4'>
                <h2 className='text-teal-800 text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>User personas</h2>
                <p className='text-md md:text-lg font-plus font-medium pb-10'>Drawing from insights I created user personas to guide and ensure the service development resonates with users' needs.</p>
                </div>
            </div>
            <div className='col-start-3 col-end-11  grid grid-cols-2 gap-8'>
                <div className='col-span-1  grid place-items-center gap-4 bg-zinc-200 p-8 rounded-md'>
                    {/* Personas 1 */}
                    <p className='text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Christine</p>
                    <div className='w-40 h-40 rounded-full overflow-hidden'>
                        <img src={ChristineImage}/>
                    </div>
                    <div className="bg-white font-plus font-medium text-md md:text-lg w-fit h-auto rounded-md px-2 py-4">Bright, determined and passionate</div>
                    <div className='text-left w-full h-auto space-y-2 py-2'>
                        <p className='text-xl font-monda tracking-tighter font-bold'>Background</p>
                        <p className='md:text-lg text-md font-medium font-plus'>Christine is driven to make a difference in her community. Despite her upbringing in a disadvantaged area, she aspires to become a social worker to create a positive impact. In her free time she enjoys outings with friends - indulging in shopping sprees - and embarking on road trips.</p>
                    </div>
                    <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Goals</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Finding a way to help her community sustain itself long-term.</li>
                        <li>Investing in one eco-friendly activity as a part of her new year's resolution.</li>
                    </ul>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Frustrations</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Christine struggles to find or afford sustainable products and services within her budget.</li>
                    </ul>                
                </div>
                </div>
                <div className='col-span-1  grid place-items-center gap-4 bg-zinc-200 p-8 rounded-md'>
                    {/* Personas 2 */}
                    <p className='text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Justin</p>
                    <div className='w-40 h-40 rounded-full overflow-hidden'>
                        <img src={JustinImage}/>
                    </div>
                    <div className="bg-white font-plus font-medium text-md md:text-lg w-fit h-auto rounded-md px-2 py-4">Serious, quiet and introverted.</div>
                    <div className='text-left w-full h-auto space-y-2 py-2'>
                        <p className='text-xl font-monda tracking-tighter font-bold'>Background</p>
                        <p className='md:text-lg text-md font-medium font-plus'>Justin is a typical family man who works during the day and prioritises time with his loved ones after hours. Eager to forge a unique family lifestyle, he is drawn to minimalism and relies on a capsule wardrobe.</p>
                    </div>
                    <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Goals</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Wants to look more into the impact of minimalism.</li>
                        <li>Aims to establish healthier habits for his family to ensure a good quality of life.</li>
                    </ul>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Frustrations</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>With so many eco-friendly options available, he's unsure which ones are best for his family.</li>
                    </ul>                
                </div>
                </div>
      
            </div>
        </div>

        {/* Responsive */}
        <div className='lg:hidden grid grid-cols-12 w-screen h-auto py-10 md:py-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
        <h2 className='text-teal-800 text-[32px] md:text-[40px] font-monda tracking-tighter font-bold pb-4'>User personas</h2>
        {/* Persona 1 */}
        <div className="grid space-y-4 bg-zinc-200 rounded-md p-4">
        <div className="row-span-1 font-plus font-medium text-md md:text-lg w-full h-auto grid place-items-center py-4 md:py-6 md:space-y-6 space-y-4">
            <p className='text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Christine</p>
            <div className='md:w-60 w-36 md:h-60 h-36 overflow-hidden rounded-full'>
                <img src={ChristineImage}/>
            </div>
            <div className="font-plus font-medium text-md md:text-lg w-fit h-auto bg-white rounded-md p-4">Bright, determined and passionate</div>
        </div>
                <div className='space-y-8'>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Background</p>
                    <p className='md:text-lg text-md font-medium font-plus'>Christine is driven to make a difference in her community. Despite her upbringing in a disadvantaged area, she aspires to become a social worker to create a positive impact. In her free time she enjoys outings with friends - indulging in shopping sprees - and embarking on road trips.</p>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Goals</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Finding a way to help her community sustain itself long-term.</li>
                        <li>Investing in one eco-friendly activity as a part of her new year's resolution.</li>
                    </ul>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Frustrations</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Christine struggles to find or afford sustainable products and services within her budget.</li>
                    </ul>                
                </div>
                </div>
        </div>
                {/* Persona 2 */}
                <div className="grid space-y-4 py-10 bg-zinc-200 p-4 my-10">
        <div className=" row-span-1 font-plus font-medium text-md md:text-lg w-full h-auto grid place-items-center rounded-md py-4 md:py-6 md:space-y-6 space-y-4">
            <p className='text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Justin</p>
            <div className='md:w-60 w-36 md:h-60 h-36 overflow-hidden rounded-full'>
                <img src={JustinImage}/>
            </div>
            <div className="font-plus font-medium text-md md:text-lg w-fit h-auto bg-white rounded-md p-4">Serious, quite and introverted.</div>
        </div>
                <div className='space-y-8'>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Background</p>
                    <p className='md:text-lg text-md font-medium font-plus'>Justin is a typical family man who works during the day and prioritises time with his loved ones after hours. Eager to forge a unique family lifestyle, he is drawn to minimalism and relies on a capsule wardrobe.</p>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Goals</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>Wants to look more into the impact of minimalism.</li>
                        <li>Aims to establish healthier habits for his family to ensure a good quality of life.</li>
                    </ul>
                </div>
                <div className='text-left w-full h-auto space-y-2'>
                    <p className='text-xl font-monda tracking-tighter font-bold'>Frustrations</p>
                    <ul className='md:text-lg text-md font-medium font-plus pl-6 list-disc space-y-2'>
                        <li>With so many eco-friendly options available, he's unsure which ones are best for his family.</li>
                    </ul>                
                </div>
                </div>
        </div>
  
        </div>
        </div>
    </>
  )
}
