import AboutThumbnail from '../home-elements/home-images/about-thumbnail.png';


import React from 'react'

export default function AboutIntro() {
  return (
    <>
    <div className='grid grid-cols-12'>
      <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 py-10 md:py-20 space-y-4 md:space-y-6'>
        {/* Title */}
        <h1 className='text-5xl md:text-6xl tracking-tighter leading-snug bg-gradient-to-r from-yellow-500 to-purple-600 h-fit text-transparent bg-clip-text font-monda font-bold w-full'>About</h1>
        {/* Image and description */}
        <div className='overflow-hidden grid place-items-center row-span-1 w-full h-60 md:h-80 rounded-xl  text-white'>
            <img src={AboutThumbnail} alt="mockup-one" className=' block w-fit lg:scale-[1.5] scale-[2.6] rounded-md' />
          </div>
          <p className='tracking-tight w-full text-md text-zinc-500 font-medium'>Photo from Torrens University Australia “How to Uni” Campaign featuring me!</p>
          <p className='w-full text-md lg:text-lg font-plus font-medium'>
            As a child I always dreamed of becoming two things; a scientist and an artist. However, I ended up going down the path of being an artist instead as it was the path I had more success in at the time. I begun persuing art - completing holiday programs through the National Art School during high school - and migrated there afterwards for university.
            </p>
      </div>
      </div>
    </>
  )
}
