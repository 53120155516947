import PortfolioThumbnail from '../home-elements/home-images/portfolio-thumbnail.png'
import {Link} from 'react-router-dom';
import { useEffect } from 'react';





export default function EngineeringWork(){

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

    return(
        <>

        {/* BIG CHANGE */}

             {/* Extras Responsive*/}

      <div className='w-screen h-auto py-10 md:py-20 hidden md:grid grid-cols-12'>

        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 space-y-8 grid place-items-center'>

        <h1 className='h-fit py-4 text-[40px] sm:text-5xl md:text-6xl tracking-tighter font-bold font-monda bg-gradient-to-r from-yellow-500 to-purple-600 text-transparent bg-clip-text leading-snug w-full'>Front-end engineering</h1>
          
          {/* Section 1 */}
          <div className='grid place-items-center grid-cols-2 gap-8 h-auto'>
            {/* Item 1 */}
            <Link to="/portfolio-process" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
            <div className='bg-slate-100 w-[30vw] h-fit col-span-1 rounded-xl'>
              <div className='w-full h-[48vh] text-left-align rounded-t-xl overflow-hidden grid place-items-center'>
                <img src={PortfolioThumbnail} alt="mockup-one" className=' block w-fit lg:scale-[1.5] scale-[1.6]' />
              </div>
              <div className='w-full py-4 px-4'>
                <h3 className='text-3xl font-bold font-monda tracking-tighter w-fit text-left-align'>Portfolio Redesign</h3>
                <p className='w-fit text-left text-align-left text-xl'>Re-aligning and rebranding</p>
              </div>
              <div className="h-auto w-full text-left text-md md:text-lg pl-3 pb-4  ">
                <span className="bg-white font-medium text-zinc-500 border-solid border-2 border-zinc-400 font-plus text-left px-4 py-1 rounded-full ">Front-end engineering</span>
              </div>
            </div>
            </Link>

            {/* Item 2 */}
            
            {/*GitHub Project*/}
          </div>

        </div>

      </div>
      {/* Responsive */}

            {/* Responsive */}

<div className="bg-white py-10 w-screen md:hidden grid place-items-center grid-cols-12 ">

<div className="col-start-3 col-end-11 space-y-8 grid place-items-center">

<h1 className='h-fit py-2 text-[40px] sm:text-5xl md:text-6xl tracking-tighter font-monda font-bold bg-gradient-to-r from-yellow-500 to-purple-600 text-transparent bg-clip-text leading-snug'>Front-end engineering</h1>


    {/* Item 1 */}
    <Link to="/portfolio-process" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }}>
    <div className="w-full h-fit bg-slate-100 rounded-xl">
        <div className="rounded-t-xl h-[40vh] overflow-hidden grid place-items-center">
          <img src={PortfolioThumbnail} alt="mockup-one" className=' block w-fit scale-[1.6] ' />
        </div>
        <div className='w-full h-auto py-4 px-4'>
            <h3 className='font-monda tracking-tighter text-2xl font-bold w-fit text-left-align'>Portfolio Redesign</h3>
            <p className='text-left w-fit text-align-left text-md'>Re-aligning and rebranding</p>
        </div>
        <div className="h-auto w-full text-left text-md md:text-lg pl-3 pb-4  ">
            <span className="bg-white font-medium text-zinc-500 border-solid border-2 border-zinc-400 font-plus text-left px-4 py-1 rounded-full ">Front-end Engineering</span>
        </div>
    </div>
    </Link>

</div>

</div>

        </>
    );
};