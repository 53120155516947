import React from 'react';
import CanvasOne from '../../canvas/CanvasOne';
import FindingUX from './about-images/FindingUx.gif';

export default function DecisionSection() {

//   const draw = (context, count) => {
//     context.clearRect(0,0,context.canvas.width,context.canvas.height);
//     context.fillStyle ='blue';
//     const delta = count % 800;
//     context.fillRect(10 + delta ,10,100,100);
// };

  return (
    <>
            {/* Decision Section */}
        {/* Unresponsive */}
        <div className='hidden lg:grid grid-cols-12 pb-10 md:pb-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 py-10 md:py-20 space-y-4 md:space-y-6'>
        <div className='grid grid-cols-2 gap-8'>
          <div className='col-span-1 w-full'>
            <div className='w-96 aspect-square rounded-md overflow-hidden'>
              {/* <CanvasOne draw={draw} width="500" height="100" color="pink"/> */}
              <img src={FindingUX} className='w-full h-auto scale-75'/>

            </div>
          </div>
          <div className='text-left col-span-1 space-y-8'>
            <p className='w-full h-auto md:text-lg text-md font-plus font-medium'>Eventually I found out about UX and Web design through mindless hours of scrolling through YouTube. For the first time in my life I felt a passion greater than I did with art or science. I finally found a career that would allow me to utilise my creativity to create work that could benefit the world.</p>
            <p className='w-full h-auto md:text-lg text-md font-plus font-medium'>I transferred over to Billy Blue College of Design - perusing the Bachelor of UX and Web Design - and sincerely appreciated all of the personal projects given to us. Each project challenged me to create innovative yet killer solutions. Here is where I found my passion for UX engineering and accessibility.</p>
          </div>
        </div>
      </div>
      </div>
      {/* Responsive */}
      <div className='grid lg:hidden grid-cols-12'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 py-10 md:py-20 space-y-4 md:space-y-6'>
        <div className='grid grid-row-2 gap-8 place-items-center'>
          <div className='row-span-1 w-full h-auto grid place-items-center'>
            <div className='w-60 aspect-square overflow-hidden rounded-md'>
              <img src={FindingUX} className='w-full h-auto'/>
            </div>
          </div>
          <div className='text-left row-span-1 space-y-8'>
            <p className='w-full h-auto md:text-lg text-md font-plus font-medium'>Eventually I found out about UX and Web design through mindless hours of scrolling through YouTube. For the first time in my life I felt a passion greater than I did with art or science. I finally found a career that would allow me to utilise my creativity to create work that could benefit the world.</p>
            <p className='w-full h-auto md:text-lg text-md font-plus font-medium'>I transferred over to Billy Blue College of Design - perusing the Bachelor of UX and Web Design - and sincerely appreciated all of the personal projects given to us. Each project challenged me to create innovative yet killer solutions. Here is where I found my passion for UX engineering and accessibility.</p>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}
