import React from 'react';
import XlabsGif from './xlabs-images/interactive-prototype.gif';
import GroupPhoto from './xlabs-images/group-photo.png';

export default function XlabsSummary() {
  return (
    <>
          <div className='grid grid-cols-12 py-10 md:py-16 w-screen h-auto bg-white'>
    <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
    <div className='w-full h-auto space-y-6 grid place-items-center'>
            <div className='text-4xl md:text-[52px] w-full h-auto font-monda tracking-tighter font-bold text-zinc-800'>Project summary</div>
            
            <div className='text-cyan-700 w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>Kids Helpline Youth Ambassador Program</div>

            <div className='w-[80%] h-auto rounded-md bg-red-400 overflow-hidden shadow-md'>
              <img src={XlabsGif} className='w-full'/>
            </div>

            <p className='pt-4 md:pt-8 w-full text-left text-md md:text-lg font-medium'>Our team was tasked with creating a digital experience - focusing on a solution for the "youth" - to teach and inspire our demographic about a charitable cause of our choice. </p>
        
            <p className='w-full text-left text-md md:text-lg font-medium'>We chose Kids Helpine as our charity of choice and created a youth ambassador program. This program contained 5 simple steps for peers and friends to learn about mental health within highschools and use their learning to create meaningful impacts.</p>

            <p className=' w-full text-left text-md md:text-lg font-medium'>For this project I completed the following tasks:</p>


            <ul className='list-disc pl-6 text-md md:text-lg font-plus font-medium w-full text-left'>
                <li>Created the final interactive product using Figma to create a functional prototype.</li>
                <li>Developed a comprehensive document to highlighting each step taken by our team.</li>
              </ul>

            <div className='pt-5 md:pt-10 w-[80%] h-auto overflow-hidden space-y-2 '>
              <img src={GroupPhoto} className='w-full shadow-sm rounded-md'/>
              <p className='text-md md:text-lg w-full font-medium font-plus'>Photo of the entire team!</p>

            </div>
        </div>
      </div>
      </div>
    </>
  )
}
