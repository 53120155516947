import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from './pages/Home';
import Footer from './components/Footer';
import ChromacityPage from './pages/ChromacityPage';
import AboutPage from './pages/AboutPage';
import Header from './components/Header';
import WorkInProgress from './pages/about-page-elements/WorkInProgress';
import XlabsPage from './pages/XlabsPage';
import PlancoPage from './pages/PlancoPage';
import ResolutionDigitalPage from './pages/ResolutionDigitalPage';
import ProdigiPage from './pages/ProdigiPage';
import IntroPage from './pages/IntroPage';
import AmbassadorPage from './pages/AmbassadorPage';
import WorkPage from './pages/WorkPage';
import PortfolioPiecePage from './pages/PortfolioPiecePage';

function App() {
  return (
    <div className="App">

    <BrowserRouter>
      <Header/>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/chromacity"element={<ChromacityPage />}/>
                <Route path="/planco"element={<PlancoPage />}/>
                <Route path="/resolution-digital"element={<ResolutionDigitalPage />}/>
                <Route path="/xlabs-luminary"element={<XlabsPage />}/>
                <Route path="/prodigi"element={<ProdigiPage />}/>
                <Route path="/intro-2022"element={<IntroPage />}/>
                <Route path="/tua-ambassador"element={<AmbassadorPage />}/>
                <Route path="/work-in-progress"element={<WorkInProgress />}/>
                <Route path="/work"element={<WorkPage/>}/>
                <Route path="/portfolio-process"element={<PortfolioPiecePage/>}/>
            </Routes>
      <Footer/>
    </BrowserRouter>


  {/* <Navigation/> */}
  {/* <NavBarFinal/> */}
  {/* <ChromacityPage/> */}
  {/* <AboutPage/> */}

    </div>
  );
}

export default App;
