import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';
import OldScreens from './portfolio-piece-images/old-screens.gif'
export default function PortfolioDiscover() {
  return (
    <>
    {/* Discover */}
    <div className='grid grid-cols-12 w-screen h-auto pb-10 md:pb-20'>
      <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
        <div className='space-y-4 md:space-y-6 grid place-items-center'>
        <motion.h1 initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }}h1 className='text-[44px] md:text-[52px] font-monda font-bold tracking-tighter w-full text-left text-zinc-800'>Process</motion.h1>
    

            {/* <div className='w-full h-auto rounded-xl text-zinc-700 grid place-items-center space-y-4 md:space-y-6'>
              <img src={IbisGraphOne}  className='h-fit'/>
            </div> */}
            {/* Unresponsive */}
            <div className='hidden md:grid pb-8'>
              <div className='col-span-1 grid place-items-center p-8 rounded-md bg-purple-50'>
                <div className='w-32 h-32 grid place-items-center'>
                  <svg class="h-28 w-28 text-purple-800"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="9" />  <line x1="9" y1="10" x2="9.01" y2="10" />  <line x1="15" y1="10" x2="15.01" y2="10" />  <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" /></svg>
                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>My portfolio doesn't reflect the areas I aspire to work in. Although it presents my work, there is no emphasis on my personality. It doesn't clearly say "I want to do product design and UX engineering" and the UI isn't great; doesn't show how much I've improved since being in the industry.</p> 
              </div>
            </div>

            {/* Responsive */}
            <div className='grid md:hidden grid-row-2 gap-6 pb-4'>
              <div className='row-span-1 grid place-items-center p-8 rounded-md bg-purple-50'>
                <div className='w-24 h-24 grid place-items-center'>
                  <svg class="h-20 w-20 text-purple-800"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="9" />  <line x1="9" y1="10" x2="9.01" y2="10" />  <line x1="15" y1="10" x2="15.01" y2="10" />  <path d="M9.5 15.25a3.5 3.5 0 0 1 5 0" /></svg>
                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>My portfolio doesn't reflect the areas I aspire to work in. Although it presents my work, there is no emphasis on my personality. It doesn't clearly say "I want to do product design and UX engineering" and the UI isn't great; doesn't show how much I've improved since being in the industry.</p> 
              </div>
            </div>
            <div className='w-full h-auto grid place-items-center space-y-4'>
              <div className='w-full h-auto grid place-items-center rounded-md bg-red-300 drop-shadow-md'>
                <img src={OldScreens}/>
              </div>
              <p className='text-zinc-800 text-sm md:text-md font-plus font-bold'>Selected screens from old portfolio</p>
            </div>
            <div className='w-full h-auto py-6 px-6 md:py-10 md:px-10 space-y-4 md:space-y-6 rounded-xl text-white bg-purple-700'>
                <p className='text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left'>Problem</p>
                <p className='text-lg md:text-xl font-medium font-plus text-left'>How do I develop a portfolio which can combine all parts of my professional passions into a fun, interactive platform?</p>
            </div>
        </div> 
      </div>
      </div>

    </>
  )
}
