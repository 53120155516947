import React from 'react';
import DesignSystem from './chromacity-images/style-guide.jpg';
import PrototypeA from './chromacity-images/prototype-a.png';

export default function DeliverChrom() {
  return (
    <>
    <div className='grid grid-cols-12 w-screen h-auto py-10 md:py-20 bg-white'>
    <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
    <div className='w-full h-auto space-y-4 md:space-y-6'>
            <h3 className='text-left text-4xl md:text-[52px] font-bold text-zinc-900 font-monda tracking-tighter'>Deliver</h3>
            {/* text portion 1 */}
            <div className='w-full h-auto space-y-2'>
                <h4 className='text-left text-[32px] md:text-[40px] font-bold text-blue font-monda tracking-tighter text-cyan-800'>Hi-fidelity prototype</h4>
            </div>

            <p className='text-left w-full h-auto text-md font-medium md:text-lg font-plus pt-2'>For the user testing I devided to use <span className='font-bold text-cyan-800'>Maze</span> - an online rapid-testing platform - for automating my user tests and to support with analysing the test results.</p>
        
            {/* Responsive */}
            <div className='space-y-6 grid place-items-center'>

                <div className='w-full h-auto'>
                    <iframe className="drop-shadow-md w-full h-[600px]" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fp8fQHjkbPBKQocEKJv2T7i%2FChroma.city%3Fpage-id%3D146%253A1710%26type%3Ddesign%26node-id%3D146-2345%26viewport%3D1004%252C2678%252C0.45%26t%3Da0OyQxeBaJ9sXaMS-1%26scaling%3Dscale-down%26starting-point-node-id%3D146%253A1711%26mode%3Ddesign" allowfullscreen></iframe>
                </div>
                <div className='w-32 md:w-48 h-auto rounded-md space-y-2 grid place-items-center'>
                    {/* <img src={PrototypeA}/> */}
                    <p className='text-sm md:text-md text-zinc-800 font-plus font-medium'>First prototype</p>
                </div>
                {/* Unresponsive - maze section */}
                <div className="hidden md:grid w-full p-4 bg-cyan-700 rounded-md space-y-1 font-medium">
                    <div className="w-full h-auto text-white font-monda text-[24px] md:text-[30px] tracking-tighter font-bold">Responses from Maze</div>
                    <div className='grid grid-cols-2 gap-4 py-2'>
                        <div className='flex justify-center items-center  md:text-lg text-md col-span-1 p-4 rounded-md bg-white gap-4 text-left'><span className='font-black text-cyan-700'>1</span> Add an “Account and Settings” feature to help users sign out.</div>
                        <div className='flex justify-center items-center  md:text-lg text-md col-span-1 p-4 rounded-md bg-white gap-4 text-left'><span className='font-black text-cyan-700'>2</span> Adding example journals within the “Journal” feature for users to view and improve their understanding.</div>
                    </div>
                    <div className='grid grid-cols-2 gap-4 py-2 md:text-lg text-md'>
                        <div className='flex justify-center items-center col-span-1 p-4 rounded-md bg-white gap-4 text-left'><span className='font-black text-cyan-700'>3</span> Alter micro-interactions to create fluidity and consistency.</div>
                        <div className='flex justify-center items-center col-span-1 p-4 rounded-md bg-white gap-4 text-left'><span className='font-black text-cyan-700'>4</span> Alter the floating CTA buttons and signing out user flow.</div>
                    </div>
                    <div className='grid grid-cols-2 gap-4 py-2 md:text-lg text-md'>
                        <div className='flex justify-center items-center col-span-1 p-4 rounded-md bg-white gap-4 text-left'><span className='font-black text-cyan-700'>5</span> Creating a shareable item within the “Journal” feature to add value for users to use the platform.</div>
                        <div className='flex justify-center items-center col-span-1 p-4 rounded-md bg-white gap-4 text-left'><span className='font-black text-cyan-700'>6</span>Reducing content within the “Onboarding” feature to three parts and simplifying the copywriting of it’s CTA’s.</div>
                    </div>
                    <div className='grid grid-cols-2 gap-4 py-2 md:text-lg text-md'>
                        <div className='flex justify-center items-center col-span-1 p-4 rounded-md bg-white gap-4 text-left'><span className='font-black text-cyan-700'>7</span> Use the “Learn” feature as the landing page instead of incorporating a home page.</div>
                    </div>
                </div>
                {/* Responsive - maze section */}
                <div className="grid md:hidden w-full p-4 bg-cyan-700 rounded-md space-y-1">
                    <div className="w-full h-auto text-white font-monda text-[24px] md:text-[30px] tracking-tighter font-bold">Responses from Maze</div>
                    <div className='grid grid-rows-7 gap-4 py-2 text-md font-plus font-medium'>
                        <div className='flex justify-center items-center row-span-1 p-4 rounded-md bg-white gap-4'><span className='font-black text-cyan-700'>1</span> Add an “Account and Settings” feature to help users sign out.</div>
                        <div className='flex justify-center items-center row-span-1 p-4 rounded-md bg-white gap-4'><span className='font-black text-cyan-700'>2</span> Adding example journals within the “Journal” feature for users to view and improve their understanding.</div>
                        <div className='flex justify-center items-center row-span-1 p-4 rounded-md bg-white gap-4'><span className='font-black text-cyan-700'>3</span> Alter micro-interactions to create fluidity and consistency.</div>
                        <div className='flex justify-center items-center row-span-1 p-4 rounded-md bg-white gap-4'><span className='font-black text-cyan-700'>4</span> Alter the floating CTA buttons and signing out user flow.</div>
                        <div className='flex justify-center items-center row-span-1 p-4 rounded-md bg-white gap-4'><span className='font-black text-cyan-700'>5</span> Creating a shareable item within the “Journal” feature to add value for users to use the platform.</div>
                        <div className='flex justify-center items-center row-span-1 p-4 rounded-md bg-white gap-4'><span className='font-black text-cyan-700'>6</span>Reducing content within the “Onboarding” feature to three parts and simplifying the copywriting of it’s CTA’s.</div>
                        <div className='flex justify-center items-center row-span-1 p-4 rounded-md bg-white gap-4'><span className='font-black text-cyan-700'>7</span> Use the “Learn” feature as the landing page instead of incorporating a home page.</div>
                    </div>
                </div>
                

            </div>

        </div>
        </div>
        </div>
    </>
  )
}
