import StudentAmbassadorMdLgThumbnail from './home-images/StudentAmbassadorMdLgThumbnail.png';
import AppleMdLgThumbnail from './home-images/AppleMdLgThumbnail.png';
import IntroMdLgThumbnail from './home-images/IntroMdLgThumbnail.png';
import ArtMdLgThumbnail from './home-images/ArtMdLgThumbnail.png';
import {Link} from "react-router-dom";







export default function MyExtras(){
    return(
        <>
     {/* Extras Responsive*/}

      <div className='w-screen h-auto py-20 hidden md:grid grid-cols-12'>

        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 space-y-8 grid place-items-center'>

          <h1 className='h-fit py-4 text-[40px] sm:text-5xl md:text-6xl font-bold font-monda tracking-tighter bg-gradient-to-r from-yellow-500 to-purple-600 text-transparent bg-clip-text leading-snug w-full'>Other Experiences</h1>
          
          {/* Section 1 */}
          <div className='grid place-items-center grid-cols-2 gap-8 h-auto'>
            {/* Item 1 */}
            <a href="https://www.apple.com/au/retail/penrith/">
            <div className='bg-slate-100 w-[30vw] h-fit col-span-1 rounded-xl'>
              <div className='w-full h-[20vh] text-left-align rounded-t-xl overflow-hidden grid place-items-center'>
                <img src={AppleMdLgThumbnail} alt="mockup-one" className=' block w-fit lg:scale-[1.2]' />
              </div>
              <div className='w-full py-4 px-4'>
                <h3 className='text-3xl font-bold font-monda tracking-tighter w-fit text-left-align'>Apple Retail</h3>
                <p className='w-fit text-left text-align-left text-xl'>Technical Specialist</p>
              </div>
            </div>
          </a>

            {/* Item 2 */}
            <Link to="/tua-ambassador" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
            <div className='bg-slate-100 w-[30vw] h-fit  col-span-1 rounded-xl'>
              <div className='w-full h-[20vh] text-left-align rounded-t-xl overflow-hidden grid place-items-center'>
                <img src={StudentAmbassadorMdLgThumbnail} alt="mockup-one" className=' block w-fit lg:scale-[1.2]' />
              </div>
              <div className='w-full py-4 px-4'>
                <h3 className='text-3xl font-bold font-monda tracking-tighter w-fit text-left-align'>Ambassador</h3>
                <p className='w-fit text-left text-align-left text-xl'>@ TUA</p>
              </div>
            </div>
          </Link>
          </div>

          {/* Section 2 */}
          <div className='grid place-items-center grid-cols-2 gap-8 h-auto'>
            {/* Item 1 */}
            <Link to="/intro-2022" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
            <div className='bg-slate-100 w-[30vw] h-fit col-span-1 rounded-xl'>
              <div className='w-full h-[20vh] text-left-align rounded-t-xl overflow-hidden grid place-items-center'>
                <img src={IntroMdLgThumbnail} alt="mockup-one" className=' block w-fit lg:scale-[1.2]' />
              </div>
              <div className='w-full h-auto py-4 px-4'>
                <h3 className='text-3xl font-bold font-monda tracking-tighter w-fit text-left-align'>INTRO 2022</h3>
                <p className='w-fit text-left text-align-left text-xl'>Graduate Exhibition</p>
              </div>
            </div>
          </Link>

            {/* Item 2 */}
            <a href="https://tapas.io/lpennyfeatherou">
            <div className='bg-slate-100 w-[30vw] h-fit col-span-1 rounded-xl'>
              <div className='w-full h-[20vh] text-left-align rounded-t-xl overflow-hidden grid place-items-center'>
                <img src={ArtMdLgThumbnail} alt="mockup-one" className=' block w-fit lg:scale-[1.2]' />
              </div>
              <div className='w-full h-auto py-4 px-4'>
                <h3 className='text-3xl font-bold font-monda tracking-tighter w-fit text-left-align'>LOUPEN</h3>
                <p className='w-fit text-left text-align-left text-xl'>Webcomic artist @ Tapas</p>
              </div>
            </div>
          </a>
          </div>

        </div>

      </div>
      {/* Responsive */}

            {/* Responsive */}

<div className="bg-white py-10 w-screen md:hidden grid place-items-center grid-cols-12 ">

<div className="col-start-3 col-end-11 space-y-8 grid place-items-center">

<h1 className='h-fit py-2 text-[40px] sm:text-5xl md:text-6xl tracking-tighter font-monda font-bold bg-gradient-to-r from-yellow-500 to-purple-600 text-transparent bg-clip-text leading-snug'>Other experiences</h1>


    {/* Item 1 */}
    <a href="https://www.apple.com/au/retail/penrith/">
    <div className="w-full h-fit bg-slate-100 rounded-xl">
        <div className="rounded-t-xl h-[20vh] overflow-hidden grid place-items-center">
          <img src={AppleMdLgThumbnail} alt="mockup-one" className=' block w-fit lg:scale-[1.2]' />
        </div>
        <div className='w-full h-auto py-4 px-4'>
            <h3 className='font-monda tracking-tighter text-2xl font-bold w-fit text-left-align'>Apple Retail</h3>
            <p className='text-left w-fit text-align-left text-md'>Technical Specialist</p>
        </div>
    </div>
    </a>

    {/* Item 2 */}
    <Link to="/tua-ambassador" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
    <div className="w-full h-fit bg-slate-100 rounded-xl">
        <div className="rounded-t-xl h-[20vh] overflow-hidden grid place-items-center">
          <img src={StudentAmbassadorMdLgThumbnail} alt="mockup-one" className=' block w-fit lg:scale-[1.2]' />
        </div>
        <div className='w-full h-auto py-4 px-4'>
            <h3 className='font-monda tracking-tighter text-2xl font-bold w-fit text-left-align'>Ambassador</h3>
            <p className='text-left w-fit text-align-left text-md'>@ TUA</p>
        </div>
    </div>
    </Link>

    {/* Item 3 */}
    <Link to="/intro-2022" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
    <div className="w-full h-fit bg-slate-100 rounded-xl">
        <div className="rounded-t-xl h-[20vh] overflow-hidden grid place-items-center">
          <img src={IntroMdLgThumbnail} alt="mockup-one" className=' block w-fit lg:scale-[1.2]' />
        </div>
        <div className='w-full h-auto py-4 px-4'>
            <h3 className='font-monda tracking-tighter text-2xl font-bold w-fit text-left-align'>INTRO 2022</h3>
            <p className='text-left w-fit text-align-left text-md'>Graduate exhibition</p>
        </div>
    </div>
    </Link>

        {/* Item 4 */}
        <a href="https://tapas.io/lpennyfeatherou">
        <div className="w-full h-fit bg-slate-100 rounded-xl">
        <div className="rounded-t-xl h-[20vh] overflow-hidden grid place-items-center">
          <img src={ArtMdLgThumbnail} alt="mockup-one" className=' block w-fit lg:scale-[1.2]' />
        </div>
        <div className='w-full h-auto py-4 px-4'>
            <h3 className='font-monda tracking-tighter text-2xl font-bold w-fit text-left-align'>LOUPEN</h3>
            <p className='text-left w-fit text-align-left text-md'>Webcomic artist @ Tapas</p>
        </div>
        </div>
        </a>

</div>

</div>

        </>
    );
};