import { NavLink } from "react-router-dom";
import React, { useState } from 'react';
import OpenMenuButton from "./OpenMenuButton";
import ClosedMenuButtons from "./ClosedMenuButtons";
import MyResume from '../pages/home-elements/home-images/ayisha_taye_zraika_resume.pdf';

function Mailto({ email, subject, body, ...props }) {
  return (
    <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
      {props.children}
    </a>
  );
};

const NavLinks=()=> {
  return (
    <>
    <NavLink to='/' className='md:mx-4 my-2 md:my-0 px-2 py-1 text-zinc-600 font-monda tracking-tighter font-bold text-[16px] rounded-md bg-zinc-100 transition ease-in-out delay-2 hover:-translate-y-[2px] hover:scale-80 hover:bg-gradient-to-r from-yellow-200 to-purple-200 duration-500'>Home</NavLink>
    <NavLink to='/about' className='md:mx-4 my-2 md:my-0 px-2 py-1 text-zinc-600 font-monda tracking-tighter font-bold text-[16px] rounded-md bg-zinc-100 transition ease-in-out delay-2 hover:-translate-y-[2px] hover:scale-80 hover:bg-gradient-to-r from-yellow-200 to-purple-200 duration-500'>About</NavLink>
    <NavLink to='/work' className='md:mx-4 my-2 md:my-0 px-2 py-1 text-zinc-600 font-monda tracking-tighter font-bold text-[16px] rounded-md bg-zinc-100 transition ease-in-out delay-2 hover:-translate-y-[2px] hover:scale-80 hover:bg-gradient-to-r from-yellow-200 to-purple-200 duration-500'>Work</NavLink>

    </>
  );
};


const NavThree=()=> {

    const [isOpen, setIsOpen]= useState(false);

    const toggleNavbar =() => {
        setIsOpen(!isOpen);
    };


    return (
      <>
        <nav className="flex w-1/3 justify-end place-items-center">
            <div className="hidden md:flex justify-between">
                <NavLinks/>
                <Mailto email="a.t.zraika147@gmail.com" subject="Let's get in touch!" body="Hello Ayisha. I hope you've been well." className="h-auto w-auto"><div className="md:mx-4 my-2 md:my-0 px-2 py-1 text-zinc-600 font-monda tracking-tighter font-bold text-[16px] rounded-md bg-zinc-100 transition ease-in-out delay-2 hover:-translate-y-[2px] hover:scale-80 hover:bg-gradient-to-r from-yellow-200 to-purple-200 duration-500">Contact</div></Mailto>
                <a href={require('../pages/home-elements/home-images/ayisha_taye_zraika_resume.pdf')} target="_blank"><div className='md:mx-4 my-2 md:my-0 px-2 py-1 text-zinc-600 font-monda tracking-tighter font-bold text-[16px] rounded-md bg-zinc-100 transition ease-in-out delay-2 hover:-translate-y-[2px] hover:scale-80 hover:bg-gradient-to-r from-yellow-200 to-purple-200 duration-500'>Resume</div></a>
            </div>
            <div className="md:hidden">
            <button onClick={toggleNavbar}>
                {isOpen ? <ClosedMenuButtons/>:<OpenMenuButton/>}
            </button>
            </div>
        </nav>
        {isOpen && (
          <div className="flex basis-full flex-col items-center">
            <NavLinks/>
            <Mailto email="a.t.zraika147@gmail.com" subject="Let's get in touch!" body="Hello Ayisha. I hope you've been well." className="h-auto w-auto"><div className="md:mx-4 my-2 md:my-0 px-2 py-1 text-zinc-600 font-monda tracking-tighter font-bold text-[16px] rounded-md bg-zinc-100 transition ease-in-out delay-2 hover:-translate-y-[2px] hover:scale-80 hover:bg-gradient-to-r from-yellow-200 to-purple-200 duration-500">Contact</div></Mailto>
            <a href={require('../pages/home-elements/home-images/ayisha_taye_zraika_resume.pdf')} target="_blank"><div className='md:mx-4 my-2 md:my-0 px-2 py-1 text-zinc-600 font-monda tracking-tighter font-bold text-[16px] rounded-md bg-zinc-100 transition ease-in-out delay-2 hover:-translate-y-[2px] hover:scale-80 hover:bg-gradient-to-r from-yellow-200 to-purple-200 duration-500'>Resume</div></a>
          </div>
          )}
      </>
    );
  };

export default NavThree;
