import MyWorkSection from "./home-elements/MyWorkSection";
import MyExtras from "./home-elements/MyExtras";
import UxEngineerSection from "./home-elements/UxEngineerSection";
import AccessibilitySection from "./home-elements/AccessibilitySection";
import HeroSection from "./home-elements/HeroSection";
import AboutSectionHome from "./home-elements/AboutSectionHome";
import TestFramer from "./home-elements/TestFramer";
export default function Home(){
    return(
        <>
     
      <HeroSection/>
      <AccessibilitySection/>
      <UxEngineerSection/>
      <MyWorkSection/>
      <MyExtras/>
      <AboutSectionHome/>
      {/* <TestFramer/> */}
     

        </>
    );
};