import React from 'react'

export default function DebateSection() {
  return (
    <>
    {/* Marquee 1*/}

                <div className='pb-5 md:pb-10'>
                    <div class="relative flex overflow-x-hidden font-poppins row-start-2 row-end-3">
                            <div class="py-12 animate-marquee whitespace-nowrap font-monda tracking-tighter font-bold text-zinc-200">
                             <span class="text-3xl lg:text-4xl mx-4">Am I a creative?</span>
                             <span class="text-3xl lg:text-4xl mx-4">Can I be a creative?</span>
                             <span class="text-3xl lg:text-4xl mx-4">Am I a creative?</span>
                             <span class="text-3xl lg:text-4xl mx-4">Can I be a Creative?</span>
                            </div>

                             <div class="absolute top-0 py-12 animate-marquee2 whitespace-nowrap font-monda tracking-tighter font-bold text-zinc-200">
                             <span class="text-3xl lg:text-4xl mx-4">Am I a creative?</span>
                             <span class="text-3xl lg:text-4xl mx-4">Can I be a creative?</span>
                             <span class="text-3xl lg:text-4xl mx-4">Am I a creative?</span>
                             <span class="text-3xl lg:text-4xl mx-4">Can I be a Creative?</span>
                             </div>
                    </div>
            </div>

    <div className='grid grid-cols-12'>
      <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 space-y-4 md:space-y-6'>
          <p className='w-full text-md lg:text-lg font-plus font-medium'>
          Although I loved art something never felt entirely right. I always wanted to use my creative passions to pursue something in this world yet still, I also held onto the dream of helping people.</p>
      </div>
      </div>

{/* Marquee 2 */}
            <div className='pt-5 md:pt-10'>
                    <div class="relative flex overflow-x-hidden font-poppins row-start-6 row-end-7">
                            <div class="py-12 animate-marquee whitespace-nowrap font-monda tracking-tighter font-bold text-zinc-200">
                             <span class="text-3xl lg:text-4xl mx-4">Can I help people?</span>
                             <span class="text-3xl lg:text-4xl mx-4">I want to help people!</span>
                             <span class="text-3xl lg:text-4xl mx-4">Can I help people?</span>
                             <span class="text-3xl lg:text-4xl mx-4">I want to help people!</span>
                            </div>

                             <div class="absolute top-0 py-12 animate-marquee2 whitespace-nowrap font-monda tracking-tighter font-bold text-zinc-200">
                             <span class="text-3xl lg:text-4xl mx-4">Can I help people?</span>
                             <span class="text-3xl lg:text-4xl mx-4">I want to help people!</span>
                             <span class="text-3xl lg:text-4xl mx-4">Can I help people?</span>
                             <span class="text-3xl lg:text-4xl mx-4">I want to help people!</span>
                             </div>
                    </div>
            </div>
    </>
  )
}
