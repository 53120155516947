// import CarouselOne from './CarouselOne';
// import FormResponses from './chromacity-images/form-responses.png';
// import ResearchCarouselWrapper from './ResearchCarouselWrapper';
import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';
import PortfolioResearchFindings from './PortfolioResearchFindings';
import CarouselTwoWrapperPort from './CarouselTwoWrapperPort';
import CarouselOneWrapperPort from './CarouselOneWrapperPort';



export default function ResearchChrom() {
  return (
    <>
        {/* Primary and Secondary Research */}
        <div className='grid grid-cols-12 w-screen h-auto pb-10 md:pb-20'>
        <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
        <div className='space-y-6'>
            <h3 className='text-left text-[32px] md:text-[40px] font-bold text-purple-800 font-monda tracking-tighter'>Creating a new foundation</h3>
            <p className='w-full text-left text-md md:text-lg font-plus tracking-tight font-medium'>Before I started, I knew it was important to take the next steps:</p>
            <ul className='pl-6 list-disc md:py-2 space-y-2 text-md md:text-lg font-plus w-full text-left tracking-tight font-medium'>
                        <li>Re-aligning my vision and deciciding on how I want to be perceived through proper planning and research.</li>
                        <li>Ensuring the copywriting includes keywords and phrases relating to the competencies of product accessibility, front-end engineering and product design.</li>
            </ul>
            {/* Unresponsive */}
      
              <div className='w-full p-2 h-auto'>
                <CarouselOneWrapperPort/>
              </div>
              <div className='w-full'>
                    <ul className='pl-6 list-disc md:py-2 space-y-2 text-md md:text-lg font-plus w-full text-left tracking-tight font-medium'>
                        <li>Clearly defined the purpose of the project and my vision by brainstorming keywords and themes that best personifed myself.</li>
                        <li>Created a moodboard on Milanote from various web-design inspiration platforms like Awwwards, Hoverstates and Loadmore.</li>
                    </ul>
              </div>
              <PortfolioResearchFindings/>
          <div className='w-full space-y-6 pt-10'>
          <h3 className='text-left text-[32px] md:text-[40px] font-bold text-purple-800 font-monda tracking-tighter'>Crafting the new experience</h3>
          <div className='w-full p-2 h-auto'>
            <CarouselTwoWrapperPort/>
              {/* <ResearchCarouselWrapper/> */}
              </div>
              <div className='w-full space-y-6'>
              <p className='text-left font-plus font-medium md:text-lg text-md w-full h-auto'>After establishing my new vision, I felt confident to prototype my website. I accomplished this by completing the following tasks:</p>
                    <ul className='pl-6 list-disc pb-2 md:pb-0 md:py-2 space-y-2 text-md md:text-lg font-plus w-full text-left tracking-tight font-medium'>
                        <li>Built the site map for platform.</li>
                        <li>Created design system for my typography using M PLUS 1 for body text and Monda for headings.</li>
                        <li>Built a medium fidelity prototype on Figma and sent to trusted circle of peers for feedback, including mentors from ADPList.</li>
                        <li>Coded portfolio using used Framer Motion, React.js and Tailwind CSS to build platform within Visual Studio Code.</li>
                    </ul>
              </div>
            </div>
                  {/* ---Other Interesting Finds--- */}
        <div className='w-full h-auto md:space-y-6 space-y-4'>
        <h3 className='text-purple-800 text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full leading-tight'>Highlighted feedback</h3>
            {/* Regular */}
            <div className='p-8 w-full h-auto hidden md:grid grid-cols-2 gap-12 bg-purple-700 rounded-md font-medium'>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>The feedback highlighted my tendency to create "safe" versions of the website that greatly inspired me. I was encouraged to step out of my comfort zone if I wanted to create an impressionable and memorable website.</div>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>The feedback additionally highlighted the need to pay closer attention to the fundamental visual design of the UI, specifically with my typography skills. Therefore, I took classes to improve them.</div>
            </div>
            {/* Responsive */}
            <div className='p-8 w-full h-auto grid md:hidden grid-rows-2 gap-8 bg-purple-700 rounded-md text-md font-medium'>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>Feedback highlighted my tendency to create "safe" versions of the website that greatly inspired me. I was encouraged to step out of my comfort zone if I wanted to create an impressionable and memorable website.</div>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>Feedback highlighted the need to pay closer attention to the fundamental visual design of the UI, specifically with my typography skills. I took classes to improve them.</div>
            </div>
        </div>
        </div>
        </div>
        </div>
    </>
  )
}
