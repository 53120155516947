import React from 'react';

export default function ProdigiSummary() {
  return (
    <>
          <div className='grid grid-cols-12 pb-8 pt-2 md:pb-0 md:pt-2 md:py-16 w-screen h-auto bg-white'>
    <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
    <div className='w-full h-auto space-y-6 grid place-items-center'>
            <div className='text-4xl md:text-[52px] w-full h-auto font-monda tracking-tighter font-bold text-zinc-800'>Project summary</div>
            
            <div className='text-orange-800 w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold leading-tight'>Torrens University Australia Discovery Stage</div>
            <div className='py-2 md:py-8 w-full'>
              <p className='w-full text-left text-md md:text-lg font-medium'>Due to my contract, I cannot go into further detail of the project's requirements on this platform. To learn more please feel free to contact me over email for more information.</p>
            </div>

        </div>
      </div>
      </div>
    </>
  )
}
