import React from 'react'

export default function PlancoVideoOne() {
  return (
    <>
    <div className="w-full h-auto bg-white font-plus text-zinc-900 grid grid-cols-12 pb-10 md:pb-20">
        <div className="md:col-start-3 md:col-end-11 col-start-2 col-end-12 space-y-2 md:space-y-4">
        <div className='w-[100%] h-auto rounded-md grid place-items-center'>
                <div className='w-full h-[70vh] flex justify-center'>
                  <iframe className='w-full h-auto rounded-md' src="https://www.youtube.com/embed/W93lwZxsqPg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
        </div>
 
        </div>
    </div>
    </>
  )
}