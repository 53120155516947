import React from 'react';
import MyLogo from '../pages/home-elements/home-images/Logo.png'

export default function Logo() {
  return (
<div className="h-12 w-auto ml-2 flex justify-center items-center gap-2 md:gap-4">
    <img src={MyLogo} className='md:w-8 w-6 md:h-8 h-6'/>
    <div className='tracking-tighter font-monda font-bold text-md md:text-lg text-zinc-700'>Ayisha Taye Zraika</div>
</div>
  )
}
