import React from 'react';
import FeatureOnePlanco from './planco-images/planco-screen-one.png';
import FeatureTwoPlanco from './planco-images/planco-screen-two.png' ;
import FeatureThreePlanco from './planco-images/planco-screen-three.png' ;
import FeatureFourPlanco from './planco-images/planco-screen-four.png' ;

export default function PlancoFeatureBreakdown() {
  return (
    <>

    {/* LARGE */}

    <div className='hidden lg:grid grid-cols-12 w-screen h-auto py-10 md:py-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
            <div className='w-full h-auto md:space-y-6 space-y-4'>
                <h3 className='text-left text-4xl md:text-[52px] font-bold text-teal-800 font-monda tracking-tighter'>Final prototype</h3>
                {/* Regular */}
                <div className='w-full grid grid-cols-4 gap-20 pt-10'>
                    <div className='col-span-1 h-auto space-y-2'>
                        <img src={FeatureOnePlanco} className=''/>
                        <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step one</p>
                        <p className='text-teal-950 w-full md:text-xl text-lg font-bold'>Eco-friendly quiz</p>
                        <p className='text-md md:text-lg font-medium'>Provides short quizzes to learn more about the user.</p>
                    </div>

                    <div className='col-span-1 h-auto space-y-2'>
                        <img src={FeatureTwoPlanco} className=''/>
                        <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step two</p>
                        <p className='text-teal-950 w-full md:text-xl text-lg font-bold'>Eco-Score</p>
                        <p className='text-md md:text-lg font-medium'>Check your results to see how green you are and get suggestions for two starting activities.</p>
                    </div>

                    <div className='col-span-1 h-auto space-y-2'>
                        <img src={FeatureThreePlanco} className=''/>
                        <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step three</p>
                        <p className='text-teal-950 w-full md:text-xl text-lg font-bold'>Eco-Plan</p>
                        <p className='text-md md:text-lg font-medium'>A beginner's plan is suggested according to your quiz results, covering prices and included features.</p>
                    </div>

                    <div className='col-span-1 h-auto space-y-2'>
                        <img src={FeatureFourPlanco} className=''/>
                        <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step four</p>
                        <p className='text-teal-950 w-full md:text-xl text-lg font-bold'>Eco-Path</p>
                        <p className='text-md md:text-lg font-medium'>Access all platform features and start your journey towards sustainability.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>




    {/* MEDIUM */}
    <div className='hidden md:grid lg:hidden grid-cols-12 w-screen h-auto py-10 md:py-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
        <div className='w-full h-auto md:space-y-6 space-y-4'>
            <h3 className='text-left text-4xl md:text-[52px] font-bold text-teal-800 font-monda tracking-tighter'>Feature breakdown</h3>
            
            <div className='w-full h-auto grid grid-cols-2 gap-4 pt-8 pb-4 place-items-center'>
                <div className='col-span-1 h-96'>
                    <img src={FeatureOnePlanco} className='h-full'/>
                </div>
                <div className='col-span-1 h-auto space-y-2'>
                    {/* Elements of features */}
                    <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step one</p>
                    <p className='text-teal-950 w-full md:text-xl text-lg font-bold'>Eco-friendly quiz</p>
                        <p className='text-md md:text-lg font-medium'>Provides short quizzes to learn more about the user.</p>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-2 gap-4 pt-4 place-items-center'>
                <div className='col-span-1 h-auto space-y-2'>                    
                    <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step two</p>
                    <p className='w-full md:text-xl text-lg font-bold'>Eco-Score</p>
                    <p className='text-md md:text-lg font-medium'>Check your results to see how green you are and get suggestions for two starting activities.</p>              
                </div>
                <div className='col-span-1 h-96'>
                    <img src={FeatureTwoPlanco} className='h-full'/>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-2 gap-4 place-items-center'>
                <div className='col-span-1 h-96 '>
                    <img src={FeatureThreePlanco} className='h-full'/>
                </div>
                <div className='col-span-1 h-auto space-y-2'>
                    <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step three</p>
                    <p className='w-full md:text-xl text-lg font-bold'>Eco-Plan</p>
                    <p className='text-md md:text-lg font-medium'>A beginner's plan is suggested according to your quiz results, covering prices and included features.</p>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-2 gap-4 place-items-center'>
                <div className='col-span-1 h-auto space-y-2 grid'>
                    <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step four</p>
                    <p className='w-full md:text-xl text-lg font-bold'>Eco-Path</p>
                    <p className='text-md md:text-lg font-medium'>Access all platform features and start your journey towards sustainability.</p>
                </div>
                <div className='col-span-1 h-96'>
                    <img src={FeatureFourPlanco} className='h-full'/>
                </div>
            </div>
        </div>
        </div>
        </div>

            {/* SMALL */}
    <div className='md:hidden grid grid-cols-12 w-screen h-auto py-10 md:py-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
        <div className='w-full h-auto space-y-8'>
            <h3 className='text-left text-4xl md:text-[52px] font-bold text-cyan-800 font-monda tracking-tighter'>Feature breakdown</h3>
            <div className='w-full h-auto space-y-6 grid place-items-center'>
                <div className='h-auto rounded-md w-40'><img src={FeatureOnePlanco}/></div>
                <div className='h-auto space-y-2'>
                    <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step one</p>
                    <p className='text-teal-950 w-full md:text-xl text-lg font-bold'>Eco-friendly quiz</p>
                    <p className='space-y-2text-md md:text-lg font-medium'>Provides short quizzes to learn more about the user.</p>
                </div>
            </div>
            <div className='w-full h-auto space-y-6 grid place-items-center '>
                <div className='w-40 grid place-items-center'>
                <img src={FeatureTwoPlanco}/>
                </div>
                <div className='h-auto space-y-2'>                    
                    <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step two</p>
                    <p className='text-teal-950 w-full md:text-xl text-lg font-bold'>Eco-Score</p>
                    <p className='space-y-2 text-md md:text-lg font-medium'>Check your results to see how green you are and get suggestions for two starting activities.</p>
                </div>
            </div>
            <div className='w-full h-auto space-y-6 grid place-items-center'>
                <div className='w-40 grid place-items-center'>
                    <img src={FeatureThreePlanco}/>
                </div>
                <div className='h-auto space-y-2'>
                <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step three</p>
                    <p className='text-teal-950 w-full md:text-xl text-lg font-bold'>Eco-Plan</p>
                    <p className='space-y-2 text-md md:text-lg font-medium'>A beginner's plan is suggested according to your quiz results, covering prices and included features.</p>
                </div>
            </div>
            <div className='w-full h-auto space-y-6 grid place-items-center'>
                <div className='w-40 grid place-items-center'>
                    <img src={FeatureFourPlanco}/>
                </div>
                <div className='h-auto space-y-2'>
                <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Step four</p>
                    <p className='text-teal-950 w-full md:text-xl text-lg font-bold'>Eco-Path</p>
                    <p className='space-y-2 text-md md:text-lg font-medium'>Access all platform features and start your journey towards sustainability.</p>
                </div>
            </div>


            
        </div>
        </div>
        </div>
        
    </>
  )
}
