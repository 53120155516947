import React from 'react';
import HomeLogo from './planco-images/home-logo.png';
import NotificationsLogo from './planco-images/notifications-logo.png' ;
import PathsLogo from './planco-images/paths-logo.png' ;
import SavedLogo from './planco-images/saved-logo.png' ;
import SeedsLogo from './planco-images/seeds-logo.png' ;


export default function PlancoFeatureBreakdownTwo() {
  return (
    <>
    {/* Feature Unresponsive */}
    <div className='hidden lg:grid grid-cols-12 w-screen h-auto pb-10 md:pb-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
        <div className='w-full h-auto md:space-y-6 space-y-4'>
            <h3 className='text-left text-4xl md:text-[52px] font-bold text-teal-800 font-monda tracking-tighter'>Feature breakdown</h3>
            <div className='w-full h-auto grid grid-cols-4 gap-20 py-4 place-items-start items-center'>
                <div className='col-span-1 w-full aspect-square rounded-xl shadow-md'>
                    <img src={HomeLogo}/>
                </div>
                <div className='col-span-3 h-auto space-y-2'>
                    {/* Elements of features */}
                    <p className='text-teal-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Home</p>
                    <p className='text-teal-950 text-left w-full md:text-xl text-lg font-bold'>Presents a personalised dashboard and shortcuts to various parts of Planco.</p>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-4 gap-20 py-4 place-items-start items-center'>
                <div className='col-span-3 h-auto space-y-2'>                    
                    <p className='text-teal-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Notifications</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Stay up to date with everything on your platform.</p>
                </div>
                <div className='col-span-1 w-full aspect-square rounded-xl shadow-md'>
                    <img src={NotificationsLogo} className='h-full'/>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-4 gap-20 py-4 place-items-start items-center'>
                <div className='col-span-1 w-full aspect-square brounded-xl shadow-md'>
                    <img src={PathsLogo} className='h-full'/>
                </div>
                <div className='col-span-3 h-auto space-y-2'>
                <p className='text-teal-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Paths</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Provides short classes and resources for improving your eco-knowledge.</p>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-4 gap-20 py-4 place-items-start items-center'>
                <div className='col-span-3 h-auto space-y-2 grid'>
                <p className='text-teal-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Saved</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Access all of your saved classes and Seeds from here.</p>
                </div>
                <div className='col-span-1 w-full aspect-square rounded-xl shadow-md'>
                    <img src={SavedLogo} className='h-full'/>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-4 gap-20 py-4 place-items-start items-center'>
                <div className='col-span-1 w-full aspect-square brounded-xl shadow-md'>
                    <img src={SeedsLogo} className='h-full'/>
                </div>
                <div className='col-span-3 h-auto space-y-2'>
                <p className='text-teal-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Seeds</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Short form content for users to scroll through and gain quick tips on sustainability.</p>
                </div>
            </div>
        </div>
        </div>
        </div>

            {/* Feature Responsive */}
    <div className='lg:hidden grid grid-cols-12 w-screen h-auto py-10 md:py-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
        <div className='w-full h-auto space-y-8 grid place-items-center'>
            <h3 className='text-left text-4xl md:text-[52px] font-bold text-cyan-800 font-monda tracking-tighter'>Feature breakdown</h3>
            <div className='w-full h-auto space-y-6 grid place-items-center'>
                <div className='w-60 aspect-square  rounded-xl shadow-md'>
                    <img src={HomeLogo}/>
                </div>
                <div className='h-auto space-y-2 grid place-items-center'>
                <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Home</p>
                    <p className='w-[80%] md:text-xl text-lg font-bold'>Presents a personalised dashboard and shortcuts to various parts of Planco.</p>
                </div>
            </div>
            <div className='w-full h-auto space-y-6 grid place-items-center'>
                <div className='w-60 aspect-square  rounded-xl shadow-md'>
                    <img src={NotificationsLogo} className='h-full'/>
                </div>
                <div className='h-auto space-y-2 grid place-items-center'>
                <p className='text-teal-800 text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Notifications</p>
                    <p className='w-[80%] md:text-xl text-lg font-bold'>Stay up to date with everything on your platform.</p>
                </div>
            </div>
            <div className='w-full h-auto space-y-6 grid place-items-center'>
                <div className='w-60 aspect-square rounded-xl shadow-md'>
                    <img src={PathsLogo} className='h-full'/>
                </div>
                <div className='h-auto space-y-2'>
                <p className='text-teal-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Paths</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Provides short classes and resources for improving your eco-knowledge.</p>
                </div>
            </div>
            <div className='w-full h-auto space-y-6 grid place-items-center'>
                <div className='w-60 aspect-square rounded-xl shadow-md'>
                    <img src={SavedLogo} className='h-full'/>
                </div>
                <div className='h-auto space-y-2'>
                <p className='text-teal-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Saved</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Access all of your saved classes and Seeds from here.</p>
                </div>
            </div>
            <div className='w-full h-auto space-y-6 grid place-items-center'>
                <div className='w-60 aspect-square rounded-xl shadow-md'>
                    <img src={SeedsLogo} className='h-full'/>
                </div>
                <div className='h-auto space-y-2'>
                <p className='text-teal-800 text-left text-[24px] md:text-[30px] font-monda tracking-tighter font-bold'>Seeds</p>
                    <p className='text-left w-full md:text-xl text-lg font-bold'>Short form content for users to scroll through and gain quick tips on sustainability.</p>
                </div>
            </div>
        </div>
        </div>
        </div>
    </>
  )
}
