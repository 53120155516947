import React from 'react'
import IntroIntroduction from './intro-2022-elements/IntroIntroduction'
import IntroVideos from './intro-2022-elements/IntroVideos'
import IntroWorkOfficial from './intro-2022-elements/IntroWorkOfficial'

export default function IntroPage() {
  return (
    <>
    <IntroIntroduction/>
    <IntroVideos/>
    <IntroWorkOfficial/>
    </>
  )
}

