import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';

export default function XlabsIntro() {
  return (
    <>
          <div className='w-full h-auto grid grid-cols-12 place-items-center bg-gradient-to-r from-lime-600 via-cyan-700 to-violet-600'>
      <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 grid place-items-center'>
      <motion.div initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }} className='w-full h-auto grid grid-place-items-center bg-white/20 p-10 md:p-20 rounded-md my-10'>
              <h1 className='text-[44px] sm:text-[60px] text-white font-monda font-bold tracking-tighter w-full leading-tight'>X-Labs x Luminary - NFP + Youth Audiences</h1>
                <p className='text-[24px] font-monda text-white font-bold tracking-tighter py-5'>Youth Design Stratergy Project</p>
                <div className='w-full space-y-2 pb-4'>
                  <p className='text-white text-md md:text-lg font-medium'>X-Labs is Billy Blue’s experimental creative technology research hub. For this project, our team worked with the digital design agency Luminary to create a digital strategy solution, based on the provided design brief.</p>
              </div>
          </motion.div>
      </div>
    </div>
    </>
  )
}
