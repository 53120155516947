import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';


export default function PortfolioIntro() {
  return (
    <>
    <div className='w-full h-auto grid grid-cols-12 place-items-center bg-gradient-to-r to-yellow-800 from-purple-700'>
      <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 grid place-items-center'>
      <motion.div initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }} className='w-full h-auto grid grid-place-items-center bg-white/20 p-10 md:p-20 rounded-md my-8 md:my-10'>
              <h1 className='text-[44px] sm:text-[60px] text-white font-monda font-bold tracking-tighter w-full'>Portfolio Redesign</h1>
                <p className='text-[24px] font-monda text-white font-bold tracking-tighter pb-5 md:pb-6'>Re-aligning and rebranding</p>
                <div className='w-full space-y-2 pb-4'>
                <p className='text-white text-md md:text-lg font-medium'>My portfolio is an all-in-one place to learn and experience all of my skills as a designer.  Since I aim for roles in front-end engineering and product design, I believed that it was crucial to package my work into a platform that acts as it's own body of work by combining all my skills into one product.</p>
              </div>
          </motion.div>
      </div>
    </div>
    </>
  )
}
