import React from 'react'
import DiscoverIntro from './chromacity-elements/DiscoverIntro'
import MockUpOneChrom from './chromacity-elements/MockUpOneChrom'
import UserPersonasChrom from './chromacity-elements/UserPersonasChrom'
import DevelopChrom from './chromacity-elements/DevelopChrom'
import WireframingChrom from './chromacity-elements/WireframingChrom'
import DeliverChrom from './chromacity-elements/DeliverChrom'
import ChromReflection from './chromacity-elements/ChromReflection'
import ChromEnding from './chromacity-elements/ChromEnding'
import DesignProcessChrom from './chromacity-elements/DesignProcessChrom'
import ResearchChrom from './chromacity-elements/ResearchChrom'
import DefineChrom from './chromacity-elements/DefineChrom'
import ChromFinalDesign from './chromacity-elements/ChromFinalDesign'
import ChromFeatureBreakdown from './chromacity-elements/ChromFeatureBreakdown'
import ChromIntro from './chromacity-elements/ChromIntro'
import ChromVideoOne from './chromacity-elements/ChromVideoOne'


export default function ChromacityPage() {
  return (
    <>
        <ChromIntro/>
        <DesignProcessChrom/>
        <ChromVideoOne/>
        {/* <MockUpOneChrom/> */}
        <DiscoverIntro/>
        <ResearchChrom/>
        <DefineChrom/>
        <UserPersonasChrom/>
        <DevelopChrom/>
        {/* <WireframingChrom/> */}
        <DeliverChrom/>
        <ChromFinalDesign/>
        <ChromFeatureBreakdown/>
        <ChromReflection/>
        <ChromEnding/>
    </>
  )
}
