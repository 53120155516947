import React from 'react'
import ProdigiIntro from './prodigi-elements/ProdigiIntro'
import ProdigiReflection from './prodigi-elements/ProdigiReflection'
import ProdigiSummary from './prodigi-elements/ProdigiSummary'
import ProdigiLink from './prodigi-elements/ProdigiLink'
import ProdigiProcess from './prodigi-elements/ProdigiProcess'

export default function ProdigiPage() {
  return (
    <>
      <ProdigiIntro/>
      <ProdigiProcess/>
      <ProdigiReflection/>
      <ProdigiSummary/>
      <ProdigiLink/>
    </>
  )
}
