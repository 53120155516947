import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';
import FinalSolutionPlanco from './planco-images/planco-reveal.png';

export default function PlancoFinalDesign() {
  return (
    <>
    {/* Final Design */}
    <div className='grid grid-cols-12 w-screen h-auto py-10 md:py-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
        <div className='w-full h-auto space-y-4 md:space-y-6'>
            <motion.h3 initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }} className='text-left text-4xl md:text-[52px] font-bold text-teal-950 font-monda tracking-tighter'>Introducing, Planco!</motion.h3>
            <motion.ul initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }} className='space-y-2 list-disc pl-6'>
                <li className='w-full h-auto font-plus font-medium text-lg text-left'><span className='font-bold text-teal-800'>Created a web-based service</span> that <span className='font-bold text-teal-800'>equips eco-friendly beginners with all the tools they need</span> to begin their sustainability journey.</li>
                <li className='w-full h-auto font-plus font-medium text-lg text-left'>Provides <span className='font-bold text-teal-800'>personalised plans, coaching and quizzes</span> for users to analyse where they are on their <span className='font-bold text-teal-800'>journey to reduce their carbon footprint.</span></li>
                <li className='w-full h-auto font-plus font-medium text-lg text-left'><span className='font-bold text-teal-800'>Widget and Smart Watch integrations</span> available to optimise user success with their personalised programs.</li>
            </motion.ul>
            <div className='w-full h-auto bg-slate-200 rounded-md overflow-hidden'>
              <img src={FinalSolutionPlanco} className='w-full'/>
            </div>
        </div>
        </div>
        </div>
    </>
  )
}
