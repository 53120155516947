import React from 'react';
import PrototypeOnePlanco from './planco-images/prototype-one-planco.png';

export default function PrototypeOne() {
  return (
    <>
              <div className='grid grid-cols-12 w-screen h-auto pb-10 md:pb-20'>
        <div className='md:col-start-3 col-start-2 md:col-end-11 col-end-12 grid place-items-center'>
        <div className='w-full h-auto space-y-4 grid place-items-center'>
            <p className='text-left text-teal-800 text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>Prototype one</p>
            <div className='w-[80%] h-fit rounded-md grid place-items-center overflow-hidden bg-red-400'>
              <img src={PrototypeOnePlanco}></img>
            </div>
            <p className='py-4 w-full h-auto text-md font-medium md:text-lg font-plus'>After creating the first prototype, I realized that simply presenting the platform as a resource hub for eco-friendliness wasn't sufficient for engaging users. The feedback I received emphasized the need to reframe the features to provide users with compelling reasons to return.</p>            {/* Regular */}
            <div className='p-8 w-full h-auto hidden md:grid grid-cols-3 gap-12 bg-teal-700 rounded-md font-medium'>
                <div className='col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>Demonstrate the practical applications of this service beyond the platform. </div>
                <div className='col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>Explanations of the features need to be clearer.</div>
                <div className='col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>Only using articles could lead to disengagement. Explore different ways to enhance the user experience.</div>
            </div>
            {/* Responsive */}
            <div className='p-8 w-full h-auto grid md:hidden grid-rows-3 gap-8 bg-teal-700 rounded-md text-md font-medium'>
                <div className='row-span-1 bg-white p-4 rounded-md grid place-items-center'>Demonstrate the practical applications of this service beyond the platform. </div>
                <div className='row-span-1 bg-white p-4 rounded-md grid place-items-center'>Explanations of the features need to be clearer.</div>
                <div className='row-span-1 bg-white p-4 rounded-md grid place-items-center'>Only using articles could lead to disengagement. Explore different ways to enhance the user experience.</div>
            </div>
        </div>
        </div>
        </div>
    </>
  )
}
