import React from 'react';
import AboutIntro from './about-page-elements/AboutIntro';
import DebateSection from './about-page-elements/DebateSection';
import DecisionSection from './about-page-elements/DecisionSection';
import JourneySection from './about-page-elements/JourneySection';
import GoalSection from './about-page-elements/GoalSection';

export default function AboutPage() {
  return (
    <>
    <AboutIntro/>
    <DebateSection/>
    <DecisionSection/>
    <GoalSection/>
    <JourneySection/>


    </>
  )
}
