import React from 'react';
import BrainstormCarouselContent from './ResearchCarouselContent';
import TestIsh from './chromacity-images/two-test.jpg';
import TestIshTwo from './chromacity-images/three-test.jpg';
import BsOne from './chromacity-images/brainstorm-1.png';
import BsTwo from './chromacity-images/brainstorm-2.png';
import MoodBoard from './chromacity-images/brainstorm-moodboard.png';
import HowMightWe from './chromacity-images/brainstorm-howmightwe.png';
import MindMap from './chromacity-images/brainstorming-mindmap.png';
import SketchIdeas from './chromacity-images/brainstorm-sketch.png';
import LofiTest from './chromacity-images/lofi-prototype.png'




const slides =[
   
  HowMightWe, MindMap, MoodBoard, SketchIdeas, BsOne, BsTwo, LofiTest 

]

export default function BrainstormCarouselWrapper() {
  return (
    <>
    <div className='w-full h-auto grid'>

    <div className="w-auto h-auto">
        <BrainstormCarouselContent>
            {slides.map((s)=>(
                <img src={s}/>
            ))}
        </BrainstormCarouselContent>
    </div>

    </div>
      
    </>
  )
}