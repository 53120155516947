import React from 'react'
import PlancoMoodboard from './planco-images/moodboard-planco.png'
import PlancoBrand from './planco-images/brand-ideation.png'


export default function PlancoBrainstorm() {
  return (
    <>
      
    <div className='grid grid-cols-12 w-screen h-auto bg-white py-10 md:py-20'>
        <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
            <div className='w-full h-auto space-y-4 md:space-y-6'>
            <h3 className='text-left text-[44px] md:text-[52px] font-bold text-zinc-900 font-monda tracking-tighter'>Develop</h3>
            <p className='font-plus font-medium text-md md:text-lg text-left pb-4'>Now that we've laid a strong foundation, it's time to explore potential solutions. I did this by rephrasing the problem to uncover opportunities for progress in harmony.</p>
            <div className='w-full h-auto py-6 px-6 md:py-10 md:px-10 space-y-4 md:space-y-6 rounded-xl text-teal-900 bg-teal-100'>
                <p className='text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left'>New problem statement</p>
                <p className='text-lg md:text-xl font-medium font-plus text-left'>How can we make sustainablility services accessible while simplifying eco-friendly decision-making for Australian youth and families?</p>
            </div>
            <p className='pt-2 text-left text-[32px] md:text-[40px] font-bold font-monda tracking-tighter text-teal-800'>Brainstorming</p>            
            <div className='w-full grid place-items-center space-y-8'>
              <div className='h-auto w-full space-y-4 md:space-y-6 grid place-items-center'>
                <div className='h-auto w-full rounded-md overflow-hidden grid place-items-center'>
                  <img src={PlancoMoodboard} className='scale-[1.4]'/> 
                </div>
                <p className='text-md md:text-lg font-medium font-plus'>Mood board</p>
              </div>
              <div className='h-auto space-y-4 md:space-y-6 grid place-items-center w-full'>
                <div className='h-auto w-full rounded-md overflow-hidden grid place-items-center shadow-sm'>
                  <img src={PlancoBrand} className='grid place-items-center'/> 
                </div>
                <p className='text-md md:text-lg font-medium font-plus'>Brand ideation</p>
              </div>
            </div>
            {/* <BrainstormCarouselWrapper/> */}
        </div>
      </div>
      </div>    
    </>
  )
}
