import React from 'react';
import PortfolioIntro from './portfolio-piece-elements/PortfolioIntro';
import PortfolioDesignProcess from './portfolio-piece-elements/PortfolioDesignProcess';
import PortfolioDiscover from './portfolio-piece-elements/PortfolioDiscover';
import PortfolioPieceResearch from './portfolio-piece-elements/PortfolioPieceResearch';
import PortfolioSolution from './portfolio-piece-elements/PortfolioSolution';
import PortfolioReflection from './portfolio-piece-elements/PortfolioReflection';

export default function PortfolioPiecePage() {
  return (
    <>
      <PortfolioIntro/>
      <PortfolioDesignProcess/>
      <PortfolioDiscover/>
      <PortfolioPieceResearch/>
      <PortfolioSolution/>
      <PortfolioReflection/>
    </>
  )
}
