import React from 'react';
import CanvaSlideProdigi from '../prodigi-elements/prodigi-images/prodigi-slide.png';


export default function ProdigiLink() {
  return (
    <>
      <div className='w-full h-auto py-6 md:py-10 grid place-items-center'>
        <div className="space-y-4 grid place-items-center">
            <div className="text-[30px] font-monda font-bold tracking-tighter">Group project deck</div>
                <div className='w-[70%] md:w-72 md:h-48 rounded-md overflow-hidden drop-shadow-sm grid place-items-center'>
                  <img src={CanvaSlideProdigi} className='scale-[1.6]'/>
                </div>
            <a href="https://www.canva.com/design/DAFzXy6jebY/6pJA4SNV1JZulCumzbJ7yw/edit?utm_content=DAFzXy6jebY&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton"><button className='font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-orange-700 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>Process deck</button></a>
        </div>
      </div>
    </>
  )
}
