import React from 'react';
import Logo from './Logo';
import NavThree from './NavThree';

export default function Header() {
  return (
    <>
    <div className='h-auto sticky top-0 z-[20] mx-auto grid grid-cols-12 bg-white drop-shadow-sm w-screen'>
        <header className='md:col-start-3 md:col-end-11 col-start-2 col-end-12  flex flex-wrap w-full justify-between place-items-center'>
            <Logo/>
            <NavThree/>
        </header>
    </div>
    </>
  )
}
