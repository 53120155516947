import React from 'react';
import {motion} from 'framer-motion';


export default function UxEngineerSection() {


  return (
    <>
    
    <div className='grid w-screen h-auto grid-cols-12 bg-white tracking-tight py-10'>
        <div className='col-start-3 col-end-11 grid row-auto rounded-xl font-bold space-y-8'>
            <h1 className='h-fit py-0 md:py-4 text-[40px] sm:text-5xl md:text-6xl tracking-tighter leading-tight font-bold font-monda bg-gradient-to-r from-yellow-500 to-purple-600 text-transparent bg-clip-text md:leading-snug w-full'>Obsessed with UX Engineering</h1>
            {/* Unresponsive */}
            <div className='rounded-md grid self-center w-full h-auto place-items-center text-white font-plus'>
              <motion.div className='rounded-md w-[80%] h-auto text-md md:text-xl p-4 bg-white cursor-grab grid place-items-center font-monda tracking-tighter bg-gradient-to-r from-yellow-500 to-purple-600' 
                 initial={{ scale:0,
                            }}
                 whileHover={{
                   scale: 1.2,
                   transition: { duration: 0.5 },
                 }}
                 whileTap={{ scale: 0.9 }}
                 whileInView={{ opacity: 1, transition: { duration: 0.5 }, scale:1 }}>
                  Made using Framer Motion, React.js and Tailwind CSS. 
                </motion.div>
            </div>
            {/* Responsive */}
            <p className='leading-snug text-xl md:text-2xl font-medium'><span class="bg-gradient-to-r from-yellow-500 to-purple-600 h-fit text-transparent bg-clip-text font-black">I am also obsessed with the intersection of UX Design and Front-End Engineering.</span> My educational background in both fields revealed to me that there are many communication issues between both disciplines whilst working together. I want to support these teams with achieving reasonable compromises, which will enable companies create outstanding yet impactful design solutions. </p>
        </div>
      </div>
    </>
  )
}
