import React from 'react';
import ResearchCarouselContent from './ResearchCarouselContent';
import TestIsh from './chromacity-images/two-test.jpg';
import TestIshTwo from './chromacity-images/three-test.jpg';
import SrOne from './chromacity-images/secondary-research-1.png';
import SrTwo from './chromacity-images/secondary-research-2.png';
import SrThree from './chromacity-images/secondary-research-3.png';
import SrFour from './chromacity-images/secondary-research-4.png';


const slides =[
   
    SrOne, SrTwo, SrThree, SrFour,

]

export default function ResearchCarouselWrapper() {
  return (
    <>
    <div className='w-full h-auto grid'>

    <div className="w-auto h-auto pb-4">
        <ResearchCarouselContent>
            {slides.map((s)=>(
                <img src={s}/>
            ))}
        </ResearchCarouselContent>
    </div>

    </div>
      
    </>
  )
}
