import RiskyAssumptions from './planco-images/risky-assumptions.png';
import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';

export default function DiscoverIntroPlanco() {
  return (
    <>

    {/* Discover */}
    <div className='grid grid-cols-12 w-screen h-auto py-10 md:py-20'>
      <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
        <div className='space-y-4 md:space-y-6 grid place-items-center'>
        <motion.h1 initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }} className='text-[44px] md:text-[52px] font-monda font-bold tracking-tighter w-full text-left text-zinc-800'>Discover</motion.h1>
        <p initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }} className='w-full text-left text-md md:text-lg font-plus tracking-tight font-medium'>Based on the project requirements it was outlined to brainstorm a collection of ideas revolving around these key questions:</p>
            <ul className='pl-6 list-disc md:py-2 space-y-2 text-md md:text-lg font-plus w-full text-left tracking-tight font-medium'>
                        <li>Is it worth solving?</li>
                        <li>Is it evident enough?</li>
                        <li>Is there a market for it?</li>
            </ul>
            <p className='w-full text-left text-md md:text-lg font-plus tracking-tight font-medium'>I decided upon the topic of eco-friendliness and sustainability. To complete my initial research, I needed to understand society's current perspective on these topics. To ensure the research is useful, I created a chart to identify risky assumptions. It helped pinpoint which points had enough supporting data and which ones didn't.</p>
            <div className='w-full h-auto grid place-items-center'>
              <div className='md:py-6 py-2 w-full md:w-[80%] h-auto rounded-md shadow-md'>
                <img src={RiskyAssumptions}/>
              </div>
            </div>
            <h3 className='text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter'>My riskiest assumptions</h3>


            {/* <div className='w-full h-auto rounded-xl text-zinc-700 grid place-items-center space-y-4 md:space-y-6'>
              <img src={IbisGraphOne}  className='h-fit'/>
            </div> */}
            {/* Unresponsive */}
            <div className='hidden md:grid grid-cols-3 gap-12 pb-8'>
              <div className='col-span-1 grid place-items-center p-8 rounded-md bg-teal-50'>
                <div className='w-32 h-32 grid place-items-center'>
                  <svg class="h-28 w-28 text-teal-800"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>  
                  </svg>
                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>Making sustainable choices is habitual, influenced by various factors like income and location, which determine our priorities.</p> 
              </div>
              <div className='col-span-1 grid place-items-center p-8 rounded-md bg-teal-50'>
                <div className='w-32 h-32 grid place-items-center'>
                  <svg class="h-28 w-28 text-teal-800"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.4" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1" /></svg>                
                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>Most sustainability practices aren't entirely accessible or convenient; bringing a glass container to work - while eco-friendly - is still difficult for those with physical limitations.</p> 
              </div>
              <div className='col-span-1 grid place-items-center p-8 rounded-md bg-teal-50'>
                <div className='w-32 h-32 grid place-items-center'>
                  <svg class="h-28 w-28 text-teal-800"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
                  </svg>
                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>Sustainability companies aim to be inclusive, but social limitations are a present barrier. For lower-income people, sustainability may feel like a privilege.</p> 
              </div>
            </div>

            {/* Responsive */}
            <div className='grid md:hidden grid-row-3 gap-6 pb-4'>
              <div className='row-span-1 grid place-items-center p-8 rounded-md bg-teal-50'>
                <div className='w-24 h-24 grid place-items-center'>
                <svg class="h-20 w-20 text-teal-800"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>  
                  </svg>                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>Making sustainable choices is habitual, influenced by various factors like income and location, which determine our priorities.</p> 
              </div>
              <div className='row-span-1 grid place-items-center p-8 rounded-md bg-teal-50'>
                <div className='w-24 h-24 grid place-items-center'>
                  <svg class="h-20 w-20 text-teal-800"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.4" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M4 7h3a1 1 0 0 0 1 -1v-1a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0 -1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-1a2 2 0 0 0 -4 0v1a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1h1a2 2 0 0 0 0 -4h-1a1 1 0 0 1 -1 -1v-3a1 1 0 0 1 1 -1" /></svg>                
                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>Most sustainability practices aren't entirely accessible or convenient; bringing a glass container to work - while eco-friendly - is still difficult for those with physical limitations.</p> 
              </div>
              <div className='row-span-1 grid place-items-center p-8 rounded-md bg-teal-50'>
                <div className='w-24 h-24 grid place-items-center'>
                  <svg class="h-20 w-20 text-teal-800"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
                  </svg>                </div>
                <p className='w-full font-medium font-plus md:text-lg text-md'>Sustainability companies aim to be inclusive, but social limitations are a present barrier. For lower-income people, sustainability may feel like a privilege.</p> 
              </div>
            </div>

            <div className='w-full h-auto py-6 px-6 md:py-10 md:px-10 space-y-4 md:space-y-6 rounded-xl text-white bg-teal-700'>
                <p className='text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left'>Problem</p>
                <p className='text-lg md:text-xl font-medium font-plus text-left'>How do eco-friendly beginners reduce their carbon footprint when sustainability practices often rely on an individual's social environment or aren't manageable long-term?</p>
            </div>
        </div> 
      </div>
      </div>

    </>
  )
}
