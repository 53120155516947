import React from 'react'

export default function JourneySection() {
  return (
    <>
    {/* More on my journey */}
    <div className='grid grid-cols-12 py-10 md:py-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 pb-10 md:pb-b0 space-y-4 md:space-y-6'>
        {/* Title */}
          <h1 className='text-[44px] md:text-[52px] tracking-tighter leading-snug bg-gradient-to-r from-yellow-500 to-purple-600 h-fit text-transparent bg-clip-text font-monda font-bold w-full'>More on my journey</h1>
        {/* Video 1 */}
          <div className='overflow-hidden grid place-items-center row-span-1 w-full h-96 rounded-xl text-white'>
            
            <div className='w-full h-full flex justify-center'>
                            
                <iframe className='w-[80%] h-full md:w-[70%] rounded-xl drop-shadow-xl' src="https://www.youtube.com/embed/fk0J-hkVats" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                              
            </div>

          </div>
            <p className='tracking-tight w-full text-md text-zinc-500 font-medium pb-4'>My video resume!</p>
            {/* Video 2 */}
          <div className='overflow-hidden grid place-items-center row-span-1 w-full h-96 rounded-xl text-white'>
            
            <div className='w-full h-full flex justify-center'>
                            
                       <iframe className='w-[80%] h-full md:w-[70%] rounded-xl drop-shadow-xl' src="https://www.youtube.com/embed/Mwr-xkHCF2I?start=99" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                          
            </div>

          </div>
            <p className='pb-4 tracking-tight w-full text-md text-zinc-500 font-medium'><span className='font-bold underline'>Skip to 1:11</span> to hear about the beginning of my UX Design Journey at Torrens University Australia!</p>

        </div>
      </div>
    </>
  )
}
