import React from 'react'

export default function OpenMenuButton() {
  return (
    <>
    <div className='w-10 h-10 grid place-items-center'>
      <svg class="h-8 w-8 text-zinc-800"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z"/> <line x1="4" y1="6" x2="20" y2="6" /> <line x1="4" y1="12" x2="20" y2="12" />  <line x1="4" y1="18" x2="20" y2="18" /></svg>
    </div>
    </>
  )
}
