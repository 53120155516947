import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';


export default function PlancoReflection() {
  return (
    <>
    <div className='grid grid-cols-12 py-10 md:py-20 w-screen h-auto bg-gradient-to-r from-teal-800  to-green-800'>
    <motion.div initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }} className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
    <div className='w-full h-auto space-y-6'>
            <div className='text-zinc-300 text-left text-4xl md:text-[52px] w-full h-auto font-monda tracking-tighter font-bold'>Reflection</div>
            <div className='text-zinc-200 text-left w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>Takeaways</div>
            {/* Unresponsive */}
            <div className='hidden md:grid grid-cols-2 gap-12 text-white'>
                <div className="col-span-1 space-y-4">
                    <div className="underline text-left text-[30px] font-monda font-bold tracking-tighter">Larger toolbox</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>I developed a new set of research methods during this course including risky assumptions for issue prioritisation, and rapid prototyping for efficient user feedback collection during interviews. These skills will prove to be invaluable, especially within fast-paced industry settings. This increased my confidence in user research and made it more enjoyable to do.</p>
                </div>
                <div className="col-span-1 space-y-4">
                    <div className="underline text-left text-[30px] font-monda font-bold tracking-tighter">Mindset growth</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>This project also allowed me to think outside of the box, particulalary about how the service can work beyond the confines of a physical application and beyond. Along with also developing branding skills, I learned to understand the dynamics of social enterprises and fostered a growth mindset crucial for nonprofit work aspirations.</p>
                </div>
            </div>
            {/* Responsive */}
            <div className='md:hidden grid grid-rows-2 gap-12 text-white'>
                <div className="row-span-1 space-y-4">
                    <div className="underline text-left text-[24px] font-monda font-bold tracking-tighter">Larger toolbox</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>I developed a new set of research methods during this course including risky assumptions for issue prioritisation, and rapid prototyping for efficient user feedback collection during interviews. These skills will prove to be invaluable, especially within fast-paced industry settings. This increased my confidence in user research and made it more enjoyable to do.</p>
                </div>
                <div className="row-span-1 space-y-4">
                    <div className="underline text-left text-[24px] font-monda font-bold tracking-tighter">Mindset growth</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>This project also allowed me to think outside of the box, particulalary about how the service can work beyond the confines of a physical application and beyond. Along with also developing branding skills, I learned to understand the dynamics of social enterprises and fostered a growth mindset crucial for nonprofit work aspirations.</p>
                </div>
            </div>
        </div>
      </motion.div>
      </div>
    </>
  )
}
