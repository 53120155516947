import React from 'react';
import PlanPort from './portfolio-piece-images/plan-portfolio.png';
import MilaNote from './portfolio-piece-images/milanote-portfolio.png';
import CarouselOneContentPort from './CarouselOneContentPort';


const slides =[
   
    PlanPort, MilaNote,

]

export default function CarouselTwoContentPort() {
  return (
    <>
    <div className='w-full h-auto grid'>

    <div className="w-auto h-auto pb-4">
        <CarouselOneContentPort>
            {slides.map((s)=>(
                <img src={s}/>
            ))}
        </CarouselOneContentPort>
    </div>

    </div>
      
    </>
  )
}
