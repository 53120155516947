import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';


export default function ChromIntro() {
  return (
    <>
    <div className='w-full h-auto grid grid-cols-12 place-items-center bg-gradient-to-r from-cyan-800  to-rose-800'>
      <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 grid place-items-center'>
      <motion.div initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }} className='w-full h-auto grid grid-place-items-center bg-white/20 p-10 md:p-20 rounded-md my-8 md:my-10'>
              <h1 className='text-[44px] sm:text-[60px] text-white font-monda font-bold tracking-tighter w-full'>Chromacity</h1>
                <p className='text-[24px] font-monda text-white font-bold tracking-tighter pb-5 md:pb-6'>All-in-one colour psychology platform</p>
                <div className='w-full space-y-2 pb-4'>
                  <p className='text-white text-md md:text-lg font-medium'>Chromacity is a web-based service providing comprehensive classes on accessibility, colour psychology, and other common design principles. For creatives it's an all-in-one place to learn, apply and share knowledge in a compelling way.</p>
              </div>
          </motion.div>
      </div>
    </div>
    </>
  )
}
