// import FinalSolution from './chromacity-images/MockUpLanding.png';
import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';
import PortfolioMockup from './portfolio-piece-images/port-mockup.png'

export default function PortfolioSolution() {
  return (
    <>
    {/* Final Design */}
    <div className='grid grid-cols-12 w-screen h-auto py-10 md:py-20'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
        <div className='w-full h-auto space-y-4 md:space-y-8'>
            <motion.h3 initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }}h3 className='text-left text-4xl md:text-[52px] font-bold text-cyan-950 font-monda tracking-tighter'>Introducing, me!</motion.h3>
            <ul className='space-y-2 list-disc pl-6'>
                <li className='w-full h-auto font-plus font-medium text-lg text-left'>Created a <span className='font-bold text-purple-700'>platform clearly defining who I am.</span></li>
                <li className='w-full h-auto font-plus font-medium text-lg text-left'>Built using <span className='font-bold text-purple-700'>all my accessibility, front-end engineering, and product design skillsets.</span></li>
                <li className='w-full h-auto font-plus font-medium text-lg text-left'>Provides all my projects within a <span className='font-bold text-purple-700'>vibrant and interactive space.</span></li>
            </ul>
            <div className='w-full h-auto grid place-items-center'>
              <img src={PortfolioMockup}/>
            </div>
            <motion.div
            initial={{scale:0}}
            whileInView={{transition: { duration: 0.5 }, scale:1 }}
             className='w-full md:py-0 py-10 h-auto md:h-40 bg-gradient-to-r to-yellow-200 from-purple-200 rounded-md overflow-hidden text-zinc-800 flex justify-center items-center font-bold font-monda tracking-tighter leading-tight md:text-3xl text-2xl'>
              How awesome is this? You're actually using what I created right now!
            </motion.div>
        </div>
        </div>
        </div>
    </>
  )
}
