import React from 'react';
import IntroBanner from './intro-images/intro-banner.jpg';

export default function IntroIntroduction() {
  return (
    <>
    <div className='w-screen h-auto grid grid-cols-12'>
        <section className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 py-10 md:py-20 space-y-4 md:space-y-6 grid place-items-center'>
        <div className='w-[80%] md:w-[50%] h-auto pb-5 rounded-md shadow-lg overflow-hidden grid place-items-center'>
            <img src={IntroBanner}/>
        </div>
        <h1 className='text-4xl md:text-[52px] bg-gradient-to-r from-yellow-500 to-purple-600 h-fit text-transparent bg-clip-text font-monda font-bold tracking-tighter w-full leading-snug'>INTRO 2022 - Graduate Exhibition</h1>     
        <p  className='pb-2 md:pb-4 font-plus font-medium md:text-lg text-md'>INTRO is the vibrant graduate exhibition of Billy Blue College of Design. This platform's intention is to spotlight emerging and recently graduated students. This platform celebrates the innovative talents, aiming to uncover fresh and rising stars. Despite graduating in 2023, I had the honour of showcasing my portfolio in the 2022 exhibition.</p>  

        </section>
    </div>
    </>
  )
}
