import React from 'react'

export default function DesignProcessPlanco() {
  return (
    <>
               <div className='grid grid-cols-12 w-screen h-auto'>
        <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
              {/* Design process */}
        <div className='md:py-20 py-10 text-left space-y-10 grid place-items-center'>
            {/* Project Summary - Unresponsive + Responsive */}
        <div className='w-auto h-auto space-y-10'>
        <h1 className='text-4xl md:text-[52px] text-zinc-800 font-monda font-bold tracking-tighter w-full text-left'>Project summary</h1>            
        <div className='gap-24 hidden md:grid grid-cols-2'>
            <div className='col-span-1 w-full h-fit grid grid-rows-2 gap-8'>
                <div className='row-span-1 text-left font-plus space-y-3'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter'>Project type</h3>
                    <p className='text-md md:text-lg font-plus font-medium'>Planco is an independent project I developed and completed on my own within a 6-month time frame. I utilised Canva, Figma, Marvel and Maze.</p>
                </div>
                <div className='row-span-1 text-left font-plus space-y-3'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter'>Problem statement</h3>
                    <p className='text-md md:text-lg font-plus font-medium'>How do eco-friendly beginners reduce their carbon footprint when sustainability practices often rely on an individual's social environment or aren't manageable long-term?</p>
                </div>
            </div>
            
            <div className='col-span-1 w-full h-auto grid grid-rows-2 gap-8'>

                <div className='space-y-3 row-span-1 text-left font-plus'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter'>Focuses</h3>
                    <ul className='list-disc pl-6 text-md md:text-lg font-plus font-medium'>
                        <li>Brand Design</li>
                        <li>Product Design</li>
                        <li>UX Design</li>
                        <li>UX Research</li>
                    </ul>
                </div>
                <div className='space-y-3 row-span-1 text-left font-plus'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter'>Solution</h3>
                    <p className='text-md md:text-lg font-plus font-medium'>To develop a platform that empowers users with education and tools to help become eco-friendly and sustainable.</p>
                </div>
            </div>
        </div>
        
        {/* Responsive */}
        <div className='grid md:hidden grid-row-2 place-items-center'>
            <div className='row-span-1 w-full h-auto grid grid-rows-2 gap-8'>
                <div className='row-span-1 text-left font-plus space-y-2'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter'>Project type</h3>
                    <p className='text-md font-medium'>Planco is an independent project I developed and completed on my own within a 6-month time frame. I utilised Canva, Figma, Marvel and Maze.</p>
                </div>
                <div className='row-span-1 text-left font-plus space-y-2'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter'>Problem statement</h3>
                    <p className='text-md font-medium'>How do eco-friendly beginners reduce their carbon footprint when sustainability practices often rely on an individual's social environment or aren't manageable long-term?</p>
                </div>
            </div>
            <div className='row-span-1 w-full h-auto grid grid-rows-2 gap-8 pt-8'>
            <div className='row-span-1 text-left font-plus space-y-2'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter'>Focuses</h3>
                    <ul className='list-disc pl-6 space-y-2 text-md font-plus font-medium'>
                        <li>Brand Design</li>
                        <li>Product Design</li>
                        <li>UX Design</li>
                        <li>UX Research</li>
                    </ul>
            </div>
                <div className='row-span-1 text-left font-plus space-y-2'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter'>Solution</h3>
                    <p className='text-md font-medium'>To develop a platform that empowers users with education and tools to help become eco-friendly and sustainable.</p>
                </div>
            </div>
        </div>
        </div>

            {/* Design Process - Unresponsive + Responsive */}
            
        <div className='pt-2 w-full h-full space-y-2 md:space-y-6'>
        <h3 className='w-full text-left text-[32px] md:text-[40px] font-bold text-teal-800 font-monda tracking-tighter'>Design process</h3>
        <div className='w-full h-auto grid place-items-center space-y-2 md:space-y-10'>
            <div className='md:gap-10 hidden md:grid grid-cols-2 w-full'>
            <div className='col-span-1 w-full h-auto grid grid-rows-2 gap-8'>
                <div className='row-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-zinc-100'>
                    <h3 className='text-teal-800 text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-leftt'>1 - Discover</h3>
                    <ul className='text-md md:text-lg font-plus font-medium list-disc pl-6 space-y-1'>
                        <li className=''>Problem statement</li>
                        <li className=''>Research</li>
                    </ul>
                </div>
                <div className='row-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-zinc-100'>
                    <h3 className='text-teal-800 text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left'>3 - Develop</h3>
                    <ul className='text-md md:text-lg font-plus font-medium list-disc pl-6 space-y-1'>
                        <li className=''>Brainstorming</li>
                    </ul>
                </div>
            </div>
            <div className='col-span-1 w-full h-auto grid grid-rows-2 gap-8'>
                <div className='row-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-zinc-100'>
                    <h3 className='text-teal-800 text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left'>2 - Define</h3>
                    <ul className='pl-6 list-disc text-md md:text-lg font-plus font-medium space-y-1'>
                        <li className=''>Consolidating insights</li>
                        <li className=''>User personas</li>
                    </ul>
                </div>
                <div className='row-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-zinc-100'>
                    <h3 className='text-teal-800 text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left'>4 - Deliver</h3>
                    <ul className='list-disc pl-6 text-md md:text-lg font-plus font-medium space-y-1'>
                    <li className=''>Hi-fidelity prototype</li>
                        <li className=''>Prototyping</li>
                    </ul>
                </div>
            </div>
        </div>
        {/* Responsive */}
        <div className=' gap-8 grid md:hidden grid-row-2 place-items-center items w-full'>
            <div className='row-span-1 w-full h-auto gap-8 grid grid-rows-2'>
                <div className='row-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-zinc-100'>
                    <h3 className='text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left text-teal-800'>1 - Discover</h3>
                    <ul className='list-disc pl-6 text-md font-plus font-medium space-y-1'>
                        <li className=''>Problem statement</li>
                        <li className=''>Research</li>
                    </ul>
                </div>
                <div className='row-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-zinc-100'>
                    <h3 className='text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left text-teal-800'>2 - Define</h3>
                    <ul className='list-disc pl-6 text-md font-plus font-medium space-y-1'>
                        <li className=''>Consolidating insights</li>
                        <li className=''>User personas</li>
                    </ul>
                </div>
            </div>
            <div className='row-span-1 w-full h-auto gap-8 grid grid-rows-2'>
                <div className='row-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-zinc-100'>
                    <h3 className='text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left text-teal-800'>3 - Develop</h3>
                    <ul className='list-disc pl-6 text-md font-plus font-medium space-y-1'>
                        <li className=''>Brainstorming</li>
                    </ul>
                </div>
                <div className='row-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-zinc-100'>
                    <h3 className='text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left text-teal-800'>4 - Deliver</h3>
                    <ul className='list-disc pl-6 text-md font-plus font-medium space-y-1'>
                        <li className=''>Hi-fidelity prototype</li>
                        <li className=''>Prototyping</li>
                    </ul>
                </div>
            </div>
        </div>
        {/* <div className='py-6'>
            <button className='font-monda tracking-tight align-center w-fit px-4 py-2 rounded-lg h-fit text-lg bg-cyan-600 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>Skip to solution</button>
        </div> */}
        </div>
        </div>
        </div>
    </div>
    </div>
    </>
  )
}
