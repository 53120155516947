import React from 'react'
import XlabsIntro from './xlabs-page-elements/XlabsIntro'
import XlabsReflection from './xlabs-page-elements/XlabsReflection'
import XlabsSummary from './xlabs-page-elements/XlabsSummary'
import XlabsLink from './xlabs-page-elements/XlabsLink'
import XlabsProcess from './xlabs-page-elements/XlabsProcess'
import XlabsMockUps from './xlabs-page-elements/XlabsMockups'

export default function XlabsPage() {
  return (
    <>
      <XlabsIntro/>
      <XlabsProcess/>
      <XlabsMockUps/>
      <XlabsReflection/>
      <XlabsSummary/>
      <XlabsLink/>
    </>
  )
}

