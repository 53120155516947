import React from 'react';
import { useState } from 'react';

export default function ResearchCarouselContent({children: slides}) {

    const [curr, setCurr] = useState(0);

    const prev = () => setCurr (curr => curr === 0 ? slides.length-1: curr - 1);
    const next = () => setCurr (curr => curr === slides.length-1 ? 0: curr + 1);


  return (
    <>
      <div className="rounded-md h-60 md:h-96 overflow-hidden relative">
        <div className="flex transition-transform ease-out duration-500" style={{transform: `translateX(-${curr*100}%)`}}>{slides}</div>
        <div className='absolute inset-0 flex items-center justify-between p-4'>
            <button onClick={prev} className='p-1'>
            <svg class="h-10 w-10 text-zinc-500/60 hover:text-zinc-500/100"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polyline points="15 18 9 12 15 6" /></svg>       </button>
            <button onClick={next} className='p-1'>
            <svg class="h-10 w-10 text-zinc-500/60 hover:text-zinc-500/100"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="9 6 15 12 9 18" /></svg>        </button>
        </div>
        <div className='absolute bottom-4 right-0 left-0'>
            <div className="flex items-center justify-center gap-2">
                {slides.map((_, i)=>(
                    <div className={`
                    transition-all w-3 h-3 bg-zinc-400 rounded-full
                    ${curr === i ? "p-2":"bg-opacity-50"}`}></div>
                ))}
            </div>
        </div>
      </div>
    </>
  )
}
