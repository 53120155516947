import React from 'react';
import FigmaProto from './chromacity-images/ProtoThumbnailChom.png';
import ChromSlideDeck from './chromacity-images/ChromCanvaTitle.png';

export default function ChromEnding() {
  return (
    <>
              {/* Endings Unresponsive*/}
        <div className='hidden md:grid grid-cols-12 w-screen h-auto'>
          <div className='col-start-3 col-end-11'>
              <div className='w-full h-auto grid grid-cols-2 gap-12 py-6 md:py-10'>
            <div className="col-span-1 space-y-4 grid place-items-center">
            <div className="text-[30px] font-monda font-bold tracking-tighter">Figma prototype</div>
                <div className='w-3/4 md:h-48 rounded-md overflow-hidden drop-shadow-sm'>
                  <img src={FigmaProto} className='scale-[1.6]'/>
                </div>
                <a href="https://www.figma.com/file/p8fQHjkbPBKQocEKJv2T7i/Chroma.city?type=design&node-id=433-5196&mode=design"><button className='font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-cyan-600 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>Figma file</button></a>
            </div>
            <div className="col-span-1 space-y-4 grid place-items-center">
                <div className="text-[30px] font-monda font-bold tracking-tighter">Full process</div>
                <div className='w-3/4 md:h-48 rounded-md overflow-hidden drop-shadow-sm grid place-items-center'>
                  <img src={ChromSlideDeck} className='scale-[1.2]'/>
                </div>
                <a href="https://www.canva.com/design/DAE7NwzPMxc/X6CjMdn9GSaCCGgKghV5-g/view?utm_content=DAE7NwzPMxc&utm_campaign=designshare&utm_medium=link&utm_source=publishpresent"><button className='font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-cyan-600 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>Process Deck</button></a>
            </div>
        </div>
        </div>
        </div>
        {/* Responsive */}
        <div className='md:hidden grid grid-cols-12 w-screen h-auto'>
          <div className='col-start-3 col-end-11'>
              <div className='w-full h-auto grid grid-rows-2 gap-12 py-6 md:py-10'>
            <div className="row-span-1 space-y-4 grid place-items-center">
                <div className='w-full h-auto text-[24px] font-monda font-bold tracking-tighter'>Figma prototype</div>
                <div className='w-72 h-auto rounded-md'>
                  <img src={FigmaProto}/>
                </div>
                <a href="https://www.figma.com/file/p8fQHjkbPBKQocEKJv2T7i/Chroma.city?type=design&node-id=433-5196&mode=design"><button className='font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-cyan-600 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>Figma file</button></a>
            </div>
            <div className="row-span-1 space-y-4 grid place-items-center">
                <div className='w-full h-auto text-[24px] font-monda font-bold tracking-tighter'>Full process</div>
                <div className='w-72 h-auto rounded-md overflow-hidden'>
                  <img src={ChromSlideDeck}/>
                </div>
                <a href="https://www.canva.com/design/DAE7NwzPMxc/X6CjMdn9GSaCCGgKghV5-g/view?utm_content=DAE7NwzPMxc&utm_campaign=designshare&utm_medium=link&utm_source=publishpresent"><button className='font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-cyan-600 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>Process Deck</button></a>
            </div>
        </div>
        </div>
        </div>
    </>
  )
}
