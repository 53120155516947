import React from 'react';
import BlinkistImg from './chromacity-images/blinkist.png';
import CoveImg from './chromacity-images/cove-two.png';
import HeadspaceImg from './chromacity-images/headspace.png';
import PigmentsImg from './chromacity-images/pigments.png'





export default function SecondaryResearchFindingsChrom() {
  return (
    <>

    {/* Competitor Analysis and everything findings */}
    {/* Large */}
            <div className='hidden lg:grid w-full h-auto md:space-y-6 space-y-4 pt-2 md:pt-10'>
                <h3 className='text-cyan-800 text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full leading-tight'>Competitor analysis, feature audit and heuristic analysis summary</h3>
                <p className='w-full font-bold text-md md:text-lg font-plus tracking-tight'>In summary each service provides simplistic, engaging, educational experiences.</p>
                {/* Regular */}
                <div className='w-full grid grid-cols-4 gap-20 pt-10'>
                    <div className='col-span-1 h-auto space-y-2'>
                        <img src={BlinkistImg} className=''/>
                        <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Blinkist</p>
                        <p className='text-md md:text-lg font-medium'>Over all great application. Very limited without premium.</p>
                    </div>

                    <div className='col-span-1 h-auto space-y-2'>
                        <img src={CoveImg} className=''/>
                        <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Cove</p>
                        <p className='text-md md:text-lg font-medium'>Highly intuitive, minimal accessibility.</p>
                    </div>

                    <div className='col-span-1 h-auto space-y-2'>
                        <img src={HeadspaceImg} className=''/>
                        <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Headspace</p>
                        <p className='text-md md:text-lg font-medium'>Smooth and friendly UI Design. Limited accessibility.</p>
                    </div>

                    <div className='col-span-1 h-auto space-y-2'>
                        <img src={PigmentsImg} className=''/>
                        <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Pigments</p>
                        <p className='text-md md:text-lg font-medium'>Option to simulate multiple colour schemes under WGAC guidelines.</p>
                    </div>
                </div>
            </div>


    {/* MEDIUM */}
   
        <div className='hidden md:grid lg:hidden w-full h-auto md:space-y-6 space-y-4 pt-2 md:pt-10'>
            <h3 className='text-cyan-800 text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full leading-tight'>Competitor analysis, feature audit and heuristic analysis summary</h3>
            <p className='w-full font-bold text-md md:text-lg font-plus tracking-tight'>In summary each service provides simplistic, engaging, educational experiences.</p>
            <div className='w-full h-auto grid grid-cols-2 gap-4 pt-8 pb-4 place-items-center'>
                <div className='col-span-1 h-96'>
                    <img src={BlinkistImg} className='h-full'/>
                </div>
                <div className='col-span-1 h-auto space-y-2'>
                    {/* Elements of features */}
                    <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Blinkist</p>
                    <p className='text-md md:text-lg font-medium'>Over all great application. Very limited without premium.</p>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-2 gap-4 pt-4 place-items-center'>
                <div className='col-span-1 h-auto space-y-2'>                    
                    <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Cove</p>
                    <p className='text-md md:text-lg font-medium'>Highly intuitive, minimal accessibility.</p>              
                </div>
                <div className='col-span-1 h-96'>
                    <img src={CoveImg} className='h-full'/>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-2 gap-4 place-items-center'>
                <div className='col-span-1 h-96 '>
                    <img src={HeadspaceImg} className='h-full'/>
                </div>
                <div className='col-span-1 h-auto space-y-2'>
                    <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Headspace</p>
                    <p className='text-md md:text-lg font-medium'>Smooth and friendly UI Design. Limited accessibility.</p>
                </div>
            </div>
            <div className='w-full h-auto grid grid-cols-2 gap-4 place-items-center'>
                <div className='col-span-1 h-auto space-y-2 grid'>
                    <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Pigments</p>
                    <p className='text-md md:text-lg font-medium'>Option to simulate multiple colour schemes under WGAC guidelines.</p>
                </div>
                <div className='col-span-1 h-96'>
                    <img src={PigmentsImg} className='h-full'/>
                </div>
            </div>
        </div>
 

            {/* SMALL */}
    
        <div className='w-full h-auto space-y-8 pt-2 md:pt-10 grid md:hidden'>
            <h3 className='text-cyan-800 text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full leading-tight'>Competitor analysis, feature audit and heuristic analysis summary</h3>
            <p className='w-full font-bold text-md md:text-lg font-plus tracking-tight'>In summary each service provides simplistic, engaging, educational experiences.</p>
            <div className='w-full h-auto space-y-6 grid place-items-center'>
                <div className='h-auto rounded-md w-40'><img src={BlinkistImg}/></div>
                <div className='h-auto space-y-2'>
                    <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Blinkist</p>
                    <p className='space-y-2text-md md:text-lg font-medium'>Over all great application. Very limited without premium.</p>
                </div>
            </div>
            <div className='w-full h-auto space-y-6 grid place-items-center '>
                <div className='w-40 grid place-items-center'>
                <img src={CoveImg}/>
                </div>
                <div className='h-auto space-y-2'>                    
                    <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Cove</p>
                    <p className='space-y-2 text-md md:text-lg font-medium'>Highly intuitive, minimal accessibility.</p>
                </div>
            </div>
            <div className='w-full h-auto space-y-6 grid place-items-center'>
                <div className='w-40 grid place-items-center'>
                    <img src={HeadspaceImg}/>
                </div>
                <div className='h-auto space-y-2'>
                <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Headspace</p>
                    <p className='space-y-2 text-md md:text-lg font-medium'>Smooth and friendly UI Design. Limited accessibility.</p>
                </div>
            </div>
            <div className='w-full h-auto space-y-6 grid place-items-center'>
                <div className='w-40 grid place-items-center'>
                    <img src={PigmentsImg}/>
                </div>
                <div className='h-auto space-y-2'>
                <p className='text-cyan-950 w-full md:text-xl text-lg font-bold'>Pigments</p>
                    <p className='space-y-2 text-md md:text-lg font-medium'>Option to simulate multiple colour schemes under WGAC guidelines.</p>
                </div>
            </div>


            
        </div>

        {/* ---Other Interesting Finds--- */}
        <div className='w-full h-auto md:space-y-6 space-y-4'>
        <h3 className='text-cyan-800 text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full leading-tight'>Other interesting finds</h3>
            {/* Regular */}
            <div className='p-8 w-full h-auto hidden md:grid grid-cols-2 gap-12 bg-cyan-700 rounded-md font-medium'>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>Color psychology ties to how cultures personify colors based on the electromagnetic spectrum. For instance, black symbolizes misfortune in the West but masculinity in parts of Africa.</div>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>Various creative fields - art, design, fashion, and psychology - apply color psychology differently. For example, psychologists use it in sensory exercises noticing how colours like red have a strong visceral effect on humans.</div>
            </div>
            {/* Responsive */}
            <div className='p-8 w-full h-auto grid md:hidden grid-rows-2 gap-8 bg-cyan-700 rounded-md text-md font-medium'>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>Color psychology ties to how cultures personify colors based on the electromagnetic spectrum. For instance, black symbolizes misfortune in the West but masculinity in parts of Africa.</div>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>Various creative fields - art, design, fashion, and psychology - apply color psychology differently. For example, psychologists use it in sensory exercises noticing how colours like red have a strong visceral effect on humans.</div>
            </div>
        </div>

      
    </>
  )
}
