import React from 'react';
import TwoWire from './portfolio-piece-images/wireframe-portfolio.png';
import DesSystem from './portfolio-piece-images/dessystem-portfolio.png';
import MedFi from './portfolio-piece-images/medfi-portfolio.png';
import CodePort from './portfolio-piece-images/code-portfolio.png';
import CarouselTwoContentPort from './CarouselTwoContentPort';


const slides =[
   
    TwoWire, DesSystem, MedFi, CodePort,

]

export default function CarouselTwoWrapperPort() {
  return (
    <>
    <div className='w-full h-auto grid'>

    <div className="w-auto h-auto pb-4">
        <CarouselTwoContentPort>
            {slides.map((s)=>(
                <img src={s}/>
            ))}
        </CarouselTwoContentPort>
    </div>

    </div>
      
    </>
  )
}
