import React from 'react'

export default function PortfolioDesignProcess() {
  return (
    <>
        <div className='grid grid-cols-12 w-screen h-auto'>
        <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
              {/* Design process */}
        <div className='md:py-20 py-10 text-left space-y-10 grid place-items-center'>
            {/* Project Summary - Unresponsive + Responsive */}
        <div className='w-auto h-auto space-y-10'>
        <h1 className='text-4xl md:text-[52px] text-zinc-800 font-monda font-bold tracking-tighter w-full text-left'>Project summary</h1>            
        <div className='gap-24 hidden md:grid grid-cols-2'>
            <div className='col-span-1 w-full h-fit grid grid-rows-2 gap-8'>
                <div className='row-span-1 text-left font-plus space-y-3'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-purple-800 font-monda tracking-tighter'>Project type</h3>
                    <p className='text-md md:text-lg font-plus font-medium'>This portfolio redesign is an independent project I completed within a 4-month time frame from January to April of 2024. I used Figma, Framer Motion, React.js, Tailwind CSS and Visual Studio Code.</p>
                </div>
                <div className='row-span-1 text-left font-plus space-y-3'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-purple-800 font-monda tracking-tighter'>Problem statement</h3>
                    <p className='text-md md:text-lg font-plus font-medium'>How do I develop a portfolio which can combine all parts of my professional passions into a fun, interactive platform?</p>
                </div>
            </div>
            
            <div className='col-span-1 w-full h-auto grid grid-rows-2 gap-8'>

                <div className='space-y-3 row-span-1 text-left font-plus'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-purple-800 font-monda tracking-tighter'>Focuses</h3>
                    <ul className='list-disc pl-6 text-md md:text-lg font-plus font-medium'>
                        <li>Copywriting</li>
                        <li>Front-end Engineering</li>
                        <li>Product Design</li>
                        <li>UI Design</li>
                    </ul>
                </div>
                <div className='space-y-3 row-span-1 text-left font-plus'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-purple-800 font-monda tracking-tighter'>Solution</h3>
                    <p className='text-md md:text-lg font-plus font-medium'>To create an engaging portfolio from scratch combining my product design and front-end engineering skills.</p>
                </div>
            </div>
        </div>
        
        {/* Responsive */}
        <div className='grid md:hidden grid-row-2 place-items-center'>
            <div className='row-span-1 w-full h-auto grid grid-rows-2 gap-8'>
                <div className='row-span-1 text-left font-plus space-y-2'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-purple-800 font-monda tracking-tighter'>Project type</h3>
                    <p className='text-md font-medium'>This portfolio redesign is an independent project I completed within a 4-month time frame from January to April of 2024. I used Figma, Framer Motion, React.js, Tailwind CSS and Visual Studio Code.</p>
                </div>
                <div className='row-span-1 text-left font-plus space-y-2'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-purple-800 font-monda tracking-tighter'>Problem statement</h3>
                    <p className='text-md font-medium'>How do I develop a portfolio which can combine all parts of my professional passions into a fun, interactive platform?</p>
                </div>
            </div>
            <div className='row-span-1 w-full h-auto grid grid-rows-2 gap-8'>
            <div className='row-span-1 text-left font-plus space-y-2'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-purple-800 font-monda tracking-tighter'>Focuses</h3>
                    <ul className='list-disc pl-6 space-y-2 text-md font-plus font-medium'>
                        <li>Copywriting</li>
                        <li>Front-end Engineering</li>
                        <li>Product Design</li>
                        <li>UI Design</li>
                    </ul>
            </div>
                <div className='row-span-1 text-left font-plus space-y-2'>
                    <h3 className='text-left text-[32px] md:text-[40px] font-bold text-purple-800 font-monda tracking-tighter'>Solution</h3>
                    <p className='text-md font-medium'>To create an engaging portfolio from scratch combining my product design and front-end engineering skills.</p>
                </div>
            </div>
        </div>
        </div>

            {/* Design Process - Unresponsive + Responsive */}
            
        </div>
    </div>
    </div>
    </>
  )
}
