import React from 'react'

export default function ResoProcess() {
  return (
    <>
              <div className='grid grid-cols-12 w-screen h-auto'>
        <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
              {/* Design process */}
              <div className='md:pt-20 pt-10 text-left space-y-10 grid place-items-center'>
            {/* Project Summary - Unresponsive + Responsive */}
        <div className='w-auto h-auto'>
        <div className='gap-24 hidden md:grid grid-cols-2'>
            <div className='col-span-1 w-full h-fit grid gap-8'>
                <div className='row-span-1 text-left font-plus space-y-3'>
                    <h3 className='text-[24px] md:text-[30px] font-bold text-orange-800 font-monda tracking-tighter'>Project type</h3>
                    <p className='text-md md:text-lg font-plus font-medium'>I interned on the Digital Innovation Team and Marketing Team for 3-months, working on multiple in-house projects. Our team utilised Figma, Miro and Microsoft Teams.</p>
                </div>
            </div>
            
            <div className='col-span-1 w-full h-auto grid gap-8'>

                <div className='space-y-3 row-span-1 text-left font-plus'>
                    <h3 className='text-[24px] md:text-[30px] font-bold text-orange-800 font-monda tracking-tighter'>Focuses</h3>
                    <ul className='list-disc pl-6 text-md md:text-lg font-plus font-medium'>
                        <li>Collaboration</li>
                        <li>Discovery research</li>
                        <li>UX design</li>
                        <li>User research</li>
                    </ul>
                </div>
            </div>
        </div>
        
        {/* Responsive */}
        <div className='grid md:hidden grid-row-2 place-items-center'>
            <div className='row-span-1 w-full h-auto grid gap-8'>
                <div className='row-span-1 text-left font-plus space-y-2'>
                    <h3 className='text-[24px] md:text-[30px] font-bold font-monda tracking-tighter text-orange-800'>Project type</h3>
                    <p className='text-md font-medium'>I interned on the Digital Innovation Team and Marketing Team for 3-months, working on multiple in-house projects. Our team utilised Figma, Miro and Microsoft Teams.</p>
                </div>
            </div>
            <div className='row-span-1 w-full h-auto grid gap-8 pt-8'>
            <div className='row-span-1 text-left font-plus space-y-2'>
                    <h3 className='text-[24px] md:text-[30px] font-bold font-monda tracking-tighter text-orange-800'>Focuses</h3>
                    <ul className='list-disc pl-6 space-y-2 text-md font-plus font-medium'>
                        <li>Collaboration</li>
                        <li>Discovery research</li>
                        <li>UX design</li>
                        <li>User research</li>
                    </ul>
            </div>
            </div>
        </div>
        </div>
        </div>
    </div>
    </div>
    </>
  )
}
