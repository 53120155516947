import React from 'react';
import MockUpOneImageXlabs from '../xlabs-page-elements/xlabs-images/xlabs-mockup-1.png';
import MockUpTwoImageXlabs from '../xlabs-page-elements/xlabs-images/xlabs-mockup-2.png';


export default function XlabsMockUps() {
  return (
    <>
        {/* Mock Ups */}
        <div className='hidden md:grid grid-cols-12 w-screen h-auto'>
        <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
            <div className='pt-24'>
                <div className=' gap-8 hidden md:flex justify-between items-center'>
                    <div className='lg:w-96 lg:h-96 md:h-64 md:w-64 overflow-hidden rounded-md'>
                        <img src={MockUpOneImageXlabs} alt="mockup-one" className=' block w-fit scale-[1.7]' />
                    </div>
                    <div className='lg:w-96 lg:h-96 md:h-64 md:w-64 overflow-hidden rounded-md'>
                        <img src={MockUpTwoImageXlabs} alt="mockup-one" className=' block w-fit md:scale-[1.7]' />
                    </div>
                </div>
                <div className='gap-10 md:hidden flex justify-between items-center'>
                    <div className='row-span-1 bg-green-100 w-full aspect-square rounded-md overflow-hidden'>
                        <img src={MockUpOneImageXlabs} alt="mockup-one" className=' block w-fit scale-[1.7]' />
                    </div>
                    <div className='row-span-1 bg-green-100 w-full aspect-square rounded-md overflow-hidden'>
                        <img src={MockUpTwoImageXlabs} alt="mockup-one" className=' block w-fit scale-[1.7]' />
                    </div>
                </div>
            </div>
        </div>
        </div>

        {/* Responsive */}
        <div className='md:hidden grid w-full h-auto grid-cols-12 pt-10'>
            <div className='grid grid-rows-2 gap-8 col-start-2 col-end-12 place-items-center'>
                <div className='row-span-1 bg-green-100 w-3/4 aspect-square rounded-md overflow-hidden'>
                    <img src={MockUpOneImageXlabs} alt="mockup-one" className=' block w-fit scale-[1.6]' />
                </div>
                <div className='row-span-1 bg-green-100 w-3/4 aspect-square rounded-md overflow-hidden'>
                    <img src={MockUpTwoImageXlabs} alt="mockup-one" className=' block w-fit scale-[1.6]' />
                </div>
            </div>
        </div>

    </>
  )
}
