import {useRef,useEffect} from 'react';

const useCanvas = draw => {
    const ref = useRef();

    // const draw = (context, count) => {
    //     context.clearRect(0,0,context.canvas.width,context.canvas.height);
    //     context.fillStyle ='blue';
    //     const delta = count % 800;
    //     context.fillRect(10 + delta ,10,100,100);
    // };

    useEffect(()=>{
        const canvas = ref.current;
        const context = canvas.getContext('2d');
        let count = 0;
        let animationID
        const renderer = () => {
            count ++
            draw(context,count)
            animationID=window.requestAnimationFrame(renderer)
        };
        renderer();

        return() => window.cancelAnimationFrame(animationID)
    },[draw])


    return ref
};

export default useCanvas;