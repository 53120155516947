import React from 'react'

export default function XlabsReflection() {
  return (
    <>
          <div className='grid grid-cols-12 py-10 md:py-20 w-screen h-auto bg-white'>
    <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
    <div className='w-full h-auto space-y-6'>
            <div className='text-left text-4xl md:text-[52px] w-full h-auto font-monda tracking-tighter font-bold text-zinc-800'>Reflection</div>
            
            <div className='text-left text-lime-700 w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>Gained the following skills</div>
            
            {/* Responsive */}

            <div className="w-full hidden md:grid grid-cols-3 gap-12">
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-lime-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Collaborated within a remote setting.</p>
                    </div>
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-lime-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Improved UX design skillset and understanding of digital strategy.</p>
                    </div>
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-lime-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Improved understanding of typography and other visual design fundamentals.</p>
                    </div>
            </div>

            {/* Unresponsive */}

            <div className="w-full grid md:hidden space-y-6">
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-lime-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Collaborated within a remote setting.</p>
                    </div>
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-lime-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Improved UX design skillset and understanding of digital strategy.</p>
                    </div>
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-lime-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Improved understanding of typography and other visual design fundamentals.</p>
                    </div>
            </div>

            <div className='text-left text-violet-700 w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>Learned the following lessons</div>

            {/* Responsive */}

            <div className="w-full hidden md:grid grid-cols-2 gap-12">
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-violet-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>My team and I were expose to real industry design briefs, which has better equipped us for future challenges within  the design field. Additionally, including various design items like posters and social media mock ups can help demonstrate potential for growth of projects.</p>
                    </div>
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-violet-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Although design disciplines exist, elements from each discipline are used across each. For example, attention to typography and ensuring all visual design fundamentals are in place significantly enhance any design project.</p>
                    </div>
            </div>

            {/* Unresponsive */}

                <div className="w-full grid md:hidden space-y-6">
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-violet-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>My team and I were expose to real industry design briefs, which has better equipped us for future challenges within  the design field. Additionally, including various design items like posters and social media mock ups can help demonstrate potential for growth of projects.</p>
                    </div>
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-violet-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Although design disciplines exist, elements from each discipline are used across each. For example, attention to typography and ensuring all visual design fundamentals are in place significantly enhance any design project.</p>
                    </div>
            </div>
        </div>
      </div>
      </div>
    </>
  )
}
