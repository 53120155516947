import React from 'react'
import BrainstormCarouselWrapper from './BrainstormCarouselWrapper'

export default function DevelopChrom() {
  return (
    <>
              {/* Develop */}

    <div className='grid grid-cols-12 w-screen h-auto bg-white py-10 md:py-20'>
        <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
            <div className='w-full h-auto space-y-4 md:space-y-6'>
            <h3 className='text-left text-[44px] md:text-[52px] font-bold text-zinc-900 font-monda tracking-tighter'>Develop</h3>
            <p className='text-left font-plus text-md md:text-lg font-medium pb-4'>Now that a solid foundation had been established it was now time to navigate potential solutions. I accomplished this by reframing the problem statement to reveal opportunities for moving forward in alignment.</p>
            <div className='w-full h-auto py-6 px-6 md:py-10 md:px-10 space-y-4 md:space-y-6 rounded-xl text-cyan-900 bg-cyan-100'>
                <p className='text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full text-left'>New problem statement</p>
                <p className='text-lg md:text-xl font-medium font-plus text-left'>How do we develop a product which can simplify the principles of colour psychology, while providing a calm and intuitive experience?</p>
            </div>
            <p className='text-left text-[32px] md:text-[40px] font-monda font-bold tracking-tighter pt-4 text-cyan-800'>Brainstorming</p>
            {/* Unresponsive */}
            <div className='hidden md:grid space-y-6 '>
            <p className='w-full text-left text-md md:text-lg font-plus tracking-tight font-medium'>To brainstorm solutions I completed the following activities:</p>
            <div className='grid grid-cols-2 gap-12'>
            <ul className='col-span-1 pl-6 list-disc md:py-2 space-y-2 text-md md:text-lg font-plus w-full text-left tracking-tight font-medium'>
                        <li>“How Might We” brain dump</li>
                        <li>Creating mind maps for each point</li>
                        <li>Creating a moodboard</li>
                        <li>Sketching possible layouts</li>
            </ul>
            <ul className='col-span-1 pl-6 list-disc md:py-2 space-y-2 text-md md:text-lg font-plus w-full text-left tracking-tight font-medium'>
                        
                        <li>Drafting an ideal userflow</li>
                        <li>Creating the information architecture</li>
                        <li>First <span className='font-bold text-cyan-600'>Lo-fi prototype wireframe</span> and cognitive walkthrough</li>
            </ul>
            </div>
            </div>
            {/* Repsonsive */}
            <div className='md:hidden grid space-y-4'>
            <p className='w-full text-left text-md md:text-lg font-plus tracking-tight font-medium'>To brainstorm solutions I completed the following activities:</p>
            <ul className='pl-6 list-disc md:py-2 space-y-2 text-md md:text-lg font-plus w-full text-left tracking-tight font-medium'>
                        <li>“How Might We” brain dump</li>
                        <li>Creating mind maps for each point</li>
                        <li>Creating a moodboard</li>
                        <li>Sketching possible layouts</li>
                        <li>Drafting an ideal userflow</li>
                        <li>Creating the information architecture</li>
                        <li>First <span className='font-bold text-cyan-600'>Lo-fi prototype wireframe</span> and cognitive walkthrough</li>
            </ul>
            </div>
            <BrainstormCarouselWrapper/>
            <span className='w-full pb-4 text-white'>f</span>
            {/* Findings */}
            <div className='p-8 w-full h-auto hidden md:grid grid-cols-3 gap-12 bg-cyan-700 rounded-md font-medium'>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>Use desaturated pastels and minimal UI patterns for smoother UX.</div>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>Curate a design system with localised signage to support the user flows.</div>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>Refine copyrighting to relay specific instructions relating to the features of your product.</div>
            </div>
            {/* Responsive */}
            <div className='p-8 w-full h-auto grid md:hidden grid-rows-3 gap-8 bg-cyan-700 rounded-md text-md font-medium'>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>Use desaturated pastels and minimal UI patterns for smoother UX.</div>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>Curate a design system with localised signage to support the user flows.</div>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>Refine copyrighting to relay specific instructions relating to the features of your product.</div>
            </div>
        </div>
      </div>
      </div>
    </>
  )
}
