import React from 'react'
import ProductWork from './work-page/ProductWork'
import EngineeringWork from './work-page/EngineeringWork'
import FeaturedWorkPage from './work-page/FeaturedWorkPage'

export default function WorkPage() {
  return (
    <>
          <div className='grid grid-cols-12'>
      <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 py-10 md:py-20 space-y-4 md:space-y-6'>
        {/* Title */}
        <h1 className='text-5xl md:text-6xl tracking-tighter leading-snug bg-gradient-to-r from-yellow-500 to-purple-600 h-fit text-transparent bg-clip-text font-monda font-bold w-full'>Work</h1>
        {/* Image and description */}

        <p className='w-full text-md lg:text-lg font-plus font-medium'>
            All projects from front-end development to product design.
        </p>
      </div>
      </div>
      <FeaturedWorkPage/>
      <EngineeringWork/>
      <ProductWork/>
      
    </>
  )
}
