import React from 'react'

export default function GoalSection() {
  return (
    <>
          {/* Unresponsive */}
          <div className='w-screen h-auto hidden md:grid grid-cols-12 py-10 md:py-20 bg-gradient-to-r from-yellow-500 to-purple-500'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 rounded-md p-16 bg-white'>
            <div className='grid grid-cols-3 gap-20'>
                <div className="col-span-1 grid place-items-center">
                    <div className='w-32 h-32 grid place-items-center'>
                    <svg class="h-32 w-32 text-zinc-950"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7" />  <line x1="9" y1="4" x2="9" y2="17" />  <line x1="15" y1="7" x2="15" y2="20" /></svg>
                    </div>
                </div>
                <div className="col-span-1 grid place-items-center">
                    <div className='w-32 h-32 grid place-items-center'>
                        <svg class="h-32 w-32 text-zinc-950"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="9" />  <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />  <circle cx="12" cy="7.5" r=".5" fill="currentColor" /></svg>
                    </div>
                </div>
                <div className="col-span-1 grid place-items-center">
                    <div className='w-32 h-32 grid place-items-center'>
                        <svg class="h-32 w-32 text-zinc-950"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="6 21 21 6 18 3 3 18 6 21" />  <line x1="15" y1="6" x2="18" y2="9" />  <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />  <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" /></svg>
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-3 gap-20 pt-8'>
                <div className="col-span-1">
                    <p className='w-full h-auto font-medium font-plus md:text-lg text-md'>I am currently searching for a graduate role as a Product, Service or UX Designer. I want to work in this field as I believe it's the role I can provide the most service in.</p>
                </div>
                <div className="col-span-1 space-y-6 md:space-y-8">
                    <p className='w-full h-auto font-medium font-plus md:text-lg text-md'>I’m passionate about accessibility. I believe media should be able to be experienced by EVERYONE regardless of language or ability.</p>
                    <p className='w-full h-auto font-medium font-plus md:text-lg text-md'>I do this by staying up to date with the latest accessibility trends, constantly researching the inclusivitiy guidelines of Google and Apple.</p>
                </div>
                <div className="col-span-1">
                    <div className='space-y-6 md:space-y-8 font-medium font-plus md:text-lg text-md'>
                        <p className='w-full h-auto'>Additionally, I have a passion for UX Engineering. I want to aid the gap between UX Design and Front-End Engineering to create better experiences! </p>
                        <p className='w-full h-auto'>My education in UX and Web design enables me to create both UI designs and code, equiping me with an understanding of what’s realistic during my design process. </p>
                    </div>
                </div>
            </div>
        </div>
      </div>

    {/* Responsive */}
      <div className='w-screen h-auto md:hidden grid grid-cols-12 py-20 bg-gradient-to-r from-yellow-500 to-purple-500'>
        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 p-8 bg-white rounded-md'>
            <div className='grid gap-8'>
                <div className='row-span-1 grid place-items-center space-y-6'>
                    <div className='w-32 h-32'>
                        <svg class="h-32 w-32 text-zinc-950"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7" />  <line x1="9" y1="4" x2="9" y2="17" />  <line x1="15" y1="7" x2="15" y2="20" /></svg>
                    </div>
                    <p className='w-full h-auto font-medium font-plus md:text-lg text-md'>I am currently searching for a graduate role as a Product, Service or UX Designer. I want to work in this field as I believe it's the role I can provide the most service in.</p>
                </div>
                <div className='row-span-1 grid place-items-center space-y-6'>
                    <div className='w-32 h-32 grid place-items-center'>
                        <svg class="h-32 w-32 text-zinc-950"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="9" />  <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />  <circle cx="12" cy="7.5" r=".5" fill="currentColor" /></svg>
                    </div>
                    <p className='w-full h-auto font-medium font-plus md:text-lg text-md'>I’m passionate about accessibility. I believe media should be able to be experienced by EVERYONE regardless of language or ability.</p>
                    <p className='w-full h-auto font-medium font-plus md:text-lg text-md'>I do this by staying up to date with the latest accessibility trends, constantly researching the inclusivitiy guidelines of Google and Apple.</p>                
                </div>
                
                <div className='row-span-1 grid place-items-center space-y-6'>
                <div className='w-32 h-32 grid place-items-center'>
                    <svg class="h-32 w-32 text-zinc-950"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="6 21 21 6 18 3 3 18 6 21" />  <line x1="15" y1="6" x2="18" y2="9" />  <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />  <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" /></svg>
                </div>
                    <div className='space-y-6 md:space-y-8 font-medium font-plus md:text-lg text-md'>
                        <p className='w-full h-auto'>Additionally, I have a passion for UX Engineering. I want to aid the gap between UX Design and Front-End Engineering to create better experiences! </p>
                        <p className='w-full h-auto'>My education in UX and Web design enables me to create both UI designs and code, equiping me with an understanding of what’s realistic during my design process. </p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}
