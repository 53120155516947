import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';


export default function AccessibilitySection() {

  // These two variables do work. This is how you make all the "children" have navigations and everything
  // const gridContainerVariants =
  // {hidden:{opacity:0}, show:{opacity:1, transition:{staggerChildren:0.25}}}
  // ;

  // const gridSquareVariants = {
  //   hidden:{opacity:0},
  //   show: {opacity:1},
  // };

  // correctly working scroll animation variables
  // const { scrollYProgress } = useScroll();
  // const scrollRef = useRef(null)
  // const scale = useTransform(scrollYProgress, [1, 0], [0.2, 2]);

  return (
    <>
            {/* Accessibility section */}
                    
                    {/* Wrapper */}
            <div className='grid w-screen h-auto grid-cols-12 bg-white tracking-tight py-20' >
                    
                    {/* Elements */}
            <div className='col-start-3 col-end-11 grid place-items-center row-auto rounded-xl font-bold space-y-8'
           >
                      
                      {/* Title */}
            <h1 className='font-monda pb-2 md:pb-4 text-[40px] sm:text-5xl md:text-6xl tracking-tighter leading-tight md:leading-snug bg-gradient-to-r from-yellow-500 to-purple-600 h-fit text-transparent bg-clip-text w-full'>Passionate about Accessibility</h1>
                 
                     {/* Icons */}
            <motion.div initial={{ opacity: 0.2 }} whileInView={{ opacity: 1, transition: { duration: 1 } }}  className='flex justify-center' >
              <div  className='place-items-center w-fit h-auto px-4 grid grid-cols-5'>
                <div className='cols-span-1 mx-4 h-16 w-16 md:h-32 md:w-32 grid place-items-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.4} stroke="currentColor" className="h-12 w-12  md:w-24 md:h-24 text-slate-700">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  </svg>
                </div>
                <div className='cols-span-1 mx-4 h-16 w-16 md:h-32 md:w-32 grid place-items-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.4} stroke="currentColor" className="h-12 w-12 md:w-24 md:h-24 text-slate-700">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.05 4.575a1.575 1.575 0 1 0-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 0 1 3.15 0v1.5m-3.15 0 .075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 0 1 3.15 0V15M6.9 7.575a1.575 1.575 0 1 0-3.15 0v8.175a6.75 6.75 0 0 0 6.75 6.75h2.018a5.25 5.25 0 0 0 3.712-1.538l1.732-1.732a5.25 5.25 0 0 0 1.538-3.712l.003-2.024a.668.668 0 0 1 .198-.471 1.575 1.575 0 1 0-2.228-2.228 3.818 3.818 0 0 0-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0 1 16.35 15m.002 0h-.002" />
                    </svg>
                </div>
                <div className='cols-span-1 mx-4 h-16 w-16 md:h-32 md:w-32 grid place-items-center'>
                  <svg class="h-12 w-12 md:w-24 md:h-24 text-slate-700"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.4" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="12" cy="12" r="9" />  <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />  <circle cx="12" cy="7.5" r=".5" fill="currentColor" /></svg>
                </div>
                <div className='cols-span-1 mx-4 h-16 w-16 md:h-32 md:w-32 grid place-items-center'>
                  <svg class="h-12 w-12 md:w-24 md:h-24 text-slate-700"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5 " stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M6 10a7 7 0 1 1 13 3.6a10 10 0 0 1 -2 2a8 8 0 0 0 -2 3  a4.5 4.5 0 0 1 -6.8 1.4" />  <path d="M10 10a3 3 0 1 1 5 2.2" /></svg>
                </div>
                <div className='cols-span-1 mx-4 h-16 w-16 md:h-32 md:w-32 grid place-items-center'>
                  <svg class="h-12 w-12 md:w-24 md:h-24 text-slate-700"  width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <circle cx="11" cy="5" r="2" />  <polyline points="11 7 11 15 15 15 19 20" />  <line x1="11" y1="11" x2="16" y2="11" />  <path d="M7 11.5a4.97 4.97 0 1 0 6 7.5" /></svg>
                </div>
       
              </div>
              
            </motion.div>
            {/* Text */}
            <p className='md:grid leading-snug text-xl md:text-2xl font-medium font-plus'>The foundation of my work is built upon my passion for accessibility and inclusivity. <span className="font-black bg-gradient-to-r from-yellow-500 to-purple-600 h-fit text-transparent bg-clip-text">I believe that everyone has a right to use technology to enhance their lifestyles.</span> I want to create and work on projects that empower and connect our society regardless of language and ability barriers. </p>
        </div>
      </div>

      {/* <section className='grid grid-cols-3 p-12 gap-0'
      variants={gridContainerVariants} initial='hidden' animate='show'>
        <div variants={gridSquareVariants} className='bg-slate-800 aspect-square rounded-lg justify-center flext items-center gap-10'></div>
        <div variants={gridSquareVariants} className='bg-slate-800 aspect-square rounded-lg justify-center flext items-center gap-10'></div>
       
       Scroll motion
        <div variants={gridSquareVariants} className='bg-slate-800 aspect-square rounded-lg justify-center flext items-center gap-10'>
          <div className='w-40 aspect-square rounded-lg bg-red-50/20'>

          </div>
        </div>
        <div variants={gridSquareVariants} className='bg-slate-800 aspect-square rounded-lg justify-center flext items-center gap-10'></div>
        <div variants={gridSquareVariants} className='bg-slate-800 aspect-square rounded-lg justify-center flext items-center gap-10'></div>
        <div variants={gridSquareVariants} className='bg-slate-800 aspect-square rounded-lg justify-center flext items-center gap-10'></div>
      </section> */}


    </>
  )
}
