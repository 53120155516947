import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';

export default function ProdigiIntro() {
  return (
    <>
          <div className='w-full h-auto grid grid-cols-12 place-items-center bg-gradient-to-r from-blue-800 to-orange-700'>
      <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 grid place-items-center'>
      <motion.div initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }} className='w-full h-auto grid grid-place-items-center bg-white/20 p-10 md:p-20 rounded-md my-10'>
              <h1 className='text-[44px] sm:text-[60px] text-white font-monda font-bold tracking-tighter w-full'>Prodigi</h1>
                <p className='text-[24px] font-monda text-white font-bold tracking-tighter pb-5 md:pb-10'>Client Design Program T2 2022</p>
                <div className='w-full space-y-2 pb-4'>
                  <p className='text-white text-md md:text-lg font-medium'>Prodigi is a student-led organisation providing opportunities to developing career skills within the product development industry. I collaborated with my group to improve upon one of Arc's products, specifically their online clubs and society's platform SpArc.</p>
              </div>
          </motion.div>
      </div>
    </div>
    </>
  )
}
