import React from 'react';
import ProdigiGif from './prodigi-images/prodigi-gifs.gif';

export default function ProdigiSummary() {
  return (
    <>
          <div className='grid grid-cols-12 py-10 md:py-16 w-screen h-auto bg-white'>
    <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
    <div className='w-full h-auto space-y-6 grid place-items-center'>
            <div className='text-4xl md:text-[52px] w-full h-auto font-monda tracking-tighter font-bold text-zinc-800'>Project summary</div>
            
            <div className='text-blue-600 w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>ARC dashboard project</div>
            <div className='space-y-2 grid place-items-center'>
              <div className='w-[80%] h-auto rounded-md bg-red-400 overflow-hidden shadow-md'>
                <img src={ProdigiGif} className='w-full' alt="prodigi-screen-prototypes"/>
              </div>
              <p className='text-left tracking-tight w-fit text-align-left text-md text-zinc-500 font-medium'>Selection of prototyped screens.</p>
            </div>

            <p className='pt-4 md:pt-8 w-full text-left text-md md:text-lg font-medium'>After analysing Arc's current platform, it became apparent that the existing user-flows and information architecture limited the engagement of online student life. As this project took place within the peak of COVID-19, online engagement became an evident necessity within everyone's lives.</p>
        
            <p className='w-full text-left text-md md:text-lg font-medium'>Our groups solution included redeveloping the current information architecture of the platform, creating a supportive user flow through the addition of an onboarding process, simplifying the platform's current UI, and integrating playful copywriting to engage with the existing demographic.</p>

            <p className=' w-full text-left text-md md:text-lg font-medium'>For this project, I was tasked with redeveloping the two following parts of the platform:</p>


            <ul className='list-disc pl-6 text-md md:text-lg font-plus font-medium w-full text-left'>
                <li>The SpArc dashboard where I simplified the UI along with the navigaition of the platform.</li>
                <li>Improved upon the Socieity navigation and Volunteering Page.</li>
              </ul>
        </div>
      </div>
      </div>
    </>
  )
}
