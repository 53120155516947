import React from 'react'

export default function AmbassadorIntro() {
  return (
    <>
    <div className='w-screen h-auto grid grid-cols-12'>
        <section className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 py-10 md:py-20 space-y-4 md:space-y-6 grid place-items-center'>
        <h1 className='text-4xl md:text-[52px] bg-gradient-to-r from-yellow-500 to-purple-600 h-fit text-transparent bg-clip-text font-monda font-bold tracking-tighter w-full leading-snug'>Student Ambassador at Torrens University Australia</h1>     
        <p  className='pb-2 md:pb-4 font-plus font-medium md:text-lg text-md'>A student ambassador at Torrens University Australia supports the university by aiding the university in running events and representing Torrens University Australia at social events.</p>  
        <h3 className='text-left  text-[32px] md:text-[40px] font-bold font-monda tracking-tighter bg-gradient-to-r from-yellow-700 to-purple-800 h-fit text-transparent bg-clip-text'>Recent Events</h3>
        <ul className='text-left list-disc pl-6 text-md md:text-lg font-plus font-medium w-auto'>
                        <li>Trimester 2 Open Day</li>
                        <li>Hosting the SNASK Event at Ultimo's Campus</li>
                        <li>HSC Expo 2022</li>
                        <li>Brand Representative for Torrens University’s “How To Uni” Campaign</li>
                    </ul>
        </section>
    </div>
    </>
  )
}
