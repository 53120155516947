import React from 'react';

export default function PortfolioResearchFindings() {
  return (
    <>

        {/* ---Other Interesting Finds--- */}
        <div className='w-full h-auto md:space-y-6 space-y-4'>
        <h3 className='text-purple-800 text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full leading-tight'>Direction moving forward</h3>
            {/* Regular */}
            <div className='p-8 w-full h-auto hidden md:grid grid-cols-2 gap-12 bg-purple-700 rounded-md font-medium'>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>I decided for my focus to be on defining my passion for UX Engineering and Accessibility, UI-minimalism, and my playful nature.</div>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>I found inspiration in websites with minimal UI designs, using subtle micro-interactions and gradient color schemes to create interest.</div>
            </div>
            {/* Responsive */}
            <div className='p-8 w-full h-auto grid md:hidden grid-rows-2 gap-8 bg-purple-700 rounded-md text-md font-medium'>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>I decided for my focus to be on defining my passion for UX Engineering and Accessibility, UI-minimalism, and my playful nature.</div>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>I found inspiration in websites with minimal UI designs, using subtle micro-interactions and gradient color schemes to create interest.</div>
            </div>
        </div>

      
    </>
  )
}
