import CarouselOne from './CarouselOne';
import FormResponses from './chromacity-images/form-responses.png';
import ResearchCarouselWrapper from './ResearchCarouselWrapper';
import SecondaryResearchFindingsChrom from './SecondaryResearchFindingsChrom';
import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';


export default function ResearchChrom() {
  return (
    <>
        {/* Primary and Secondary Research */}
        <div className='grid grid-cols-12 w-screen h-auto pb-10 md:pb-20'>
        <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>
        <div className='space-y-6'>
            <h3 className='text-left text-[32px] md:text-[40px] font-bold text-cyan-800 font-monda tracking-tighter'>Desktop research</h3>
            <p className='w-full text-left text-md md:text-lg font-plus tracking-tight font-medium'>Based on the project requirements, it was outlined that we could complete secondary research before completing primary research to establish a solid foundation. Completing the secondary research enabled me to:</p>
            <ul className='pl-6 list-disc md:py-2 space-y-2 text-md md:text-lg font-plus w-full text-left tracking-tight font-medium'>
                        <li>Understand the breadth of modern colour psychology and common existing pain points.</li>
                        <li>Recognise current trends of technology's impact on mental health to assure that a compassionate and sincere user experience is created for the solution.</li>
            </ul>
            {/* Unresponsive */}
      
              <div className='w-full p-4'>
              <ResearchCarouselWrapper/>
              </div>
              <div className='w-full'>
              <p className='text-left font-plus font-medium md:text-lg text-md w-full h-auto'>I used the following methods to completed my secondary research:</p>
                    <ul className='pl-6 list-disc md:py-2 space-y-2 text-md md:text-lg font-plus w-full text-left tracking-tight font-medium'>
                        <li>Browsed multiple resources on colour psychology and the effect of social media and mental health.</li>
                        <li>Conducted a competitor's analysis using the SWCDUXO matrix.</li>
                        <li>Created a heuristic analysis and feature audit to summarise the studied applications.</li>
                    </ul>
              </div>
              <SecondaryResearchFindingsChrom/>
          <div className='w-full space-y-6 pt-10'>
          <h3 className='text-left text-[32px] md:text-[40px] font-bold text-cyan-800 font-monda tracking-tighter'>Learning about the demographic’s perspective</h3>
            <div className='w-full h-auto rounded-md space-y-2'>
                <div className='w-full h-40 md:h-60 rounded-md overflow-hidden'>
                  <img src={FormResponses}></img>
                </div>
                <p className='text-sm md:text-md text-left text-zinc-800 font-plus font-medium'>Screenshot of responses</p>
            </div>
            <p className='text-md md:text-lg font-plus text-left tracking-tight font-medium'>I created a survey to gather insights for my primary research and to aid with verifying my secondary research. <span className='font-bold text-cyan-800'>20+ responses were captured.</span></p>
            </div>
                  {/* ---Other Interesting Finds--- */}
        <div className='w-full h-auto md:space-y-6 space-y-4'>
        <h3 className='text-cyan-800 text-[24px] md:text-[30px] font-monda font-bold tracking-tighter w-full leading-tight'>Highlighted insights</h3>
            {/* Regular */}
            <div className='p-8 w-full h-auto hidden md:grid grid-cols-2 gap-12 bg-cyan-700 rounded-md font-medium'>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>The survey revealed the current societal impact of technology; developing countries have less access to technology due to their slower adoption towards it.</div>
                <div className='text-left col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>One participant, disclosing their autism, highlighted daily challenges with general psychology associations, extending to understanding the emotions reflected by colours.</div>
            </div>
            {/* Responsive */}
            <div className='p-8 w-full h-auto grid md:hidden grid-rows-2 gap-8 bg-cyan-700 rounded-md text-md font-medium'>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>The survey revealed the current societal impact of technology; developing countries have less access to technology due to their slower adoption towards it.</div>
                <div className='text-left row-span-1 bg-white p-4 rounded-md grid place-items-center'>One participant, disclosing their autism, highlighted daily challenges with general psychology associations, extending to understanding the emotions reflected by colours.</div>
            </div>
        </div>
        </div>
        </div>
        </div>
    </>
  )
}
