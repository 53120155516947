import React from 'react'

export default function ResoReflection() {
  return (
    <>
          <div className='grid grid-cols-12 py-10 md:py-20 w-screen h-auto bg-white'>
    <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
    <div className='w-full h-auto space-y-6'>
            <div className='text-left text-4xl md:text-[52px] w-full h-auto font-monda tracking-tighter font-bold text-zinc-800'>Reflection</div>
            
            <div className='text-left text-orange-800 w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>Gained the following skills</div>
            
            {/* Responsive */}

            <div className="w-full hidden md:grid grid-cols-3 gap-12">
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Balancing multiple user interviews in a timely manner.</p>
                    </div>
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Improved copywriting skillset.</p>
                    </div>
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Professional communication with stakeholders.</p>
                    </div>
            </div>

            {/* Unresponsive */}

            <div className="w-full grid md:hidden space-y-6">
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Balancing multiple user interviews in a timely manner.</p>
                    </div>
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Improved copywriting skillset.</p>
                    </div>
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Professional communication with stakeholders.</p>
                    </div>
            </div>

            <div className='text-left text-orange-800 w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>Learned the following lessons</div>

            {/* Responsive */}

            <div className="w-full hidden md:grid grid-cols-2 gap-12">
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Many industry projects opt to deviate from a traditional UX design process as they onboard multiple projects at a time and are required to finish them at a rapid pace. This prompts teams to only prioritise essential design steps to complete them effectively.</p>
                    </div>
                    <div className='col-span-1 w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>While designing within a digital marketing agency, it is imperative to keep business considerations and metrics at the fore-front as these elements are often the most crucial for clients in this environment. </p>
                    </div>
            </div>

            {/* Unresponsive */}

                <div className="w-full grid md:hidden space-y-6 ">
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>Many industry projects opt to deviate from a traditional UX design process as they onboard multiple projects at a time and are required to finish them at a rapid pace. This prompts teams to only prioritise essential design steps to complete them effectively.</p>
                    </div>
                    <div className='w-full h-fit p-4 space-y-2 rounded-md bg-orange-100'>
                        <p className='text-left font-plus font-medium w-full text-md md:text-lg'>While designing within a digital marketing agency, it is imperative to keep business considerations and metrics at the fore-front as these elements are often the most crucial for clients in this environment.</p>
                    </div>
            </div>
        </div>
      </div>
      </div>
    </>
  )
}
