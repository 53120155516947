import React from 'react';
import PlancoWidget from './planco-images/screen-widget.png'
import PlancoWatch from './planco-images/watch-mockup.png'


export default function PlancoExtended() {
  return (
    <>
      
    <div className='grid grid-cols-12 w-screen h-auto bg-white'>
      <div className='col-start-2 col-end-12 md:col-start-3 md:col-end-11'>

      {/* Unresponsive */}

      <div className='w-full md:grid grid-cols-2 gap-16 hidden place-items-center'>
              <div className=' col-span-1 h-auto w-[50%] space-y-4 md:space-y-6 grid place-items-center'>
                <div className='h-auto w-full rounded-md overflow-hidden grid place-items-center'>
                  <img src={PlancoWidget}/> 
                </div>
                <p className='text-md md:text-lg font-medium font-plus'>Mobile widget</p>
              </div>
              <div className=' col-span-1 h-auto space-y-4 md:space-y-6 grid place-items-center w-full'>
                <div className='h-auto w-full rounded-md overflow-hidden grid place-items-center shadow-sm'>
                  <img src={PlancoWatch} className='grid place-items-center'/> 
                </div>
                <p className='text-md md:text-lg font-medium font-plus'>Smart watch widget</p>
              </div>
            </div>


        {/* Responsive */}
      <div className='w-full grid md:hidden place-items-center space-y-8'>
              <div className='h-auto w-[40%] space-y-4 md:space-y-6 grid place-items-center'>
                <div className='h-auto w-full rounded-md overflow-hidden grid place-items-center'>
                  <img src={PlancoWidget}/> 
                </div>
                <p className='text-md md:text-lg font-medium font-plus'>Mobile widget</p>
              </div>
              <div className='h-auto space-y-4 md:space-y-6 grid place-items-center w-[60%]'>
                <div className='h-auto w-full rounded-md overflow-hidden grid place-items-center shadow-sm'>
                  <img src={PlancoWatch} className='grid place-items-center'/> 
                </div>
                <p className='text-md md:text-lg font-medium font-plus'>Smart watch widget</p>
              </div>
            </div>
      </div>
      </div>
    </>
  )
}
