import React from 'react';
import {Link} from 'react-router-dom';


export default function WorkInProgress() {
  return (
    <>
      <div className='w-auto h-auto py-40 grid place-items-center space-y-8'>
            <h1 className='font-bold font-monda h-hit pb-6 text-[40px] sm:text-5xl md:text-6xl tracking-tighter leading-snug bg-gradient-to-r from-yellow-500 to-purple-600 text-transparent bg-clip-text w-full'>Work in progress</h1>
            <Link to="/">
              <button className='font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-purple-500 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>Return home</button>
            </Link>
      </div>
    </>
  )
}
