import React from 'react'
import PlancoIntro from './planco-elements/PlancoIntro'
import DesignProcessPlanco from './planco-elements/DesignProcessPlanco'
import PlancoMockupsOne from './planco-elements/PlancoMockupsOne'
import DiscoverIntroPlanco from './planco-elements/DiscoverIntroPlanco'
import ResearchPlanco from './planco-elements/ResearchPlanco'
import DefinePlanco from './planco-elements/DefinePlanco'
import UserPersonasPlanco from './planco-elements/UserPersonasPlanco'
import PlancoBrainstorm from './planco-elements/PlancoBrainstorm'
import PrototypeOne from './planco-elements/PrototypeOne'
import PlancoFinalDesign from './planco-elements/PlancoFinalDesign'
import PlancoExtended from './planco-elements/PlancoExtended'
import PlancoFeatureBreakdown from './planco-elements/PlancoFeatureBreakdown'
import PlancoFeatureBreakdownTwo from './planco-elements/PlancoFeatureBreakdownTwo'
import PlancoReflection from './planco-elements/PlancoReflection'
import PlancoEnding from './planco-elements/PlancoEnding'
import PlancoVideoOne from './planco-elements/PlancoVideoOne'


export default function PlancoPage() {
  return (
    <>
        <PlancoIntro/>
        <DesignProcessPlanco/>
        <PlancoVideoOne/>
        {/* <PlancoMockupsOne/> */}
        <DiscoverIntroPlanco/>
        <ResearchPlanco/>
        <DefinePlanco/>
        <UserPersonasPlanco/>
        <PlancoBrainstorm/>
        <PrototypeOne/>
        <PlancoFinalDesign/>
        <PlancoExtended/>
        <PlancoFeatureBreakdown/>
        <PlancoFeatureBreakdownTwo/>
        <PlancoReflection/>
        <PlancoEnding/>
    </>

  )
}
