import React, { useEffect, useRef } from 'react';
import {motion, useInView, useAnimate, useAnimation, useAnimationControls, useScroll, useTransform} from 'framer-motion';


export default function PortfolioReflection() {
  return (
    <>
    <div className='grid grid-cols-12 py-10 md:py-20 w-screen h-auto bg-gradient-to-r from-purple-800  to-yellow-800'>
    <motion.div initial={{ y:20 ,opacity: 0.2 }} whileInView={{ y:0, opacity: 1, transition: { duration: 1 } }} className='md:col-start-3 md:col-end-11 col-start-2 col-end-12'>
    <div className='w-full h-auto space-y-6'>
            <div className='text-zinc-300 text-left text-4xl md:text-[52px] w-full h-auto font-monda tracking-tighter font-bold'>Reflection</div>
            <div className='text-zinc-200 text-left w-full h-auto text-[32px] md:text-[40px] font-monda tracking-tighter font-bold'>Takeaways</div>
            {/* Unresponsive */}
            <div className='hidden md:grid grid-cols-2 gap-12 text-white'>
                <div className="col-span-1 space-y-4">
                    <div className="underline text-left text-[30px] font-monda font-bold tracking-tighter">Vision capture</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>I'm incredibly proud of this project. It truly represents my design style, passions and strengths. After learning that improving copywriting was crucial for improving the previous design, it greatly influenced capturing my vision.</p>
                </div>
                <div className="col-span-1 space-y-4">
                    <div className="underline text-left text-[30px] font-monda font-bold tracking-tighter">Stepping back to move forward</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>I learned Canvas.js for this project only to learn it doesn't work with React.js. Luckily, Framer Motion brought my desired micro-interactions and animations to life, resulting in a great outcome.</p>
                </div>
            </div>
            {/* Responsive */}
            <div className='md:hidden grid grid-rows-2 gap-12 text-white'>
                <div className="row-span-1 space-y-4">
                    <div className="underline text-left text-[24px] font-monda font-bold tracking-tighter">Vision capture</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>I'm incredibly proud of this project. It truly represents my design style, passions and strengths. After learning that improving copywriting was crucial for improving the previous design, it greatly influenced capturing my vision.</p>
                </div>
                <div className="row-span-1 space-y-4">
                    <div className="underline text-left text-[24px] font-monda font-bold tracking-tighter">Stepping back to move forward</div>
                    <p className='font-medium text-md md:text-lg text-left font-plus'>I learned Canvas.js for this project only to learn it doesn't work with React.js. Luckily, Framer Motion brought my desired micro-interactions and animations to life, resulting in a great outcome.</p>
                </div>
            </div>
        </div>
      </motion.div>
      </div>
    </>
  )
}
