import React from 'react';
// import EmpathyMap from './chromacity-images/empathy-map.png';

export default function DefinePlanco() {
  return (
    <>
        <div className='grid grid-cols-12 w-screen h-auto py-10 md:py-20 bg-white'>
        <div className='md:col-start-3 col-start-2 md:col-end-11 col-end-12'>
        <div className='w-full h-auto md:space-y-6 space-y-4'>
        <h3 className='text-left text-[44px] md:text-[52px] font-bold  font-monda tracking-tighter text-zinc-900'>Define</h3>
            <p className='text-left text-[32px] md:text-[40px] font-bold font-monda tracking-tighter leading-tight pb-2 text-teal-800'>Consolidating the insights</p>            
            <div className='w-full h-auto rounded-md grid place-items-center overflow-hidden'>
              {/* <img src={EmpathyMap} className='w-fit'></img> */}
            </div>
            {/* Regular */}
            <div className='p-8 w-full h-auto hidden md:grid grid-cols-2 gap-12 bg-teal-700 rounded-md font-medium'>
                <div className='space-y-2 col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>
                <p className='text-2xl font-monda tracking-tighter font-bold'>Primary research insights</p>
                  <ul className='space-y-2 text-left list-disc pl-6 text-md md:text-lg font-plus font-medium'>
                    <li>90% agreed that sustainability is linked with social and economic privileges.</li>
                    <li>100% of participants believed that eco-friendliness was habitual.</li>
                    <li>Participants addressed that people that are into sustainability are often shamed.</li>
                  </ul>
                </div>
                <div className='space-y-2 col-span-1 bg-white p-4 rounded-md text-lg grid place-items-center'>
                <p className='text-2xl font-monda tracking-tighter font-bold'>Secondary research insights</p>
                  <ul className='space-y-2 text-left list-disc pl-6 text-md md:text-lg font-plus font-medium'>
                    <li>Services with no privacy settings made the experience feel invasive.</li>
                    <li>Best platforms featured streamlined UI, Information Architecture and transparency in processes.</li>
                    <li>One Step At a Time exceeded by offering realistic sustainability practices and tracking options for users' short and long-term goals.</li>
                  </ul>
                </div>
            </div>
            {/* Responsive */}
            <div className='p-8 w-full h-auto grid md:hidden grid-rows-2 gap-8 bg-teal-700 rounded-md text-md font-medium'>
                <div className='row-span-1 bg-white p-4 rounded-md grid place-items-center space-y-4 md:space-y-0'>
                  <p className='font-monda tracking-tighter font-bold text-2xl'>Primary research insights</p>
                  <ul className='list-disc pl-6 text-md md:text-lg font-medium font-plus text-left space-y-4'>
                    <li>90% agreed that sustainability is linked with social and economic privileges.</li>
                    <li>100% of participants believed that eco-friendliness was habitual.</li>
                    <li>Participants addressed that people that are into sustainability are often shamed.</li>
                  </ul>
                </div>
                <div className='row-span-1 bg-white p-4 rounded-md grid place-items-center space-y-4 md:space-y-0'>
                  <p className='font-monda tracking-tighter font-bold text-2xl'>Secondary research insights</p>
                  <ul className='list-disc pl-6 text-md md:text-lg font-medium font-plus text-left space-y-4'>
                    <li>Services with no privacy settings made the experience feel invasive.</li>
                    <li>Best platforms featured streamlined UI, Information Architecture and transparency in processes.</li>
                    <li>One Step At a Time exceeded by offering realistic sustainability practices and tracking options for users' short and long-term goals.</li>
                  </ul>
                </div>
            </div>
        </div>
        </div>
        </div>
    </>
  )
}
