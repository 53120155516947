import React from 'react';
import OfficialWebsite from './intro-images/work-website.png';

export default function IntroWorkOfficial() {
  return (
    <>
    <div className='w-screen h-auto grid grid-cols-12 mb-10 md:mb-20'>

        <div className='md:col-start-3 md:col-end-11 col-start-2 col-end-12 w-full space-y-4 md:space-y-6 grid place-items-center'>
        
            <div className='w-[80%] overflow-hidden rounded-md shadow-md grid place-items-center'>
              <img src={OfficialWebsite} className='w-full'/>                 
            </div>
            
            <p className='text-md md:text-lg w-full font-medium font-plus'>My work is currently featured as an official example of student UX work on Torrens University Australia's website.</p>
       
        </div>  

    </div>

    <a href="https://www.torrens.edu.au/courses/design/bachelor-of-ux-and-web-design"><button className='mb-10 md:mb-20 font-monda tracking-tight align-center w-fit px-4 py-2 mt-4 rounded-lg h-fit text-lg bg-purple-700 text-white font-bold  transition ease-in-out delay-2 hover:-translate-y-1 hover:scale-80 hover:bg-indigo-500 duration-300'>View platform</button></a>

    </>
  )
}
